@font-face {
  font-family: 'Groupon';
  font-style: normal;
  font-weight: 400;
  src: url("images/Groupon-v2_5.woff2") format('woff2'), url("images/Groupon-v2_5.woff") format('woff');
}
[data-icon]:before {
  font-family: 'Groupon';
  content: attr(data-icon);
  speak: none;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-add:before,
.icon-alert:before,
.icon-arrow-curved:before,
.icon-arrow-down-large:before,
.icon-arrow-down:before,
.icon-arrow-left-large:before,
.icon-arrow-left:before,
.icon-arrow-nav-down:before,
.icon-arrow-nav-left:before,
.icon-arrow-nav-right:before,
.icon-arrow-nav-up:before,
.icon-arrow-pointer-up:before,
.icon-arrow-replay:before,
.icon-arrow-right-large:before,
.icon-arrow-right:before,
.icon-arrow-up-large:before,
.icon-arrow-up:before,
.icon-asterisk:before,
.icon-barcode:before,
.icon-bars:before,
.icon-beauty-wellness-healthcare:before,
.icon-binoculars:before,
.icon-book-ring:before,
.icon-box:before,
.icon-broadcast:before,
.icon-bubble-closed:before,
.icon-bubble-open:before,
.icon-bucks:before,
.icon-bullhorn:before,
.icon-calendar:before,
.icon-car:before,
.icon-check-circle:before,
.icon-check:before,
.icon-checklist:before,
.icon-child:before,
.icon-circle-alert:before,
.icon-circle:before,
.icon-close:before,
.icon-cloud:before,
.icon-cocktail:before,
.icon-compass:before,
.icon-corner:before,
.icon-courthouse:before,
.icon-credit-card:before,
.icon-crown:before,
.icon-currency:before,
.icon-cut:before,
.icon-daily-deals:before,
.icon-deal-ended:before,
.icon-dice:before,
.icon-document-search:before,
.icon-dollar:before,
.icon-dot-scale:before,
.icon-download:before,
.icon-email:before,
.icon-facebook:before,
.icon-factory:before,
.icon-feed:before,
.icon-filter:before,
.icon-flag:before,
.icon-food-and-drink:before,
.icon-g2:before,
.icon-gaspump:before,
.icon-gavel:before,
.icon-gear:before,
.icon-getaways:before,
.icon-gift:before,
.icon-glassdoor:before,
.icon-glove:before,
.icon-gnome:before,
.icon-goods:before,
.icon-google-plus:before,
.icon-graduate-cap:before,
.icon-grid:before,
.icon-group:before,
.icon-groupon-box:before,
.icon-groupon-cat:before,
.icon-groupon:before,
.icon-heart-filled:before,
.icon-home:before,
.icon-hourglass:before,
.icon-image:before,
.icon-import:before,
.icon-info:before,
.icon-key:before,
.icon-layout:before,
.icon-leaf:before,
.icon-link:before,
.icon-linkedin:before,
.icon-list-unordered:before,
.icon-list:before,
.icon-loading:before,
.icon-lock-open:before,
.icon-lock:before,
.icon-logo-g:before,
.icon-magnifying-glass:before,
.icon-magnifying-star:before,
.icon-marker-filled:before,
.icon-marker:before,
.icon-medkit:before,
.icon-meh-face:before,
.icon-menu:before,
.icon-merchant:before,
.icon-minus:before,
.icon-mobile:before,
.icon-moneybag:before,
.icon-mug:before,
.icon-neutral:before,
.icon-next:before,
.icon-now:before,
.icon-nuke:before,
.icon-out:before,
.icon-parallel:before,
.icon-paw:before,
.icon-pencil:before,
.icon-phone-nocall:before,
.icon-phone:before,
.icon-pie:before,
.icon-pinky-left:before,
.icon-pinky-right:before,
.icon-pinterest:before,
.icon-plus:before,
.icon-previous:before,
.icon-puzzle:before,
.icon-question:before,
.icon-quote:before,
.icon-recycle:before,
.icon-rewards:before,
.icon-room:before,
.icon-sad:before,
.icon-salesforce:before,
.icon-save:before,
.icon-seating:before,
.icon-services:before,
.icon-share:before,
.icon-shopping:before,
.icon-skull:before,
.icon-smiley-face:before,
.icon-smiley:before,
.icon-sold-out:before,
.icon-specials:before,
.icon-star-filled:before,
.icon-support:before,
.icon-tag:before,
.icon-target:before,
.icon-team:before,
.icon-text-tool:before,
.icon-thief:before,
.icon-thumbs-down:before,
.icon-thumbs-up:before,
.icon-tickets:before,
.icon-tophat:before,
.icon-trash:before,
.icon-tree:before,
.icon-truck:before,
.icon-twitter:before,
.icon-user:before,
.icon-video:before,
.icon-vimeo:before,
.icon-vip:before,
.icon-wand:before,
.icon-weights:before,
.icon-wizard-hat:before,
.icon-wrench:before,
.icon-x:before,
.icon-yin-yang:before,
.icon-your-deals:before,
.icon-zoom:before {
  font-family: Groupon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-your-deals:before {
  content: "\e000";
}
.icon-yin-yang:before {
  content: "\e001";
}
.icon-x:before {
  content: "\e002";
}
.icon-wrench:before {
  content: "\e003";
}
.icon-weights:before {
  content: "\e004";
}
.icon-wand:before {
  content: "\e005";
}
.icon-vip:before {
  content: "\e006";
}
.icon-user:before {
  content: "\e007";
}
.icon-truck:before {
  content: "\e008";
}
.icon-trash:before {
  content: "\e009";
}
.icon-tophat:before {
  content: "\e00a";
}
.icon-tickets:before {
  content: "\e00b";
}
.icon-thumbs-up:before {
  content: "\e00c";
}
.icon-thief:before {
  content: "\e00d";
}
.icon-text-tool:before {
  content: "\e00e";
}
.icon-target:before {
  content: "\e00f";
}
.icon-tag:before {
  content: "\e010";
}
.icon-star-filled:before {
  content: "\e011";
}
.icon-smiley:before {
  content: "\e012";
}
.icon-skull:before {
  content: "\e013";
}
.icon-shopping:before {
  content: "\e014";
}
.icon-share:before {
  content: "\e015";
}
.icon-services:before {
  content: "\e016";
}
.icon-sad:before {
  content: "\e017";
}
.icon-rewards:before {
  content: "\e018";
}
.icon-question:before {
  content: "\e019";
}
.icon-plus:before {
  content: "\e01a";
}
.icon-phone:before {
  content: "\e01b";
}
.icon-phone-nocall:before {
  content: "\e01c";
}
.icon-pencil:before {
  content: "\e01d";
}
.icon-paw:before {
  content: "\e01e";
}
.icon-parallel:before {
  content: "\e01f";
}
.icon-out:before {
  content: "\e020";
}
.icon-now:before {
  content: "\e021";
}
.icon-neutral:before {
  content: "\e022";
}
.icon-mug:before {
  content: "\e023";
}
.icon-moneybag:before {
  content: "\e024";
}
.icon-minus:before {
  content: "\e025";
}
.icon-menu:before {
  content: "\e026";
}
.icon-marker:before {
  content: "\e027";
}
.icon-magnifying-glass:before {
  content: "\e028";
}
.icon-logo-g:before {
  content: "\e029";
}
.icon-lock:before {
  content: "\e02a";
}
.icon-lock-open:before {
  content: "\e02b";
}
.icon-loading:before {
  content: "\e02c";
}
.icon-link:before {
  content: "\e02e";
}
.icon-leaf:before {
  content: "\e02f";
}
.icon-layout:before {
  content: "\e030";
}
.icon-info:before {
  content: "\e031";
}
.icon-image:before {
  content: "\e032";
}
.icon-home:before {
  content: "\e034";
}
.icon-groupon-cat:before {
  content: "\e036";
}
.icon-graduate-cap:before {
  content: "\e038";
}
.icon-goods:before {
  content: "\e039";
}
.icon-glove:before {
  content: "\e03a";
}
.icon-gift:before {
  content: "\e03b";
}
.icon-getaways:before {
  content: "\e03c";
}
.icon-gear:before {
  content: "\e03d";
}
.icon-gavel:before {
  content: "\e03e";
}
.icon-g2:before {
  content: "\e03f";
}
.icon-food-and-drink:before {
  content: "\e040";
}
.icon-flag:before {
  content: "\e041";
}
.icon-filter:before {
  content: "\e042";
}
.icon-dollar:before {
  content: "\e045";
}
.icon-dice:before {
  content: "\e046";
}
.icon-daily-deals:before {
  content: "\e047";
}
.icon-credit-card:before {
  content: "\e049";
}
.icon-courthouse:before {
  content: "\e04a";
}
.icon-corner:before {
  content: "\e04b";
}
.icon-cocktail:before {
  content: "\e04c";
}
.icon-cloud:before {
  content: "\e04d";
}
.icon-circle:before {
  content: "\e04f";
}
.icon-check:before {
  content: "\e050";
}
.icon-check-circle:before {
  content: "\e051";
}
.icon-car:before {
  content: "\e052";
}
.icon-currency:before {
  content: "\e053";
}
.icon-bubble-open:before {
  content: "\e054";
}
.icon-bubble-closed:before {
  content: "\e055";
}
.icon-box:before {
  content: "\e056";
}
.icon-book-ring:before {
  content: "\e057";
}
.icon-beauty-wellness-healthcare:before {
  content: "\e058";
}
.icon-bars:before {
  content: "\e059";
}
.icon-barcode:before {
  content: "\e05a";
}
.icon-asterisk:before {
  content: "\e05b";
}
.icon-arrow-up:before {
  content: "\e05c";
}
.icon-arrow-right:before {
  content: "\e05d";
}
.icon-arrow-pointer-up:before {
  content: "\e05e";
}
.icon-arrow-replay:before {
  content: "\e05f";
}
.icon-arrow-nav-right:before {
  content: "\e060";
}
.icon-arrow-nav-left:before {
  content: "\e061";
}
.icon-arrow-left:before {
  content: "\e062";
}
.icon-arrow-down:before {
  content: "\e063";
}
.icon-arrow-curved:before {
  content: "\e064";
}
.icon-alert:before {
  content: "\e065";
}
.icon-nuke:before {
  content: "\e066";
}
.icon-puzzle:before {
  content: "\e067";
}
.icon-hourglass:before {
  content: "\e033";
}
.icon-tree:before {
  content: "\e068";
}
.icon-document-search:before {
  content: "\e06f";
}
.icon-calendar:before {
  content: "\e043";
}
.icon-twitter:before {
  content: "\e044";
}
.icon-pinterest:before {
  content: "\e069";
}
.icon-linkedin:before {
  content: "\e06a";
}
.icon-google-plus:before {
  content: "\e06b";
}
.icon-feed:before {
  content: "\e06c";
}
.icon-facebook:before {
  content: "\e06e";
}
.icon-email:before {
  content: "\e070";
}
.icon-circle-alert:before {
  content: "\e071";
}
.icon-heart-filled:before {
  content: "\e035";
}
.icon-mobile:before {
  content: "\e072";
}
.icon-quote:before {
  content: "\e06d";
}
.icon-arrow-up-large:before {
  content: "\e074";
}
.icon-arrow-right-large:before {
  content: "\e075";
}
.icon-arrow-left-large:before {
  content: "\e076";
}
.icon-arrow-down-large:before {
  content: "\e077";
}
.icon-gaspump:before {
  content: "\e078";
}
.icon-bullhorn:before {
  content: "\e079";
}
.icon-list:before {
  content: "\e02d";
}
.icon-video:before {
  content: "\e07b";
}
.icon-recycle:before {
  content: "\e07c";
}
.icon-save:before {
  content: "\e07d";
}
.icon-import:before {
  content: "\e07e";
}
.icon-bucks:before {
  content: "\e080";
}
.icon-room:before {
  content: "\e081";
}
.icon-add:before {
  content: "\e082";
}
.icon-medkit:before {
  content: "\e083";
}
.icon-wizard-hat:before {
  content: "\e084";
}
.icon-pie:before {
  content: "\e085";
}
.icon-child:before {
  content: "\e101";
}
.icon-compass:before {
  content: "\e103";
}
.icon-dot-scale:before {
  content: "\e102";
}
.icon-salesforce:before {
  content: "\e07f";
}
.icon-pinky-left:before {
  content: "\e087";
}
.icon-thumbs-down:before {
  content: "\e088";
}
.icon-arrow-nav-up:before {
  content: "\e089";
}
.icon-arrow-nav-down:before {
  content: "\e08a";
}
.icon-marker-filled:before {
  content: "\e08b";
}
.icon-group:before {
  content: "\e037";
}
.icon-sold-out:before {
  content: "\e08c";
}
.icon-magnifying-star:before {
  content: "\e08d";
}
.icon-close:before {
  content: "\e04e";
}
.icon-cut:before {
  content: "\e048";
}
.icon-pinky-right:before {
  content: "\e086";
}
.icon-binoculars:before {
  content: "\e600";
}
.icon-checklist:before {
  content: "\e601";
}
.icon-groupon-box:before {
  content: "\e602";
}
.icon-factory:before {
  content: "\e603";
}
.icon-crown:before {
  content: "\e604";
}
.icon-merchant:before {
  content: "\e605";
}
.icon-list-unordered:before {
  content: "\e07a";
}
.icon-zoom:before {
  content: "\e607";
}
.icon-smiley-face:before {
  content: "\e608";
}
.icon-meh-face:before {
  content: "\e609";
}
.icon-grid:before {
  content: "\e60a";
}
.icon-groupon:before {
  content: "\e073";
}
.icon-key:before {
  content: "\e60b";
}
.icon-team:before {
  content: "\e60c";
}
.icon-seating:before {
  content: "\e60d";
}
.icon-support:before {
  content: "\e606";
}
.icon-vimeo:before {
  content: "\e60e";
}
.icon-download:before {
  content: "\e60f";
}
.icon-deal-ended:before {
  content: "\e610";
}
.icon-broadcast:before {
  content: "\e611";
}
.icon-next:before {
  content: "\e612";
}
.icon-previous:before {
  content: "\e613";
}
.icon-gnome:before {
  content: "\e614";
}
.icon-specials:before {
  content: "\e615";
}
.icon-glassdoor:before {
  content: "\e616";
}
@font-face {
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 400;
  src: url("../../public/getaways-xnet/assets/OpenSansRegular-v2.woff2") format('woff2'), url("../../public/getaways-xnet/assets/OpenSansRegular-v2.woff");
}
@font-face {
  font-family: 'OpenSansSemiBold';
  font-style: normal;
  font-weight: 600;
  src: url("../../public/getaways-xnet/assets/OpenSansSemiBold-v2.woff2") format('woff2'), url("../../public/getaways-xnet/assets/OpenSansSemiBold-v2.woff");
}
@font-face {
  font-family: 'OpenSansLight';
  font-style: normal;
  font-weight: 300;
  src: url("../../public/getaways-xnet/assets/OpenSansLight-v2.woff2") format('woff2'), url("../../public/getaways-xnet/assets/OpenSansLight-v2.woff") format('woff');
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  background-color: #fff;
  position: relative;
}
html {
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  color: #333;
}
a {
  color: #0076a8;
  line-height: inherit;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #0ab6ef;
}
h1 {
  font-size: 44px;
  line-height: 60px;
  margin: 20px 0 40px 0;
}
h2 {
  font-size: 25px;
  line-height: 40px;
}
h3 {
  font-size: 19px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
h3,
h4,
h5,
h6,
th,
td,
fieldset {
  line-height: 20px;
}
p,
h2,
h3,
ul,
ol,
dl,
dd,
figure,
blockquote {
  margin: 0 0 20px 0;
}
h4,
h5,
h6 {
  margin: 0;
}
blockquote {
  padding: 0 20px;
  color: #666;
}
blockquote cite {
  display: block;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a,
blockquote cite a:visited {
  color: #666;
}
abbr,
acronym {
  font-size: 12.6px;
  border-bottom: 1px dotted;
  cursor: help;
}
acronym {
  text-transform: uppercase;
}
code {
  font-weight: bold;
  background-color: #eaeaea;
  padding: 2px 5px;
  color: #c5299b;
}
small {
  font-size: 11px;
  color: #666;
  line-height: inherit;
}
figcaption {
  font-size: 12.6px;
  color: #666;
}
hr {
  margin: 19px 0 20px 0;
  border: solid #ccc;
  border-width: 0 0 1px 0;
  clear: both;
}
ul,
ol {
  padding-left: 20px;
}
li > ul,
li > ol {
  margin: 0;
}
dt {
  font-weight: bold;
}
table {
  background-color: #fff;
  margin: 0 0 15px 0;
}
table caption {
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  text-align: left;
}
thead {
  border-top: 1px solid #ccc;
  background: #f5f5f5;
}
th,
td {
  padding: 5px 15px 4px 15px;
}
th {
  text-align: left;
}
td {
  border-top: 1px solid #ccc;
}
tbody tr:last-of-type td {
  border-bottom: 1px solid #ccc;
}
form {
  margin: 0 0 20px 0;
}
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
legend {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
}
label {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #666;
  font-weight: bold;
  margin: 5px 0;
}
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
select,
textarea {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #666;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 20px;
  height: 30px;
  margin-bottom: 10px;
}
input[type="text"][disabled],
input[type="password"][disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
select[disabled],
textarea[disabled] {
  background-color: #eaeaea;
  color: #999;
}
select {
  border-radius: 0;
  position: relative;
}
select[multiple] {
  background-image: none;
  padding: 5px 10px;
}
textarea {
  resize: vertical;
  overflow: auto;
}
textarea,
select[multiple] {
  height: auto;
}
input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px;
  font-size: 20px;
}
input[type="submit"],
input[type="button"],
input[type="reset"],
.btn,
input[type="submit"].btn-primary,
input[type="button"].btn-primary,
.btn-primary,
input[type="submit"].btn-info,
input[type="button"].btn-info,
.btn-info,
input[type="submit"].btn-success,
input[type="button"].btn-success,
.btn-success,
input[type="submit"].btn-warning,
input[type="button"].btn-warning,
.btn-warning,
input[type="submit"].btn-caution,
input[type="button"].btn-caution,
.btn-caution,
input[type="submit"].btn-danger,
input[type="button"].btn-danger,
.btn-danger,
input[type="submit"].btn-inverse,
input[type="button"].btn-inverse,
.btn-inverse {
  display: inline-block;
  padding: 5px 20px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  text-align: center;
  background-repeat: repeat-x;
  color: #666;
  text-shadow: 0 1px rgba(255,255,255,0.3);
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  background-image: -webkit-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: -moz-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: linear-gradient(to bottom, #f9f9f9, #ddd);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 1px 2px rgba(0,0,0,0.05);
  border-bottom-color: #b3b3b3;
}
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
.btn:hover,
input[type="submit"].btn-primary:hover,
input[type="button"].btn-primary:hover,
.btn-primary:hover,
input[type="submit"].btn-info:hover,
input[type="button"].btn-info:hover,
.btn-info:hover,
input[type="submit"].btn-success:hover,
input[type="button"].btn-success:hover,
.btn-success:hover,
input[type="submit"].btn-warning:hover,
input[type="button"].btn-warning:hover,
.btn-warning:hover,
input[type="submit"].btn-caution:hover,
input[type="button"].btn-caution:hover,
.btn-caution:hover,
input[type="submit"].btn-danger:hover,
input[type="button"].btn-danger:hover,
.btn-danger:hover,
input[type="submit"].btn-inverse:hover,
input[type="button"].btn-inverse:hover,
.btn-inverse:hover {
  color: #333;
  background-color: #ddd;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
  cursor: pointer;
}
input[type="submit"]:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
.btn:focus,
input[type="submit"].btn-primary:focus,
input[type="button"].btn-primary:focus,
.btn-primary:focus,
input[type="submit"].btn-info:focus,
input[type="button"].btn-info:focus,
.btn-info:focus,
input[type="submit"].btn-success:focus,
input[type="button"].btn-success:focus,
.btn-success:focus,
input[type="submit"].btn-warning:focus,
input[type="button"].btn-warning:focus,
.btn-warning:focus,
input[type="submit"].btn-caution:focus,
input[type="button"].btn-caution:focus,
.btn-caution:focus,
input[type="submit"].btn-danger:focus,
input[type="button"].btn-danger:focus,
.btn-danger:focus,
input[type="submit"].btn-inverse:focus,
input[type="button"].btn-inverse:focus,
.btn-inverse:focus {
  color: #666;
}
input[type="submit"]:disabled,
input[type="button"]:disabled,
input[type="reset"]:disabled,
.btn:disabled,
input[type="submit"].btn-primary:disabled,
input[type="button"].btn-primary:disabled,
.btn-primary:disabled,
input[type="submit"].btn-info:disabled,
input[type="button"].btn-info:disabled,
.btn-info:disabled,
input[type="submit"].btn-success:disabled,
input[type="button"].btn-success:disabled,
.btn-success:disabled,
input[type="submit"].btn-warning:disabled,
input[type="button"].btn-warning:disabled,
.btn-warning:disabled,
input[type="submit"].btn-caution:disabled,
input[type="button"].btn-caution:disabled,
.btn-caution:disabled,
input[type="submit"].btn-danger:disabled,
input[type="button"].btn-danger:disabled,
.btn-danger:disabled,
input[type="submit"].btn-inverse:disabled,
input[type="button"].btn-inverse:disabled,
.btn-inverse:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
input[type="file"] {
  width: 100%;
}
input[type="file"]::-webkit-file-upload-button {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  background-repeat: repeat-x;
  color: #666;
  text-shadow: 0 1px rgba(255,255,255,0.3);
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  background-image: -webkit-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: -moz-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: linear-gradient(to bottom, #f9f9f9, #ddd);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.3), 0 1px 2px rgba(0,0,0,0.05);
  border-bottom-color: #b3b3b3;
  font-weight: normal;
}
input[type="file"]:focus {
  outline: -webkit-focus-ring-color auto 0;
}
input[type="search"] {
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNjRweCIgaGVpZ2h0PSI1NHB4IiB2aWV3Qm94PSIwIDAgNjQgNTQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDU0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiM5ODk4OTgiIGQ9Ik00Ni4yOSw0NC4wNjJMMzMuNjA1LDMxLjM5OGMtMC4wMjYtMC4wMjctMC4wNjMtMC4wMzMtMC4wODctMC4wNjFjMS43NDEtMi44MiwyLjY4OC02LjA2NSwyLjY4OC05LjQ2Mg0KCWMwLTQuODM3LTEuODg0LTkuMzg0LTUuMzA2LTEyLjgwMmMtMy40MTctMy40MTctNy45NjItNS4zLTEyLjc5OS01LjNjLTQuODM2LDAtOS4zOCwxLjg4Mi0xMi43OTksNS4zDQoJQzEuODgyLDEyLjQ5NSwwLDE3LjA0MiwwLDIxLjg3OWMwLDQuODMzLDEuODgyLDkuMzgxLDUuMzAzLDEyLjc5OWMzLjQxOCwzLjQyNCw3Ljk2NSw1LjMwOSwxMi44MDIsNS4zMDkNCgljMy4yMDQsMCw2LjI3Ni0wLjg0NCw4Ljk4My0yLjQwMmMwLjA1OSwwLjA2NCwwLjA3NSwwLjE0OCwwLjEzOCwwLjIwOWwxMi42ODYsMTIuNjYyYzAuODgyLDAuODc5LDIuMDM1LDEuMzE4LDMuMTkxLDEuMzE4DQoJYzEuMTU3LDAsMi4zMTUtMC40MzksMy4xOTgtMS4zMjRDNDguMDU5LDQ4LjY4NCw0OC4wNTksNDUuODI0LDQ2LjI5LDQ0LjA2MnogTTkuNTYyLDMwLjQxNmMtMi4yODItMi4yNzktMy41MzktNS4zMTItMy41MzktOC41MzcNCgljMC0zLjIyOCwxLjI1Ni02LjI2MSwzLjUzOS04LjU0NGMyLjI4My0yLjI3OSw1LjMxNS0zLjUzOCw4LjU0MS0zLjUzOGMzLjIyNiwwLDYuMjU4LDEuMjU2LDguNTM5LDMuNTM4DQoJYzIuMjgyLDIuMjgzLDMuNTQxLDUuMzE5LDMuNTQxLDguNTQ0YzAsMy4yMjYtMS4yNTksNi4yNTgtMy41NDEsOC41NDFDMjIuMDgxLDM0Ljk5MiwxNC4xMjcsMzQuOTkyLDkuNTYyLDMwLjQxNnoiLz4NCjwvc3ZnPg0K") no-repeat right 50%;
  background-size: 20px 16px;
  padding-right: 25px;
}
.row {
  width: 1000px;
  max-width: 100%;
  min-width: 768px;
  margin: 0 auto;
}
.row .row {
  width: auto;
  max-width: none;
  min-width: 0;
  margin: 0 -10px;
}
.row.collapse .column,
.row.collapse .columns {
  padding: 0;
}
.row .row.collapse {
  margin: 0;
}
.column,
.columns {
  float: left;
  min-height: 1px;
  padding: 0 10px;
  position: relative;
}
.column.centered,
.columns.centered {
  float: none;
  margin: 0 auto;
}
[class*="column"] + [class*="column"]:last-child {
  float: right;
}
[class*="column"] + [class*="column"].end {
  float: left;
}
.row .one {
  width: 8.333%;
}
.row .two {
  width: 16.667%;
}
.row .three {
  width: 25%;
}
.row .four {
  width: 33.333%;
}
.row .five {
  width: 41.667%;
}
.row .six {
  width: 50%;
}
.row .seven {
  width: 58.333%;
}
.row .eight {
  width: 66.667%;
}
.row .nine {
  width: 75%;
}
.row .ten {
  width: 83.333%;
}
.row .eleven {
  width: 91.667%;
}
.row .twelve {
  width: 100%;
}
.row .offset-by-one {
  margin-left: 8.333%;
}
.row .offset-by-two {
  margin-left: 16.667%;
}
.row .offset-by-three {
  margin-left: 25%;
}
.row .offset-by-four {
  margin-left: 33.333%;
}
.row .offset-by-five {
  margin-left: 41.667%;
}
.row .offset-by-six {
  margin-left: 50%;
}
.row .offset-by-seven {
  margin-left: 58.333%;
}
.row .offset-by-eight {
  margin-left: 66.667%;
}
.row .offset-by-nine {
  margin-left: 75%;
}
.row .offset-by-ten {
  margin-left: 83.333%;
}
.push-two {
  left: 16.667%;
}
.pull-two {
  right: 16.667%;
}
.push-three {
  left: 25%;
}
.pull-three {
  right: 25%;
}
.push-four {
  left: 33.333%;
}
.pull-four {
  right: 33.333%;
}
.push-five {
  left: 41.667%;
}
.pull-five {
  right: 41.667%;
}
.push-six {
  left: 50%;
}
.pull-six {
  right: 50%;
}
.push-seven {
  left: 58.333%;
}
.pull-seven {
  right: 58.333%;
}
.push-eight {
  left: 66.667%;
}
.pull-eight {
  right: 66.667%;
}
.push-nine {
  left: 75%;
}
.pull-nine {
  right: 75%;
}
.push-ten {
  left: 83.333%;
}
.pull-ten {
  right: 83.333%;
}
img,
object,
embed {
  max-width: 100%;
  height: auto;
}
img {
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object,
#googlemap img,
#googlemap embed,
#googlemap object {
  max-width: none !important;
}
.row,
.clearfix,
.progress-meter,
.pagination,
.tabs-container .tabs,
.tab-buttons,
.tab-button-menu {
  *zoom: 1;
}
.row:before,
.row:after,
.clearfix:before,
.progress-meter:before,
.pagination:before,
.tabs-container .tabs:before,
.tab-buttons:before,
.tab-button-menu:before,
.clearfix:after,
.progress-meter:after,
.pagination:after,
.tabs-container .tabs:after,
.tab-buttons:after,
.tab-button-menu:after {
  content: "";
  display: table;
}
.row:after,
.clearfix:after,
.progress-meter:after,
.pagination:after,
.tabs-container .tabs:after,
.tab-buttons:after,
.tab-button-menu:after {
  clear: both;
}
.block-grid {
  display: block;
  overflow: hidden;
  padding: 0;
}
.block-grid > li {
  display: block;
  height: auto;
  float: left;
}
.block-grid.one-up {
  margin: 0;
}
.block-grid.one-up > li {
  width: 100%;
  padding: 0 0 10px;
}
.block-grid.two-up {
  margin: 0 -10px;
}
.block-grid.two-up > li {
  width: 50%;
  padding: 0 10px 10px;
}
.block-grid.three-up {
  margin: 0 -12px;
}
.block-grid.three-up > li {
  width: 33.33%;
  padding: 0 12px 12px;
}
.block-grid.four-up {
  margin: 0 -10px;
}
.block-grid.four-up > li {
  width: 25%;
  padding: 0 10px 10px;
}
.block-grid.five-up {
  margin: 0 -8px;
}
.block-grid.five-up > li {
  width: 20%;
  padding: 0 8px 8px;
}
@media only screen and (max-width: 767px) {
  body.responsive {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .responsive .row {
    width: auto;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .responsive .row .row > .columns {
    padding: 0;
  }
  .responsive .column,
  .responsive .columns {
    width: auto !important;
    float: none;
  }
  .responsive .column:last-child,
  .responsive .columns:last-child {
    float: none;
  }
  .responsive [class*="column"] + [class*="column"]:last-child {
    float: none;
  }
  .responsive .column:before,
  .responsive .columns:before,
  .responsive .column:after,
  .responsive .columns:after {
    content: "";
    display: table;
  }
  .responsive .column:after,
  .responsive .columns:after {
    clear: both;
  }
  .responsive .offset-by-one,
  .responsive .offset-by-two,
  .responsive .offset-by-three,
  .responsive .offset-by-four,
  .responsive .offset-by-five,
  .responsive .offset-by-six,
  .responsive .offset-by-seven,
  .responsive .offset-by-eight,
  .responsive .offset-by-nine,
  .responsive .offset-by-ten {
    margin-left: 0 !important;
  }
  .responsive .push-two,
  .responsive .push-three,
  .responsive .push-four,
  .responsive .push-five,
  .responsive .push-six,
  .responsive .push-seven,
  .responsive .push-eight,
  .responsive .push-nine,
  .responsive .push-ten {
    left: auto;
  }
  .responsive .pull-two,
  .responsive .pull-three,
  .responsive .pull-four,
  .responsive .pull-five,
  .responsive .pull-six,
  .responsive .pull-seven,
  .responsive .pull-eight,
  .responsive .pull-nine,
  .responsive .pull-ten {
    right: auto;
  }
  .responsive .row .mobile-one {
    width: 25% !important;
    float: left;
    padding: 0 15px;
  }
  .responsive .row .mobile-one:last-child {
    float: right;
  }
  .responsive .row.collapse .mobile-one {
    padding: 0;
  }
  .responsive .row .mobile-two {
    width: 50% !important;
    float: left;
    padding: 0 15px;
  }
  .responsive .row .mobile-two:last-child {
    float: right;
  }
  .responsive .row.collapse .mobile-two {
    padding: 0;
  }
  .responsive .row .mobile-three {
    width: 75% !important;
    float: left;
    padding: 0 15px;
  }
  .responsive .row .mobile-three:last-child {
    float: right;
  }
  .responsive .row.collapse .mobile-three {
    padding: 0;
  }
  .responsive .row .mobile-four {
    width: 100% !important;
    float: left;
    padding: 0 15px;
  }
  .responsive .row .mobile-four:last-child {
    float: right;
  }
  .responsive .row.collapse .mobile-four {
    padding: 0;
  }
  .responsive .push-one-mobile {
    left: 25%;
  }
  .responsive .pull-one-mobile {
    right: 25%;
  }
  .responsive .push-two-mobile {
    left: 50%;
  }
  .responsive .pull-two-mobile {
    right: 50%;
  }
  .responsive .push-three-mobile {
    left: 75%;
  }
  .responsive .pull-three-mobile {
    right: 75%;
  }
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.hide {
  display: none;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.spinner {
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 0.65s;
  -moz-animation-duration: 0.65s;
  -o-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  font-family: "Groupon";
  font-size: 25px;
  line-height: 1;
  display: inline-block;
}
.spinner:before {
  content: "\e02c";
}
.full-height .main.row {
  border-bottom: 0;
}
.full-width .row {
  width: auto;
}
.full-width.with-nav .main > .columns > .sidenav {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  padding: 20px;
}
.full-width.with-nav .main > .columns > section {
  margin-left: 200px;
  border-left: 1px solid #ccc;
  background-color: #fff;
  padding: 20px;
}
.full-width.with-sidebar .main > .columns > section {
  margin-right: 280px;
  border-right: 1px solid #ccc;
  background-color: #fff;
  padding: 20px;
}
.full-width.with-sidebar .main > .columns > .sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 280px;
  padding: 20px;
}
.fixed-width > .row,
.fixed-width header > .row {
  width: 1200px;
  min-width: 1200px;
}
.panel-in > .row {
  background-color: #fff;
  border: solid #ccc;
  border-width: 0 1px;
}
.panel-in header > .row {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.panel-in .panel > section {
  min-height: 70px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}
.panel-in .main {
  background-color: #fafafa;
  border-bottom: 1px solid #ccc;
}
.panel-in .main > aside {
  padding: 20px;
}
.panel-in .main > section {
  background-color: #fff;
  padding: 20px;
}
.with-nav .main > section {
  border-left: 1px solid #ccc;
}
.with-sidebar .main > section {
  border-right: 1px solid #ccc;
}
.panel-out .panel {
  background-color: #eff5e7;
  border-bottom: 2px solid #dfe7ce;
  padding: 20px 0;
}
.panel-out .main {
  position: relative;
  top: -20px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
.panel-out .main > section {
  padding: 20px;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.panel-out.with-nav .main > section {
  border-top-left-radius: 0;
}
.panel-out.with-sidebar .main > section {
  border-top-right-radius: 0;
}
.unstyled,
ol.inline,
ul.inline {
  list-style: none;
  padding: 0;
}
ol.inline,
ul.inline {
  display: inline-block;
}
ol.inline li,
ul.inline li {
  display: inline;
}
.list-arrow {
  list-style: none;
  position: relative;
}
.list-arrow li:before {
  content: "\00BB";
  position: absolute;
  left: 0;
  font-size: 120%;
  line-height: 1.1;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}
.table-bordered thead,
.table-bordered tbody,
.table-bordered-horizontal thead,
.table-bordered-horizontal tbody {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.table-bordered thead td + td,
.table-bordered tbody td + td {
  border-left: 1px solid #ccc;
}
.table-row-hover tbody tr:hover {
  background-color: #eefaff;
}
.table-condensed caption {
  font-size: 12px;
}
.table-condensed th,
.table-condensed td {
  padding: 5px 10px 4px 10px;
  font-size: 12px;
  line-height: 18px;
}
.table-dashboard th,
.table-dashboard-mini th,
.table-dashboard td,
.table-dashboard-mini td {
  text-align: center;
  border-top: 0;
}
.table-dashboard th + th,
.table-dashboard-mini th + th,
.table-dashboard td + td,
.table-dashboard-mini td + td {
  border-left: 1px solid #ccc;
}
.table-dashboard td,
.table-dashboard-mini td {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  padding: 4px 28px;
  vertical-align: bottom;
}
.table-dashboard th,
.table-dashboard-mini th {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  color: #999;
  padding: 6px 15px;
  vertical-align: top;
}
.table-dashboard tbody tr:last-of-type td,
.table-dashboard-mini tbody tr:last-of-type td {
  border-bottom: 0;
}
.table-dashboard-mini th {
  font-size: 9px;
  white-space: nowrap;
  padding: 2px 5px 10px 5px;
}
.table-dashboard-mini td {
  padding: 10px 5px 2px 5px;
  font-size: 14px;
}
.checkbox,
.radio {
  padding: 0;
  margin: 0 0 10px 0;
}
.checkbox label,
.radio label {
  font-weight: normal;
  margin-top: 0;
}
.checkbox label.inline,
.radio label.inline {
  margin-right: 10px;
  line-height: 30px;
}
.help-text {
  color: #999;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 10px;
  font-weight: normal;
}
span.help-text {
  padding-left: 10px;
}
input.required,
input.invalid,
input.valid,
select.required,
select.invalid,
select.valid,
textarea.required,
textarea.invalid,
textarea.valid {
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 20px 16px;
  padding-right: 20px;
}
input.required,
select.required,
textarea.required {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSI3NHB4IiBoZWlnaHQ9IjU0cHgiIHZpZXdCb3g9IjAgMCA3NCA1NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzQgNTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNCQTIwMzI7IiBkPSJNNDQuMjM0LDIzLjIzM0gzMy4wODVsNy44ODYtNy44ODZjMS40NzEtMS40NywxLjQ3MS0zLjg1NCwwLTUuMzIyYy0xLjQ3MS0xLjQ3LTMuODUzLTEuNDctNS4zMiwwDQoJbC03Ljg5MSw3Ljg5MVY2Ljc2M2MwLTIuMDc5LTEuNjg4LTMuNzY0LTMuNzY1LTMuNzY0Yy0yLjA3OSwwLTMuNzY0LDEuNjg1LTMuNzY0LDMuNzY0djExLjEzMmwtNy44NjgtNy44Nw0KCWMtMS40Ny0xLjQ3LTMuODUyLTEuNDctNS4zMjIsMGMtMS40NywxLjQ2OS0xLjQ3LDMuODUyLDAsNS4zMjJsNy44ODUsNy44ODZIMy43NjRDMS42ODUsMjMuMjMzLDAsMjQuOTE3LDAsMjYuOTk3DQoJYzAsMi4wNzksMS42ODUsMy43NjQsMy43NjQsMy43NjRoMTEuMTUzbC03Ljg3Niw3Ljg3N2MtMS40NywxLjQ3MS0xLjQ3LDMuODUzLDAsNS4zMjFjMC43MzUsMC43MzUsMS42OTgsMS4xMDMsMi42NjEsMS4xMDMNCglzMS45MjctMC4zNjcsMi42NjEtMS4xMDNsNy44NjgtNy44Njd2MTEuMTQ0YzAsMi4wNzgsMS42ODUsMy43NjUsMy43NjQsMy43NjVjMi4wNzgsMCwzLjc2NS0xLjY4NywzLjc2NS0zLjc2NVYzNi4wNzFsNy44OTEsNy44ODgNCgljMC43MzIsMC43MzUsMS42OTUsMS4xMDMsMi42NiwxLjEwM2MwLjk2MywwLDEuOTI2LTAuMzY3LDIuNjYtMS4xMDNjMS40NzEtMS40NywxLjQ3MS0zLjg1MywwLTUuMzIxbC03Ljg3Ny03Ljg3N2gxMS4xNDENCgljMi4wOCwwLDMuNzY1LTEuNjg2LDMuNzY1LTMuNzY0QzQ3Ljk5OCwyNC45MTcsNDYuMzEyLDIzLjIzMyw0NC4yMzQsMjMuMjMzeiIvPg0KPC9zdmc+DQo=");
}
input.invalid,
select.invalid,
textarea.invalid {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSI3NHB4IiBoZWlnaHQ9IjU0cHgiIHZpZXdCb3g9IjAgMCA3NCA1NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzQgNTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNCQTIwMzE7IiBkPSJNMi4wNTcsNS4wNTdjLTIuNzQzLDIuNzQzLTIuNzQzLDguMjI5LDAsMTAuOTcyTDEzLjAyOSwyN0wyLjA1NywzNy45NzINCgljLTIuNzQzLDIuNzQyLTIuNzQzLDguMjI5LDAsMTAuOTcxYzIuNzQzLDIuNzQzLDguMjI5LDIuNzQzLDEwLjk3MiwwTDI0LDM3Ljk3MmwxMC45NzIsMTAuOTcxYzIuNzQyLDIuNzQzLDguMjI5LDIuNzQzLDEwLjk3MSwwDQoJYzIuNzQzLTIuNzQyLDIuNzQzLTguMjI5LDAtMTAuOTcxTDM0Ljk3MiwyN2wxMC45NzEtMTAuOTcxYzIuNzQzLTIuNzQzLDIuNzQzLTguMjI5LDAtMTAuOTcyYy0yLjc0Mi0yLjc0My04LjIyOS0yLjc0My0xMC45NzEsMA0KCUwyNCwxNi4wMjlMMTMuMDI5LDUuMDU3QzEwLjI4NiwyLjMxNCw0LjgsMi4zMTQsMi4wNTcsNS4wNTd6Ii8+DQo8L3N2Zz4NCg==");
}
input.valid,
select.valid,
textarea.valid {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSI3NHB4IiBoZWlnaHQ9IjU0cHgiIHZpZXdCb3g9IjAgMCA3NCA1NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzQgNTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiM4QUI2NTQ7IiBkPSJNMTcuMDA0LDQ1LjUxYzAsMC0wLjAwMywwLTAuMDA2LDBjLTEuNTk0LDAtMy4xMjUtMC42MzctNC4yNTEtMS43NjlsLTExLTExLjA0OA0KCWMtMi4zMzgtMi4zNTItMi4zMy02LjE1MiwwLjAyMS04LjQ5YzIuMzUxLTIuMzM2LDYuMTQ5LTIuMzMsOC40OTMsMC4wMThsNi43NTIsNi43ODJsMjAuNzMzLTIwLjc1Mg0KCWMyLjM0NS0yLjM0Nyw2LjE0OC0yLjM0Nyw4LjQ5NC0wLjAwM2MyLjM0NCwyLjM0NSwyLjM0NCw2LjE0NiwwLjAwMiw4LjQ5bC0yNC45OSwyNS4wMTNDMjAuMTI0LDQ0Ljg3NywxOC41OTYsNDUuNTEsMTcuMDA0LDQ1LjUxDQoJTDE3LjAwNCw0NS41MXoiLz4NCjwvc3ZnPg0K");
}
input[type="submit"].btn-primary,
input[type="button"].btn-primary,
.btn-primary,
input[type="submit"].btn-info,
input[type="button"].btn-info,
.btn-info,
input[type="submit"].btn-success,
input[type="button"].btn-success,
.btn-success,
input[type="submit"].btn-warning,
input[type="button"].btn-warning,
.btn-warning,
input[type="submit"].btn-caution,
input[type="button"].btn-caution,
.btn-caution,
input[type="submit"].btn-danger,
input[type="button"].btn-danger,
.btn-danger,
input[type="submit"].btn-inverse,
input[type="button"].btn-inverse,
.btn-inverse {
  color: #fff;
  text-shadow: 0 1px 0 rgba(0,0,0,0.3);
}
input[type="submit"].btn-primary:hover,
input[type="submit"].btn-primary:active,
input[type="submit"].btn-primary:focus,
input[type="button"].btn-primary:hover,
input[type="button"].btn-primary:active,
input[type="button"].btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
input[type="submit"].btn-info:hover,
input[type="submit"].btn-info:active,
input[type="submit"].btn-info:focus,
input[type="button"].btn-info:hover,
input[type="button"].btn-info:active,
input[type="button"].btn-info:focus,
.btn-info:hover,
.btn-info:active,
.btn-info:focus,
input[type="submit"].btn-success:hover,
input[type="submit"].btn-success:active,
input[type="submit"].btn-success:focus,
input[type="button"].btn-success:hover,
input[type="button"].btn-success:active,
input[type="button"].btn-success:focus,
.btn-success:hover,
.btn-success:active,
.btn-success:focus,
input[type="submit"].btn-warning:hover,
input[type="submit"].btn-warning:active,
input[type="submit"].btn-warning:focus,
input[type="button"].btn-warning:hover,
input[type="button"].btn-warning:active,
input[type="button"].btn-warning:focus,
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus,
input[type="submit"].btn-caution:hover,
input[type="submit"].btn-caution:active,
input[type="submit"].btn-caution:focus,
input[type="button"].btn-caution:hover,
input[type="button"].btn-caution:active,
input[type="button"].btn-caution:focus,
.btn-caution:hover,
.btn-caution:active,
.btn-caution:focus,
input[type="submit"].btn-danger:hover,
input[type="submit"].btn-danger:active,
input[type="submit"].btn-danger:focus,
input[type="button"].btn-danger:hover,
input[type="button"].btn-danger:active,
input[type="button"].btn-danger:focus,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus,
input[type="submit"].btn-inverse:hover,
input[type="submit"].btn-inverse:active,
input[type="submit"].btn-inverse:focus,
input[type="button"].btn-inverse:hover,
input[type="button"].btn-inverse:active,
input[type="button"].btn-inverse:focus,
.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse:focus {
  color: #fff;
}
input[type="submit"].btn-primary,
input[type="button"].btn-primary,
.btn-primary {
  background-color: #2a90a9;
  background-image: -webkit-linear-gradient(to bottom, #49beda 0%, #2a90a9 100%);
  background-image: -moz-linear-gradient(to bottom, #49beda 0%, #2a90a9 100%);
  background-image: -o-linear-gradient(to bottom, #49beda 0%, #2a90a9 100%);
  background-image: -ms-linear-gradient(to bottom, #49beda 0%, #2a90a9 100%);
  background-image: linear-gradient(to bottom, #49beda 0%, #2a90a9 100%);
  border: 0;
  text-shadow: none;
}
input[type="submit"].btn-primary:hover,
input[type="submit"].btn-primary:active,
input[type="button"].btn-primary:hover,
input[type="button"].btn-primary:active,
.btn-primary:hover,
.btn-primary:active {
  background-color: #30a4c1;
  background-image: -webkit-linear-gradient(to bottom, #5bc4de 0%, #30a4c1 100%);
  background-image: -moz-linear-gradient(to bottom, #5bc4de 0%, #30a4c1 100%);
  background-image: -o-linear-gradient(to bottom, #5bc4de 0%, #30a4c1 100%);
  background-image: -ms-linear-gradient(to bottom, #5bc4de 0%, #30a4c1 100%);
  background-image: linear-gradient(to bottom, #5bc4de 0%, #30a4c1 100%);
}
input[type="submit"].btn-secondary,
input[type="button"].btn-secondary,
.btn-secondary {
  background-color: #f0eeef;
  background-image: -webkit-linear-gradient(to bottom, #fffefe 0%, #f0eeef 100%);
  background-image: -moz-linear-gradient(to bottom, #fffefe 0%, #f0eeef 100%);
  background-image: -o-linear-gradient(to bottom, #fffefe 0%, #f0eeef 100%);
  background-image: -ms-linear-gradient(to bottom, #fffefe 0%, #f0eeef 100%);
  background-image: linear-gradient(to bottom, #fffefe 0%, #f0eeef 100%);
  border: 1px solid #e2e2e2;
  color: #333;
}
input[type="submit"].btn-secondary:hover,
input[type="submit"].btn-secondary:active,
input[type="button"].btn-secondary:hover,
input[type="button"].btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:active {
  background-color: #f4f2f3;
  background-image: -webkit-linear-gradient(to bottom, #fffefe 0%, #f4f2f3 100%);
  background-image: -moz-linear-gradient(to bottom, #fffefe 0%, #f4f2f3 100%);
  background-image: -o-linear-gradient(to bottom, #fffefe 0%, #f4f2f3 100%);
  background-image: -ms-linear-gradient(to bottom, #fffefe 0%, #f4f2f3 100%);
  background-image: linear-gradient(to bottom, #fffefe 0%, #f4f2f3 100%);
  border-color: #cdcdcd;
}
input[type="submit"].btn-info,
input[type="button"].btn-info,
.btn-info {
  background-color: #76d0e6;
  background-image: -webkit-linear-gradient(to bottom, #a1dfee, #4bc1de);
  background-image: -moz-linear-gradient(to bottom, #a1dfee, #4bc1de);
  background-image: linear-gradient(to bottom, #a1dfee, #4bc1de);
  border-color: #4bc1de #4bc1de #1e8aa5;
}
input[type="submit"].btn-info:hover,
input[type="submit"].btn-info:active,
input[type="button"].btn-info:hover,
input[type="button"].btn-info:active,
.btn-info:hover,
.btn-info:active {
  background-color: #4bc1de;
  background-image: none;
}
input[type="submit"].btn-success,
input[type="button"].btn-success,
.btn-success {
  background-color: #89b555;
  background-image: -webkit-linear-gradient(to bottom, #a2c479, #6f9641);
  background-image: -moz-linear-gradient(to bottom, #a2c479, #6f9641);
  background-image: linear-gradient(to bottom, #a2c479, #6f9641);
  border-color: #89b555 #89b555 #557232;
}
input[type="submit"].btn-success:hover,
input[type="submit"].btn-success:active,
input[type="button"].btn-success:hover,
input[type="button"].btn-success:active,
.btn-success:hover,
.btn-success:active {
  background-color: #6f9641;
  background-image: none;
}
input[type="submit"].btn-caution,
input[type="button"].btn-caution,
.btn-caution {
  background-color: #ffbe26;
  background-image: -webkit-linear-gradient(to bottom, #ffcd59, #f2a900);
  background-image: -moz-linear-gradient(to bottom, #ffcd59, #f2a900);
  background-image: linear-gradient(to bottom, #ffcd59, #f2a900);
  border-color: #f2a900 #f2a900 #8c6200;
}
input[type="submit"].btn-caution:hover,
input[type="submit"].btn-caution:active,
input[type="button"].btn-caution:hover,
input[type="button"].btn-caution:active,
.btn-caution:hover,
.btn-caution:active {
  background-color: #f2a900;
  background-image: none;
}
input[type="submit"].btn-warning,
input[type="button"].btn-warning,
.btn-warning {
  background-color: #fa6c21;
  background-image: -webkit-linear-gradient(to bottom, #fb8d53, #e35205);
  background-image: -moz-linear-gradient(to bottom, #fb8d53, #e35205);
  background-image: linear-gradient(to bottom, #fb8d53, #e35205);
  border-color: #e35205 #e35205 #7f2e03;
}
input[type="submit"].btn-warning:hover,
input[type="submit"].btn-warning:active,
input[type="button"].btn-warning:hover,
input[type="button"].btn-warning:active,
.btn-warning:hover,
.btn-warning:active {
  background-color: #e35205;
  background-image: none;
}
input[type="submit"].btn-danger,
input[type="button"].btn-danger,
.btn-danger {
  background-color: #ea0f3b;
  background-image: -webkit-linear-gradient(to bottom, #f23a5f, #ba0c2f);
  background-image: -moz-linear-gradient(to bottom, #f23a5f, #ba0c2f);
  background-image: linear-gradient(to bottom, #f23a5f, #ba0c2f);
  border-color: #ba0c2f #ba0c2f #5a0617;
}
input[type="submit"].btn-danger:hover,
input[type="submit"].btn-danger:active,
input[type="button"].btn-danger:hover,
input[type="button"].btn-danger:active,
.btn-danger:hover,
.btn-danger:active {
  background-color: #ba0c2f;
  background-image: none;
}
input[type="submit"].btn-inverse,
input[type="button"].btn-inverse,
.btn-inverse {
  background-color: #3b3b3b;
  background-image: -webkit-linear-gradient(to bottom, #555, #222);
  background-image: -moz-linear-gradient(to bottom, #555, #222);
  background-image: linear-gradient(to bottom, #555, #222);
  border-color: #222 #222 #000;
}
input[type="submit"].btn-inverse:hover,
input[type="submit"].btn-inverse:active,
input[type="button"].btn-inverse:hover,
input[type="button"].btn-inverse:active,
.btn-inverse:hover,
.btn-inverse:active {
  background-color: #222;
}
input[type="submit"].btn-large,
input[type="button"].btn-large,
.btn-large {
  font-size: 14px;
  padding: 8px 15px;
}
input[type="submit"].btn-small,
input[type="button"].btn-small,
.btn-small {
  font-size: 10px;
  padding: 2px 8px;
}
.radius {
  border-radius: 3px;
}
.rounded {
  border-radius: 18px;
}
input[type="submit"].btn-link,
input[type="button"].btn-link,
input[type="reset"].btn-link,
.btn.btn-link,
input.btn-link[type="submit"].btn-primary,
input.btn-link[type="button"].btn-primary,
.btn-link.btn-primary,
input.btn-link[type="submit"].btn-info,
input.btn-link[type="button"].btn-info,
.btn-link.btn-info,
input.btn-link[type="submit"].btn-success,
input.btn-link[type="button"].btn-success,
.btn-link.btn-success,
input.btn-link[type="submit"].btn-warning,
input.btn-link[type="button"].btn-warning,
.btn-link.btn-warning,
input.btn-link[type="submit"].btn-caution,
input.btn-link[type="button"].btn-caution,
.btn-link.btn-caution,
input.btn-link[type="submit"].btn-danger,
input.btn-link[type="button"].btn-danger,
.btn-link.btn-danger,
input.btn-link[type="submit"].btn-inverse,
input.btn-link[type="button"].btn-inverse,
.btn-link.btn-inverse {
  background-image: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #0076a8;
  font-weight: normal;
  padding: 5px 0;
}
input[type="submit"].btn-link:hover,
input[type="button"].btn-link:hover,
input[type="reset"].btn-link:hover,
.btn.btn-link:hover,
.btn-link.btn-primary:hover,
.btn-link.btn-info:hover,
.btn-link.btn-success:hover,
.btn-link.btn-warning:hover,
.btn-link.btn-caution:hover,
.btn-link.btn-danger:hover,
.btn-link.btn-inverse:hover {
  color: #0ab6ef;
}
.label {
  font-size: 12px;
  line-height: 22px;
  background-color: #ededed;
  color: #666;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  padding: 0 20px;
  border-radius: 11px;
}
.badge-counter {
  color: #fff;
  background-color: #aaa;
  text-shadow: 0 1px 0 rgba(0,0,0,0.25);
  border-radius: 11px;
  padding: 4px 8px;
  font-size: 11px;
  line-height: 11px;
  min-width: 24px;
  text-align: center;
  font-weight: bold;
}
.badge-single {
  color: #fff;
  background-color: #ccc;
  border-radius: 10px;
  text-shadow: 0 1px 0 rgba(0,0,0,0.25);
  min-width: 20px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  margin: 2px;
  font-weight: bold;
}
.bg-outline {
  color: #333;
  border: 1px solid #333;
  background-color: #fff;
}
.progress-meter {
  list-style: none;
  padding: 0;
  background-color: #ccc;
  background-image: -webkit-linear-gradient(to bottom, #ccc, #bebebe);
  background-image: -moz-linear-gradient(to bottom, #ccc, #bebebe);
  background-image: linear-gradient(to bottom, #ccc, #bebebe);
}
.progress-meter li {
  min-height: 10px;
  float: left;
  color: #fff;
  text-align: right;
  padding-right: 5px;
  text-shadow: 0 1px 0 rgba(0,0,0,0.3);
  line-height: 1.5;
  font-size: 11px;
}
.progress-meter li.completed {
  background-color: #666;
  background-image: -webkit-linear-gradient(to bottom, #666, #373737);
  background-image: -moz-linear-gradient(to bottom, #666, #373737);
  background-image: linear-gradient(to bottom, #666, #373737);
}
.progress-meter li.in-progress {
  background-color: #999;
  background-image: -webkit-linear-gradient(to bottom, #999, #6b6b6b);
  background-image: -moz-linear-gradient(to bottom, #999, #6b6b6b);
  background-image: linear-gradient(to bottom, #999, #6b6b6b);
}
.progress {
  height: 18px;
  overflow: hidden;
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-image: -moz-linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  border-radius: 3px;
  box-shadow: rgba(0,0,0,0.1) 0 1px 2px inset;
  margin-bottom: 20px;
}
.progress .bar {
  height: 18px;
}
.header a {
  color: #999;
}
.header nav ul {
  list-style: none;
  margin: 0 0 0 15px;
  padding: 0;
  border-right: 1px solid #ccc;
  float: left;
}
.header nav ul li {
  float: left;
}
.header nav ul li a {
  border-left: 1px solid #ccc;
  display: block;
  font-size: 13px;
  line-height: 48px;
  text-align: center;
  padding: 0 20px;
  height: 48px;
}
.header nav ul li.active a,
.header nav ul li:hover a {
  color: #333;
  background-color: #fafafa;
}
.sidenav nav ul {
  list-style: none;
  padding: 0;
  margin: -20px -20px 20px -20px;
}
.sidenav nav ul li a {
  display: block;
  padding: 10px 13px 10px 19px;
  color: #808080;
  line-height: 21px;
  text-shadow: 0 1px 0 #fff;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}
.sidenav nav ul li a:hover {
  color: #666;
}
.sidenav nav ul li.active a {
  background-color: #f2f2f2;
  box-shadow: inset 0 0 5px 1px #ebebeb;
  color: #666;
}
.header {
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #fff;
}
.header.header-medium {
  height: 42px;
}
.header.header-short {
  height: 30px;
}
.header .icon-logo-g {
  font-size: 24px;
  line-height: 48px;
  margin: 0 10px;
  color: #89b555;
  position: relative;
  top: 2px;
}
.header h1 {
  font-size: 26px;
  line-height: 50px;
  margin: 0;
  font-weight: normal;
  font-weight: 300;
}
.pagination {
  display: inline-block;
  list-style: none;
  padding: 0;
}
.pagination > li {
  float: left;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border: solid #ccc;
  border-width: 1px 0 1px 1px;
}
.pagination > li:last-of-type {
  border-width: 1px;
}
.pagination > li > a {
  display: block;
}
.pagination > li > a:hover {
  background-color: #f5f5f5;
}
.pagination > li.inactive {
  color: #999;
}
.pagination > li.active > a {
  background-color: #e5e5e5;
  color: #949494;
  cursor: default;
}
.pagination > li.arrow {
  font-size: 10px;
  cursor: default;
  color: #999;
}
.pagination > li.arrow a {
  color: #999;
}
.alert {
  border: 1px solid;
  padding: 10px;
  border-color: #777;
  background-color: #fff;
  color: #777;
  position: relative;
  margin-bottom: 20px;
  border-radius: 3px;
  clear: both;
}
.alert .message:before {
  font-family: Groupon;
  padding-right: 5px;
  content: "\e031";
}
.alert .request-id {
  padding-left: 18px;
}
.alert.error {
  color: #ed4e40;
  border: 1px solid #ed4e40;
}
.alert.error .message:before {
  content: "\e071";
}
.alert.success {
  color: #6da236;
  border-color: #6da236;
}
.alert.success .message:before {
  content: "\e050";
}
.alert.info {
  color: #bf8500;
  background-color: #fff8e8;
  border-color: #ffe4a5;
}
.alert > p:last-of-type {
  margin: 0;
}
.alert > ul:last-of-type {
  margin: 10px 0 0 0;
}
.alert .icon-close {
  opacity: 0.3;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #333;
  cursor: pointer;
}
.alert .icon-close:hover {
  opacity: 0.6;
}
.module {
  margin: 0 0 20px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fafafa;
}
.module-top {
  border-bottom: 1px solid #ccc;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: -moz-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: linear-gradient(to bottom, #f9f9f9, #ddd);
  border-radius: 3px 3px 0 0;
  padding: 11px 15px;
}
.module-header {
  font-size: 14px;
  line-height: 18px;
  color: #666;
  margin: 0;
  text-shadow: 0 1px 0 #fff;
}
.module-body {
  padding: 20px 15px 0 15px;
}
.tabs-container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.tabs-container .tabs {
  list-style: none;
  padding: 0;
  display: table;
  margin: 0 auto;
  position: relative;
  bottom: -1px;
}
.tabs-container .tabs.left-align {
  margin-left: 10px;
}
.tabs-container .tabs li {
  float: left;
  height: 35px;
}
.tabs-container .tabs li:last-of-type a {
  border-right: 1px solid #ccc;
}
.tabs-container .tabs a {
  text-align: center;
  height: 30px;
  display: block;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: -moz-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: linear-gradient(to bottom, #f9f9f9, #ddd);
  padding: 7px 15px;
  margin-top: 5px;
  white-space: nowrap;
  border: solid #ccc;
  border-width: 1px 0 1px 1px;
  color: #666;
  font-weight: bold;
  text-shadow: 0 -1px 0 #fff;
  font-size: 12px;
  line-height: 16px;
}
.tabs-container .tabs li.active,
.tabs-container .tabs li.ui-state-active {
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
  position: relative;
  bottom: 0;
}
.tabs-container .tabs li.active a,
.tabs-container .tabs li.ui-state-active a {
  background-image: none;
  background-color: #fff;
  border: none;
  padding: 7px 14px 7px 15px;
}
.tabs-container .tabs li.active + li a,
.tabs-container .tabs li.ui-state-active + li a {
  border-left: none;
}
.tabs-content > div,
.tab-buttons-content > div {
  display: none;
}
.tabs-content > div.active,
.tab-buttons-content > div.active {
  display: block;
}
.tab-buttons,
.tab-button-menu {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  border: 1px solid #ccc;
  height: 28px;
  border-radius: 3px;
  display: table;
  margin: 0 0 20px 0;
}
.tab-buttons > li,
.tab-button-menu > li {
  color: #666;
  float: left;
  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: -moz-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: linear-gradient(to bottom, #f9f9f9, #ddd);
}
.tab-buttons > li + li,
.tab-button-menu > li + li {
  border-left: 1px solid #ccc;
}
.tab-buttons > li.active,
.tab-button-menu > li.active {
  background: #f9f9f9;
}
.tab-buttons > li.active > a,
.tab-button-menu > li.active > a {
  box-shadow: #ececec 0 3px 3px 0 inset;
}
.tab-buttons > li.active > a:hover,
.tab-button-menu > li.active > a:hover {
  background-color: #f9f9f9;
  background-image: none;
}
.tab-buttons > li > a,
.tab-button-menu > li > a {
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  color: #666;
  white-space: nowrap;
  display: block;
  height: 28px;
  padding: 0 15px;
}
.tab-buttons > li > a:hover,
.tab-button-menu > li > a:hover {
  background-image: -webkit-linear-gradient(to bottom, #eee, #ccc);
  background-image: -moz-linear-gradient(to bottom, #eee, #ccc);
  background-image: linear-gradient(to bottom, #eee, #ccc);
}
.tab-buttons > li > a.active,
.tab-button-menu > li > a.active {
  background-color: #f9f9f9;
  background-image: none;
}
.tab-button-menu > li > a {
  width: 44px;
  height: 44px;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  padding: 0;
}
.has-menu {
  position: relative;
}
.modal-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 52px;
  background-color: #333;
  color: #999;
  z-index: 30;
  -webkit-filter: drop-shadow(0 0 3px rgba(0,0,0,0.5));
  -moz-filter: drop-shadow(0 0 3px rgba(0,0,0,0.5));
  display: none;
}
.modal-menu:after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51,51,51,0);
  border-bottom-color: #333;
  border-width: 6px;
  left: 22px;
  margin-left: -6px;
}
.modal-menu li + li {
  border-top: 1px solid #444;
}
.modal-menu a {
  color: #ccc;
  white-space: nowrap;
  text-align: left;
  text-shadow: none;
  font-weight: normal;
  padding: 0 28px 0 14px;
  line-height: 44px;
  display: block;
}
.modal-menu a:hover {
  background-color: #444;
}
.modal-menu a:before {
  padding-right: 14px;
  font-size: 16px;
  position: relative;
  top: 2px;
}
.modal-menu.is-visible {
  display: block;
}
.accordion {
  position: relative;
}
.accordion .module-top {
  border-bottom-width: 0;
  border-radius: 0 0 3px 3px;
}
.accordion-trigger:hover {
  cursor: pointer;
}
.accordion-trigger:before {
  font-family: "Groupon";
  content: "\e05d";
  float: left;
  margin-right: 5px;
  font-size: 20px;
  line-height: 18px;
  opacity: 0.8;
}
.accordion-content {
  display: none;
}
.accordion.open > .accordion-content {
  display: block;
}
.accordion.open > .accordion-trigger:before {
  content: "\e063";
}
.accordion.open > .module-top {
  border-bottom-width: 1px;
  border-radius: 0;
}
.modal {
  position: fixed;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  background-color: rgba(0,0,0,0.6);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
body.modal-open {
  overflow: hidden;
}
.modal-body {
  position: relative;
  width: 50%;
  margin: 100px auto 20px auto;
  padding: 10px 20px 0 20px;
  border: 3px solid #333;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.modal-body .row {
  min-width: 0;
}
.modal-body form {
  margin-bottom: 10px;
}
.modal-body table {
  margin: -10px 0 10px 0;
}
.modal-body thead {
  background-color: #fff;
  border-top-width: 0;
}
.modal-title {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  margin: -10px -20px 20px -20px;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.close-modal {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-shadow: 0 -1px 1px rgba(0,0,0,0.6);
}
.close-modal:hover {
  color: #fff;
}
.has-tooltip {
  position: relative;
  border: 1px dotted #f00;
  width: 100px;
  display: inline-block;
}
.tooltip {
  z-index: 100;
  position: absolute;
  background-color: #333;
  color: #fafafa;
  padding: 10px;
  border-radius: 3px;
  -webkit-filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
  filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
  display: none;
  width: 250px;
}
.tooltip:after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border: solid transparent;
  border-width: 6px;
}
[data-placement="top-left"]:after {
  margin-left: -6px;
  border-top-color: #333;
  top: 100%;
  left: 10%;
}
[data-placement="top"]:after {
  margin-left: -6px;
  border-top-color: #333;
  top: 100%;
  left: 50%;
}
[data-placement="top-right"]:after {
  margin-left: -6px;
  border-top-color: #333;
  top: 100%;
  left: 90%;
}
[data-placement="right-top"]:after {
  margin-top: -6px;
  border-right-color: #333;
  top: 75%;
  right: 100%;
}
[data-placement="right"]:after {
  margin-top: -6px;
  border-right-color: #333;
  top: 50%;
  right: 100%;
}
[data-placement="right-bottom"]:after {
  margin-top: -6px;
  border-right-color: #333;
  top: 25%;
  right: 100%;
}
[data-placement="bottom"]:after {
  margin-left: -6px;
  border-bottom-color: #333;
  bottom: 100%;
  left: 50%;
}
[data-placement="left"]:after {
  margin-top: -6px;
  border-left-color: #333;
  top: 50%;
  left: 100%;
}
.icon-button {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}
.icon-button:before {
  text-align: center;
  line-height: 28px;
  font-size: 18px;
}
.icon-button.btn-small {
  width: 24px;
  height: 24px;
}
.icon-button.btn-small:before {
  line-height: 22px;
  font-size: 16px;
}
.icon-button.btn-large {
  width: 34px;
  height: 34px;
}
.icon-button.btn-large:before {
  line-height: 32px;
  font-size: 20px;
}
.icon-text-button {
  margin: 0;
  padding: 5px 7px;
}
.icon-text-button:before {
  float: left;
  font-size: 18px;
  padding-right: 5px;
}
.icon-text-button.btn-small {
  padding: 2px 7px;
}
.icon-text-button.btn-small:before {
  font-size: 16px;
}
.icon-text-button.btn-large {
  padding: 7px;
}
.icon-text-button.btn-large:before {
  font-size: 20px;
}
.icon-inactive {
  opacity: 0.5;
}
.category-icon {
  display: block;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
}
.category-icon:before {
  position: relative;
}
.category-icon.icon-beauty-wellness-healthcare {
  background-color: #fb8d53;
  background-image: -webkit-linear-gradient(to bottom, #fb8d53, #e35205);
  background-image: -moz-linear-gradient(to bottom, #fb8d53, #e35205);
  background-image: linear-gradient(to bottom, #fb8d53, #e35205);
}
.category-icon.icon-beauty-wellness-healthcare:before {
  top: 1px;
}
.category-icon.icon-food-and-drink {
  background-color: #f23a5f;
  background-image: -webkit-linear-gradient(to bottom, #f23a5f, #ba0c2f);
  background-image: -moz-linear-gradient(to bottom, #f23a5f, #ba0c2f);
  background-image: linear-gradient(to bottom, #f23a5f, #ba0c2f);
}
.category-icon.icon-food-and-drink:before {
  top: 1px;
}
.category-icon.icon-leisure-offers-activities {
  background-color: #ffcd59;
  background-image: -webkit-linear-gradient(to bottom, #ffcd59, #f2a900);
  background-image: -moz-linear-gradient(to bottom, #ffcd59, #f2a900);
  background-image: linear-gradient(to bottom, #ffcd59, #f2a900);
}
.category-icon.icon-leisure-offers-activities:before {
  font-family: "Groupon";
  content: "\e012";
}
.category-icon.icon-services {
  background-color: #bad49c;
  background-image: -webkit-linear-gradient(to bottom, #bad49c, #89b555);
  background-image: -moz-linear-gradient(to bottom, #bad49c, #89b555);
  background-image: linear-gradient(to bottom, #bad49c, #89b555);
}
.category-icon.icon-services:before {
  top: 1px;
}
.category-icon.icon-shopping {
  background-color: #a1dfee;
  background-image: -webkit-linear-gradient(to bottom, #a1dfee, #4bc1de);
  background-image: -moz-linear-gradient(to bottom, #a1dfee, #4bc1de);
  background-image: linear-gradient(to bottom, #a1dfee, #4bc1de);
}
.category-icon.icon-shopping:before {
  top: 1px;
}
.category-icon.icon-grassroots {
  background-color: #f3b700;
  background-image: -webkit-linear-gradient(to bottom, #f3b700, #8d6a00);
  background-image: -moz-linear-gradient(to bottom, #f3b700, #8d6a00);
  background-image: linear-gradient(to bottom, #f3b700, #8d6a00);
}
.category-icon.icon-grassroots:before {
  font-family: "Groupon";
  content: "\e068";
}
.category-icon.icon-goods {
  background-color: #a9abad;
  background-image: -webkit-linear-gradient(to bottom, #a9abad, #75787b);
  background-image: -moz-linear-gradient(to bottom, #a9abad, #75787b);
  background-image: linear-gradient(to bottom, #a9abad, #75787b);
}
.category-icon.icon-goods:before {
  top: 2px;
}
.category-icon.icon-tickets {
  background-color: #857abb;
  background-image: -webkit-linear-gradient(to bottom, #857abb, #514689);
  background-image: -moz-linear-gradient(to bottom, #857abb, #514689);
  background-image: linear-gradient(to bottom, #857abb, #514689);
}
.category-icon.icon-tickets:before {
  top: 2px;
}
.category-icon.icon-getaways {
  background-color: #e272c4;
  background-image: -webkit-linear-gradient(to bottom, #e272c4, #c5299b);
  background-image: -moz-linear-gradient(to bottom, #e272c4, #c5299b);
  background-image: linear-gradient(to bottom, #e272c4, #c5299b);
}
.category-icon.icon-getaways:before {
  font-family: "Groupon";
  content: "\e03c";
  top: 2px;
}
.social {
  color: #fff;
  text-align: center;
  font-size: 24px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 3px;
  line-height: 30px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
}
.social.circular {
  border-radius: 15px;
}
.social:before {
  position: relative;
  top: 4px;
}
.social.icon-facebook {
  background-color: #3b5998;
}
.social.icon-twitter {
  background-color: #00a0d1;
}
.social.icon-google-plus {
  background-color: #db4a39;
}
.social.icon-pinterest {
  background-color: #c8232c;
}
.social.icon-linkedin {
  background-color: #0e76a8;
}
.social.icon-feed {
  background-color: #ee802f;
}
.social.icon-email {
  background-color: #89b555;
}
.bg-primary,
.bg-info,
.bg-success,
.bg-caution,
.bg-warning,
.bg-danger,
.bg-inverse,
.gradient-bg-primary,
.gradient-bg-info,
.gradient-bg-success,
.gradient-bg-caution,
.gradient-bg-warning,
.gradient-bg-danger,
.gradient-bg-inverse {
  color: #fff;
  text-shadow: 0 1px 0 rgba(0,0,0,0.25);
}
.bg-primary {
  background-color: #0076a8;
}
.bg-info {
  background-color: #4bc1de;
}
.bg-success {
  background-color: #89b555;
}
.bg-caution {
  background-color: #f2a900;
}
.bg-warning {
  background-color: #e35205;
}
.bg-danger {
  background-color: #ba0c2f;
}
.bg-inverse {
  background-color: #555;
}
.gradient-bg-primary {
  background-color: #00acf4;
  background-image: -webkit-linear-gradient(to bottom, #00acf4, #0076a8);
  background-image: -moz-linear-gradient(to bottom, #00acf4, #0076a8);
  background-image: linear-gradient(to bottom, #00acf4, #0076a8);
}
.gradient-bg-info {
  background-color: #8cd7ea;
  background-image: -webkit-linear-gradient(to bottom, #8cd7ea, #4bc1de);
  background-image: -moz-linear-gradient(to bottom, #8cd7ea, #4bc1de);
  background-image: linear-gradient(to bottom, #8cd7ea, #4bc1de);
}
.gradient-bg-success {
  background-color: #aecc8a;
  background-image: -webkit-linear-gradient(to bottom, #aecc8a, #89b555);
  background-image: -moz-linear-gradient(to bottom, #aecc8a, #89b555);
  background-image: linear-gradient(to bottom, #aecc8a, #89b555);
}
.gradient-bg-caution {
  background-color: #ffc540;
  background-image: -webkit-linear-gradient(to bottom, #ffc540, #f2a900);
  background-image: -moz-linear-gradient(to bottom, #ffc540, #f2a900);
  background-image: linear-gradient(to bottom, #ffc540, #f2a900);
}
.gradient-bg-warning {
  background-color: #fb7d3a;
  background-image: -webkit-linear-gradient(to bottom, #fb7d3a, #e35205);
  background-image: -moz-linear-gradient(to bottom, #fb7d3a, #e35205);
  background-image: linear-gradient(to bottom, #fb7d3a, #e35205);
}
.gradient-bg-danger {
  background-color: #f1224b;
  background-image: -webkit-linear-gradient(to bottom, #f1224b, #ba0c2f);
  background-image: -moz-linear-gradient(to bottom, #f1224b, #ba0c2f);
  background-image: linear-gradient(to bottom, #f1224b, #ba0c2f);
}
.gradient-bg-inverse {
  background-color: #7b7b7b;
  background-image: -webkit-linear-gradient(to bottom, #7b7b7b, #555);
  background-image: -moz-linear-gradient(to bottom, #7b7b7b, #555);
  background-image: linear-gradient(to bottom, #7b7b7b, #555);
}
.text-primary {
  color: #0076a8;
}
.text-info {
  color: #4bc1de;
}
.text-success {
  color: #89b555;
}
.text-caution {
  color: #f2a900;
}
.text-warning {
  color: #e35205;
}
.text-danger {
  color: #ba0c2f;
}
.text-inverse {
  color: #555;
}
header.header-green {
  border-top: 1px solid #a2c479;
  border-bottom: 1px solid #557232;
  background-color: #89b555;
  background-image: -webkit-linear-gradient(to bottom, #89b555, #6f9641);
  background-image: -moz-linear-gradient(to bottom, #89b555, #6f9641);
  background-image: linear-gradient(to bottom, #89b555, #6f9641);
}
header.header-green a {
  color: #fff;
}
header.header-green nav ul {
  box-shadow: 1px 0 0 #89b555;
  border-right: 1px solid #6f9641;
}
header.header-green nav ul li a {
  box-shadow: 1px 0 0 #89b555 inset;
  border-left: 1px solid #6f9641;
  text-shadow: 0 1px 0 rgba(0,0,0,0.8);
}
header.header-green nav ul li.active a,
header.header-green nav ul li:hover a {
  box-shadow: none;
  background-image: -webkit-linear-gradient(to bottom, #7ca849, #628439);
  background-image: -moz-linear-gradient(to bottom, #7ca849, #628439);
  background-image: linear-gradient(to bottom, #7ca849, #628439);
  color: #fff;
  background-color: #628439;
}
header.header-blue {
  border-top: 1px solid #009adb;
  border-bottom: 1px solid #002e42;
  background-color: #0076a8;
  background-image: -webkit-linear-gradient(to bottom, #0076a8, #005275);
  background-image: -moz-linear-gradient(to bottom, #0076a8, #005275);
  background-image: linear-gradient(to bottom, #0076a8, #005275);
}
header.header-blue a {
  color: #fff;
}
header.header-blue nav ul {
  box-shadow: 1px 0 0 #0076a8;
  border-right: 1px solid #005275;
}
header.header-blue nav ul li a {
  box-shadow: 1px 0 0 #0076a8 inset;
  border-left: 1px solid #005275;
  text-shadow: 0 1px 0 rgba(0,0,0,0.8);
}
header.header-blue nav ul li.active a,
header.header-blue nav ul li:hover a {
  box-shadow: none;
  background-image: -webkit-linear-gradient(to bottom, #00648e, #00405b);
  background-image: -moz-linear-gradient(to bottom, #00648e, #00405b);
  background-image: linear-gradient(to bottom, #00648e, #00405b);
  color: #fff;
  background-color: #00405b;
}
header.header-black {
  border-top: 1px solid #645b55;
  border-bottom: 1px solid #000;
  background-color: #2d2926;
  background-image: -webkit-linear-gradient(to bottom, #2d2926, #11100f);
  background-image: -moz-linear-gradient(to bottom, #2d2926, #11100f);
  background-image: linear-gradient(to bottom, #2d2926, #11100f);
}
header.header-black a {
  color: #fff;
}
header.header-black nav ul {
  box-shadow: 1px 0 0 #2d2926;
  border-right: 1px solid #11100f;
}
header.header-black nav ul li a {
  box-shadow: 1px 0 0 #2d2926 inset;
  border-left: 1px solid #11100f;
  text-shadow: 0 1px 0 rgba(0,0,0,0.8);
}
header.header-black nav ul li.active a,
header.header-black nav ul li:hover a {
  box-shadow: none;
  background-image: -webkit-linear-gradient(to bottom, #49423d, #2d2926);
  background-image: -moz-linear-gradient(to bottom, #49423d, #2d2926);
  background-image: linear-gradient(to bottom, #49423d, #2d2926);
  color: #fff;
  background-color: #49423d;
}
header.header-green h1,
header.header-green p,
header.header-green .icon-logo-g,
header.header-blue h1,
header.header-blue p,
header.header-blue .icon-logo-g,
header.header-black h1,
header.header-black p,
header.header-black .icon-logo-g {
  color: #fff;
  text-shadow: rgba(0,0,0,0.3) 0 1px 0;
}
.toolstrap .table-bordered,
.toolstrap .table-bordered-horizontal {
  border: 1px solid #ccc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-collapse: separate;
}
.toolstrap .table-bordered thead th:first-of-type,
.toolstrap .table-bordered-horizontal thead th:first-of-type {
  border-top-left-radius: 6px;
}
.toolstrap .table-bordered thead th:last-of-type,
.toolstrap .table-bordered-horizontal thead th:last-of-type {
  border-top-right-radius: 6px;
}
.toolstrap th,
.toolstrap td {
  padding: 10px 15px;
  color: #666;
  font-size: 12px;
  line-height: 20px;
}
.toolstrap th {
  text-shadow: 0 1px 0 #fff;
}
.toolstrap .table-dashboard th,
.toolstrap .table-dashboard-mini th {
  font-size: 12px;
  line-height: 12px;
  color: #999;
  padding: 6px 15px;
  text-shadow: none;
}
.toolstrap .table-dashboard td,
.toolstrap .table-dashboard-mini td {
  padding: 4px 28px;
  font-size: 18px;
  line-height: 18px;
}
.toolstrap .table-condensed caption {
  font-size: 12px;
}
.toolstrap .table-condensed th,
.toolstrap .table-condensed td {
  padding: 5px 10px 4px 10px;
  font-size: 12px;
  line-height: 18px;
}
.toolstrap thead {
  background-image: -webkit-linear-gradient(to bottom, #fafbfb, #eaebeb);
  background-image: -moz-linear-gradient(to bottom, #fafbfb, #eaebeb);
  background-image: linear-gradient(to bottom, #fafbfb, #eaebeb);
}
.toolstrap tbody tr:last-of-type td {
  border-bottom: 0;
}
.toolstrap .modal-body thead {
  background-image: none;
}
.toolstrap input[type="text"],
.toolstrap input[type="password"],
.toolstrap input[type="date"],
.toolstrap input[type="datetime"],
.toolstrap input[type="email"],
.toolstrap input[type="number"],
.toolstrap input[type="search"],
.toolstrap input[type="tel"],
.toolstrap input[type="time"],
.toolstrap input[type="url"],
.toolstrap select,
.toolstrap textarea {
  border-radius: 3px;
}
.toolstrap input[type="submit"],
.toolstrap input[type="button"],
.toolstrap input[type="reset"],
.toolstrap button,
.toolstrap .btn,
.toolstrap input[type="submit"].btn-primary,
.toolstrap input[type="button"].btn-primary,
.toolstrap .btn-primary,
.toolstrap input[type="submit"].btn-info,
.toolstrap input[type="button"].btn-info,
.toolstrap .btn-info,
.toolstrap input[type="submit"].btn-success,
.toolstrap input[type="button"].btn-success,
.toolstrap .btn-success,
.toolstrap input[type="submit"].btn-warning,
.toolstrap input[type="button"].btn-warning,
.toolstrap .btn-warning,
.toolstrap input[type="submit"].btn-caution,
.toolstrap input[type="button"].btn-caution,
.toolstrap .btn-caution,
.toolstrap input[type="submit"].btn-danger,
.toolstrap input[type="button"].btn-danger,
.toolstrap .btn-danger,
.toolstrap input[type="submit"].btn-inverse,
.toolstrap input[type="button"].btn-inverse,
.toolstrap .btn-inverse {
  border-radius: 4px;
}
.ui-datepicker {
  display: inline-block;
  background-color: #fff;
  border: 3px solid #333;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  left: -9999px;
}
.ui-datepicker table {
  margin: 0;
}
.ui-datepicker table th,
.ui-datepicker table td {
  border: 0;
  text-align: right;
}
.ui-datepicker table th {
  padding: 5px;
}
.ui-datepicker table td {
  min-width: 30px;
  padding: 0;
}
.ui-datepicker table td a {
  padding: 5px;
  display: block;
}
.ui-datepicker table td:hover {
  background-color: #e2f5fa;
}
.ui-datepicker table td.ui-datepicker-unselectable:hover {
  background-color: #fff;
}
.ui-datepicker-today {
  background-color: #f9f4dd;
}
.ui-datepicker-header {
  position: relative;
  background-color: #333;
  color: #f5f5f5;
}
.ui-datepicker-title {
  line-height: 2em;
  text-align: center;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.ui-datepicker-prev:before,
.ui-datepicker-next:before {
  font-family: "Groupon";
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  color: #ccc;
  font-size: 18px;
}
.ui-datepicker-prev {
  left: 5px;
}
.ui-datepicker-prev:before {
  content: "\e061";
  left: 0;
}
.ui-datepicker-next {
  right: 5px;
}
.ui-datepicker-next:before {
  content: "\e060";
  right: 0;
}
.ui-icon-circle-triangle-w,
.ui-icon-circle-triangle-e {
  display: none;
}
@-moz-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
html {
  background: #333;
}
body.extranet {
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  height: auto;
  overflow-y: scroll;
}
body.extranet .error,
body.extranet .parsley-error {
  border: solid 1px #cc2127;
}
body.extranet ul.parsley-error-list {
  display: none !important;
}
body.extranet a {
  color: #0185c6;
  outline: none;
  cursor: pointer;
}
body.extranet button {
  outline: none;
}
body.extranet label {
  font-size: 13px;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet label span.notes {
  font-size: 11px;
}
body.extranet textarea {
  resize: none;
}
body.extranet .btn-primary {
  padding: 6px 20px;
  color: #fff;
}
body.extranet .header-extranet {
  background: #333;
  height: 70px;
  padding: 10px 0;
  margin-bottom: 20px;
  border: 0;
}
body.extranet .header-extranet .icon-groupon {
  font-size: 42px;
  line-height: 52px;
  margin: 0 5px 0 0;
  color: #82b548;
  position: relative;
}
body.extranet .header-extranet h1 {
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  color: #82b548;
  margin-left: 5px;
  font-size: 25px;
  line-height: 24px;
  margin-top: 12px;
}
body.extranet .header-extranet h1 span {
  color: #fff;
  border-left: #666 solid 1px;
  padding-left: 10px;
  margin-left: 5px;
  line-height: 24px;
  display: inline-block;
}
body.extranet .header-extranet nav.login-info {
  margin-top: 20px;
}
body.extranet .header-extranet nav ul {
  border-right: 0;
}
body.extranet .header-extranet nav ul li {
  position: relative;
}
body.extranet .header-extranet nav ul li a {
  line-height: 14px;
  height: 14px;
  background-color: transparent;
  color: #aaa;
  border: 0;
  padding: 0 10px;
  border-left: #ddd solid 1px;
}
body.extranet .header-extranet nav ul li a span.icon-question,
body.extranet .header-extranet nav ul li a span.icon-gear {
  font-size: 16px;
}
body.extranet .header-extranet nav ul li a.no-bdr {
  border-left: 0;
}
body.extranet .header-extranet nav ul li .settings-options {
  background: #fff;
  padding: 20px;
  width: 290px;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.4);
  position: absolute;
  top: 40px;
  right: -26px;
  z-index: 10;
}
body.extranet .header-extranet nav ul li .settings-options:after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51,51,51,0);
  border-bottom-color: #fff;
  border-width: 14px;
  right: 30px;
  margin-left: -14px;
}
body.extranet .header-extranet nav ul li .settings-options a.close-settings {
  position: absolute;
  top: 10px;
  right: 0;
  display: inline-block;
  border: 0;
}
body.extranet .header-extranet nav ul li .settings-options h3 {
  font-size: 14px;
  margin-bottom: 10px;
}
body.extranet .header-extranet nav ul li .settings-options p {
  margin-bottom: 10px;
}
body.extranet .header-extranet nav ul li .settings-options label {
  margin-bottom: 10px;
}
body.extranet .header-extranet nav ul li:last-child a {
  padding-right: 0;
}
body.extranet .header-extranet nav ul li:first-child a {
  border: 0;
  color: #aaa;
  font-weight: bold;
  border-left: 0;
}
body.extranet .header-extranet nav ul li:first-child a span {
  color: #aaa;
  margin-left: 5px;
}
body.extranet .header-extranet nav ul li.active a,
body.extranet .header-extranet nav ul li:hover a {
  color: #aaa;
  background-color: transparent;
}
body.extranet .header-extranet .search {
  float: left;
  margin-left: 40px;
  height: 30px;
  margin-top: 10px;
  width: 320px;
}
body.extranet .header-extranet .search input[type="search"] {
  background-color: #fff;
  background-position: 5px 50%;
  padding-left: 25px;
  width: 200px;
  float: left;
}
body.extranet .header-extranet .search .icon-button {
  color: #999;
  margin-right: 10px;
  float: left;
}
body.extranet .header-extranet .search .advanced-link {
  font-size: 13px;
  float: left;
  line-height: 30px;
  color: #aaa;
}
body.extranet .header-extranet .search .advanced-link:hover {
  color: #aaa;
}
body.extranet section.main {
  min-height: 500px;
}
body.extranet footer {
  background: #333;
  padding: 40px 0;
  margin-top: 30px;
}
body.extranet footer nav ul {
  border-right: 0;
  margin: 0;
  padding: 0;
}
body.extranet footer nav ul li {
  float: left;
  list-style: none;
}
body.extranet footer nav ul li a {
  line-height: 14px;
  height: 14px;
  background-color: transparent;
  color: #aaa;
  border: 0;
  padding: 0 10px;
  border-left: #ddd solid 1px;
  display: inline-block;
}
body.extranet footer nav ul li a.active a,
body.extranet footer nav ul li a:hover a {
  color: #aaa;
  background-color: transparent;
}
body.extranet footer nav ul li:first-child a {
  border: 0;
}
body.extranet .page-header {
  border-bottom: #ddd solid 2px;
  margin-bottom: 10px;
}
body.extranet .page-header h2 {
  display: inline-block;
  font-size: 26px;
  font-weight: normal;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  width: auto;
  max-width: 400px;
  margin: 0;
  position: relative;
}
body.extranet .page-header h2 a {
  display: block;
  color: #333;
  padding: 0 10px;
  background-color: #f2f2f2;
  border-bottom: #ddd solid 1px;
}
body.extranet .page-header h2 a span.hotel-name {
  width: 360px;
  display: inline-block;
}
body.extranet .page-header h2 a span.icon-arrow-down-large {
  float: right;
  font-size: 13px;
  color: #aaa;
}
body.extranet .page-header h2 a#hotel-selector {
  border: 1px solid #ddd;
  border-bottom: none;
}
body.extranet .page-header h2 ul {
  list-style: none;
  background-color: #f2f2f2;
  margin: 0;
  padding: 0;
  width: 400px;
  position: absolute;
  z-index: 2;
}
body.extranet .page-header h2 ul#hotel_options {
  max-height: 300px;
  overflow: auto;
  font-size: 16px;
  border: 1px solid #ddd;
}
body.extranet .page-header nav {
  float: right;
  display: inline-block;
}
body.extranet .page-header nav ul {
  float: right;
  list-style: none;
  margin: 6px 0 0 0;
}
body.extranet .page-header nav ul li {
  float: left;
  list-style: none;
  margin: 0 10px;
}
body.extranet .page-header nav ul li:last-child {
  margin-right: 0;
}
body.extranet .page-header nav ul li a {
  color: #333;
  font-size: 15px;
  line-height: 32px;
  padding-bottom: 5px;
}
body.extranet .page-header nav ul li.active a {
  border-bottom: #82b548 solid 4px;
}
body.extranet .page-header .today {
  font-size: 26px;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  float: right;
  line-height: 40px;
}
body.extranet .page-header .today span {
  color: #999;
  font-size: 13px;
}
body.extranet .sidenav {
  padding-right: 30px;
}
body.extranet .sidenav nav ul {
  margin: 0;
}
body.extranet .sidenav nav ul li a {
  display: block;
  padding: 10px;
  line-height: 1;
  font-size: 13px;
}
body.extranet .sidenav nav ul li a span {
  float: right;
  display: none;
  color: #aaa;
}
body.extranet .sidenav nav ul li.active a {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}
body.extranet .sidenav nav ul li.active a span {
  display: inline-block;
}
body.extranet h2.page-title {
  font-size: 18px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
}
body.extranet .section-info .section-title {
  display: inline-block;
  margin: 0 10px 20px 0;
  font-size: 22px;
  line-height: 28px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
}
body.extranet .section-info .section-title.mb0 {
  margin-bottom: 0;
}
body.extranet .section-info .section-title.sub-title {
  clear: both;
  width: 100%;
}
body.extranet .section-info .section-title span {
  display: block;
  color: #999;
  font-size: 13px;
}
body.extranet .section-info .mode-link {
  list-style: none;
  display: inline;
}
body.extranet .section-info .mode-link li {
  display: inline-block;
  margin-right: 5px;
}
body.extranet .section-info .mode-link li a {
  font-size: 13px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 4px 20px;
  border-radius: 18px;
}
body.extranet .section-info .mode-link li.active a {
  background: #888;
  border-color: #888;
  color: #333;
}
body.extranet select {
  position: relative;
  border-radius: 0;
  padding: 4px 8px;
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  margin: 0 0 10px;
  text-indent: 0.01px;
  height: 30px;
  color: #666;
}
body.extranet select:focus,
body.extranet select:hover,
body.extranet select:active {
  background: #fdfdfd;
  background: linear-gradient(#fffefe, #f4f2f3);
}
body.extranet select:focus {
  outline: 0;
  box-shadow: none;
}
body.extranet select:hover {
  cursor: pointer;
}
body.extranet select:active {
  box-shadow: inset 1px 1px 5px 1px rgba(0,0,0,0.05);
}
body.extranet select option {
  padding: 0 8px;
}
body.extranet .btn-section {
  margin: 20px 0;
  clear: both;
}
body.extranet .btn-section button {
  margin-right: 5px;
}
body.extranet .modal {
  background-color: #333;
  background-color: rgba(0,0,0,0.2);
  z-index: 10;
}
body.extranet .modal-body {
  position: relative;
  width: 700px;
  min-height: 300px;
  margin: 150px auto 20px auto;
  padding: 40px;
  border: 1px solid #e7e7e8;
  border-radius: 0;
  background-color: #fff;
  box-shadow: none;
}
body.extranet .modal-body.alert-modal {
  min-height: 180px;
  width: 600px;
}
body.extranet .modal-body h5 {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}
body.extranet .modal-body hr {
  border-width: 0 0 2px 0;
  border-color: #e5e4e5;
}
body.extranet .modal-title {
  background-color: #fff;
  color: #333;
  font-size: 24px;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  padding: 0;
  margin: 0 0 30px 0;
  font-weight: normal;
}
body.extranet .modal-subtitle {
  background-color: #fff;
  color: #333;
  font-size: 16px;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  padding: 0;
  margin: 0 0 30px 0;
  font-weight: normal;
}
body.extranet .close-modal {
  color: #888;
  font-size: 30px;
  top: 15px;
  right: 15px;
}
body.extranet .room-type-modal textarea {
  resize: none;
  height: 100px;
}
body.extranet .occupancy,
body.extranet .radio-buttons {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 5px;
}
body.extranet .occupancy li,
body.extranet .radio-buttons li {
  display: inline-block;
  margin-right: 5px;
}
body.extranet .occupancy li a,
body.extranet .radio-buttons li a {
  font-size: 13px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 4px 15px;
  border-radius: 18px;
}
body.extranet .occupancy li.active a,
body.extranet .radio-buttons li.active a {
  background: #888;
  border-color: #888;
  color: #333;
}
body.extranet .table-style table {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
}
body.extranet .table-style table th,
body.extranet .table-style table td {
  vertical-align: top;
}
body.extranet .table-style table th span.sorting,
body.extranet .table-style table td span.sorting {
  color: #888;
}
body.extranet .table-style table thead {
  background: none;
  border-bottom: 1px solid #ccc;
  border-top: 0;
}
body.extranet .table-style table thead th {
  color: #888;
  font-weight: normal;
  white-space: nowrap;
  padding: 0 20px 10px 0;
}
body.extranet .table-style table tbody tr td {
  border: 0;
  padding: 0 20px 20px 0;
}
body.extranet .table-style table tbody tr td.center {
  text-align: center;
}
body.extranet .table-style table tbody tr td .green-text {
  color: #82b548;
  font-weight: bold;
}
body.extranet .table-style table tbody tr td a.icon-close,
body.extranet .table-style table tbody tr td a.icon-check-circle {
  color: #999;
}
body.extranet .table-style table tbody tr td span.icon-check-circle {
  color: #82b548;
}
body.extranet .table-style table tbody tr td label {
  margin: 0;
}
body.extranet .table-style table tbody tr:first-child td {
  padding-top: 20px;
}
body.extranet .btn-download,
body.extranet .btn-custom-select {
  color: #333;
  margin-left: 5px;
  margin-bottom: 0;
}
body.extranet .btn-download span.icon-arrow-pointer-up,
body.extranet .btn-custom-select span.icon-arrow-pointer-up {
  width: 15px;
  height: 12px;
  position: relative;
  display: inline-block;
}
body.extranet .btn-download span.icon-arrow-pointer-up:before,
body.extranet .btn-custom-select span.icon-arrow-pointer-up:before {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
body.extranet .btn-download span.icon-arrow-down-large,
body.extranet .btn-custom-select span.icon-arrow-down-large {
  color: #0075ab;
  margin-left: 5px;
}
body.extranet .filter-section {
  margin: 0 0 45px 0;
  font-size: 14px;
  padding: 7px 10px;
  background-color: #f2f2f2;
}
body.extranet .filter-section a.show-all {
  margin-top: 0;
  display: inline-block;
}
body.extranet .filter-section a.show-all span {
  color: #aaa;
  margin-left: 5px;
}
body.extranet .filter-section select {
  margin-bottom: 0;
}
body.extranet .date-range {
  margin-left: 10px;
}
body.extranet .date-range form {
  margin-bottom: 0;
}
body.extranet .date-range .date-field {
  border: 1px solid #ccc;
  display: inline-block;
  margin-right: 10px;
  height: 32px;
  padding: 0 8px;
  background-color: #fff;
}
body.extranet .date-range .date-field span.icon-calendar {
  font-size: 12px;
  position: relative;
  top: 0;
  cursor: pointer;
}
body.extranet .date-range .date-field input {
  border: 0;
  box-shadow: none;
  width: 120px;
  margin-bottom: 0;
  font-size: 13px;
}
body.extranet .date-range .columns {
  padding-left: 0;
}
body.extranet .date-range .filter-form {
  background: #fcfcfc;
  border-top: #ddd solid 2px;
  padding: 10px 10px 0 10px;
  clear: both;
}
body.extranet .date-range .filter-form h4 {
  margin-bottom: 20px;
}
body.extranet .date-range .filter-form .icon-calendar {
  cursor: pointer;
  font-size: 24px;
  position: relative;
  top: 5px;
}
body.extranet ul.list-btn {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 5px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet ul.list-btn li {
  display: inline-block;
  margin-right: 5px;
}
body.extranet ul.list-btn li:last-child {
  margin-right: 0;
}
body.extranet ul.list-btn li a {
  font-size: 13px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 4px 14px;
  border-radius: 18px;
}
body.extranet ul.list-btn li.active a {
  background: #888;
  border-color: #888;
  color: #fff;
}
body.extranet ul.list-btn.availability-btn li a {
  padding: 1px 6px;
}
body.extranet .bread-crumb {
  margin-bottom: 30px;
  font-size: 13px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .bread-crumb a span {
  font-size: 10px;
}
body.extranet .pagination-results {
  float: left;
  line-height: 30px;
  margin-right: 10px;
}
body.extranet .pagination li {
  border: 0;
}
body.extranet .pagination li a {
  color: #333;
}
body.extranet .pagination li.left-navigation,
body.extranet .pagination li.right-navigation {
  cursor: pointer;
}
body.extranet .pagination li.arrow {
  font-size: 15px;
}
body.extranet .pagination li.arrow a {
  color: #999;
}
body.extranet ul.ui-autocomplete {
  background: #fff;
  width: 166px;
  border: #ccc solid 1px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  position: absolute !important;
}
body.extranet ul.ui-autocomplete li {
  list-style: none;
}
body.extranet ul.ui-autocomplete li a {
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  color: #333;
  display: block;
}
body.extranet ul.ui-autocomplete li a:hover {
  background: #f2f2f2;
}
body.extranet .ui-helper-hidden-accessible {
  display: none;
}
body.extranet #no-results {
  display: block;
}
body.extranet .recent-auto-updates {
  margin: 10px 0;
}
body #no_result {
  text-align: center;
  padding: 10px 10px 20px 10px;
  border-bottom: 1px solid #ccc;
  color: #888;
}
body .page-loading {
  display: none;
  position: fixed;
  z-index: 10000;
  width: 100%;
  text-align: center;
  padding: 5px;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../../public/getaways-xnet/assets/common-g-spinner.gif");
  background-color: rgba(0,0,0,0.5);
  background-position: center 300px;
  background-repeat: no-repeat;
}
body .content-loading {
  width: 100%;
  text-align: center;
  padding: 5px;
  min-height: 500px;
  background-image: url("../../public/getaways-xnet/assets/common-g-spinner.gif");
  background-position: center center;
  background-repeat: no-repeat;
}
body .ui-tooltip,
body .arrow:after {
  position: absolute;
  background: #fff;
  border: 2px solid #fff;
}
body .ui-tooltip {
  padding: 5px;
  color: #000;
  border-radius: 2px;
  box-shadow: 0 0 2px #666;
  width: auto;
  max-width: 250px;
}
body .tooltip-link {
  font-weight: bold;
  color: #08e;
  cursor: pointer;
}
body .arrow {
  width: 70px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -16px;
}
body .arrow.top {
  top: -16px;
  bottom: auto;
}
body .arrow.left {
  left: 20%;
}
body .arrow:after {
  content: "";
  position: absolute;
  left: 20px;
  top: -15px;
  width: 20px;
  height: 20px;
  box-shadow: 3px 3px 3px -3px #666;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  tranform: rotate(45deg);
}
body .arrow.top:after {
  bottom: -20px;
  top: auto;
}
body .terms strong,
body .faq strong,
body .privacy strong {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body .terms .page-header,
body .faq .page-header,
body .privacy .page-header {
  margin-bottom: 20px;
}
body .terms .page-header h2,
body .faq .page-header h2,
body .privacy .page-header h2 {
  width: auto;
}
body .terms h3,
body .faq h3,
body .privacy h3 {
  font-size: 16px;
  color: #82b548;
  margin-bottom: 10px;
}
body .terms h4,
body .faq h4,
body .privacy h4 {
  font-size: 14px;
}
body .percentage-field {
  padding: 0 5px;
  border: 1px solid #ccc;
  margin: 5px;
  width: 90px;
  text-align: left;
  background: #fff;
  float: left;
}
body .percentage-field.disabled {
  background-color: #e6e6e6;
}
body .percentage-field input {
  border: 0;
  width: 55px;
  margin: 0;
  padding: 0 0 0 5px;
  text-align: right;
  height: 28px;
  box-shadow: none;
}
body .percentage-field input {
  text-align: right;
  padding-right: 5px;
}
body .pr0 {
  padding-right: 0;
}
body .help-page-content {
  margin: 0 auto;
  width: 680px;
}
body .help-page-content ul {
  list-style: none;
  width: 100%;
  float: left;
  margin: 40px 0;
  border-bottom: #ccc solid 1px;
  padding-bottom: 40px;
}
body .help-page-content ul li {
  width: 50%;
  text-align: center;
  float: left;
}
body .help-page-content ul li:first-child {
  border-right: #ccc solid 1px;
}
body .help-page-content ul li a {
  font-size: 16px;
  font-weight: 600;
}
body .help-page-content ul li span {
  color: #ccc;
  font-size: 60px;
  margin-bottom: 20px;
  display: inline-block;
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/**
* CSS themes for simplePagination.js
* Author: Flavius Matis - http://flaviusmatis.github.com/
* URL: https://github.com/flaviusmatis/simplePagination.js
*/

ul.simple-pagination {
	list-style: none;
}

.simple-pagination {
	display: block;
	overflow: hidden;
	padding: 0 5px 5px 0;
	margin: 0;
}

.simple-pagination ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.simple-pagination li {
	list-style: none;
	padding: 0;
	margin: 0;
	float: left;
}
span.ellipse.clickable {
	cursor: pointer;
}

.ellipse input {
	width: 3em;
}

/*------------------------------------*\
	Compact Theme Styles
\*------------------------------------*/

.compact-theme a, .compact-theme span {
	float: left;
	color: #333;
	font-size:14px;
	line-height:24px;
	font-weight: normal;
	text-align: center;
	border: 1px solid #AAA;
	border-left: none;
	min-width: 14px;
	padding: 0 7px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
	background: #efefef; /* Old browsers */
	background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#efefef)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #ffffff 0%,#efefef 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #ffffff 0%,#efefef 100%); /* Opera11.10+ */
	background: -ms-linear-gradient(top, #ffffff 0%,#efefef 100%); /* IE10+ */
	background: linear-gradient(top, #ffffff 0%,#efefef 100%); /* W3C */
}

.compact-theme a:hover {
	text-decoration: none;
	background: #efefef; /* Old browsers */
	background: -moz-linear-gradient(top, #efefef 0%, #bbbbbb 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#efefef), color-stop(100%,#bbbbbb)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #efefef 0%,#bbbbbb 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #efefef 0%,#bbbbbb 100%); /* Opera11.10+ */
	background: -ms-linear-gradient(top, #efefef 0%,#bbbbbb 100%); /* IE10+ */
	background: linear-gradient(top, #efefef 0%,#bbbbbb 100%); /* W3C */
}

.compact-theme li:first-child a, .compact-theme li:first-child span {
	border-left: 1px solid #AAA;
	border-radius: 3px 0 0 3px;
}

.compact-theme li:last-child a, .compact-theme li:last-child span {
	border-radius: 0 3px 3px 0;
}

.compact-theme .current {
	background: #bbbbbb; /* Old browsers */
	background: -moz-linear-gradient(top, #bbbbbb 0%, #efefef 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#bbbbbb), color-stop(100%,#efefef)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #bbbbbb 0%,#efefef 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #bbbbbb 0%,#efefef 100%); /* Opera11.10+ */
	background: -ms-linear-gradient(top, #bbbbbb 0%,#efefef 100%); /* IE10+ */
	background: linear-gradient(top, #bbbbbb 0%,#efefef 100%); /* W3C */
	cursor: default;
}

.compact-theme .ellipse {
	background: #EAEAEA;
	padding: 0 10px;
	cursor: default;
}

/*------------------------------------*\
	Light Theme Styles
\*------------------------------------*/

.light-theme a, .light-theme span {
	float: left;
	color: #666;
	font-size:14px;
	line-height:24px;
	font-weight: normal;
	text-align: center;
	border: 1px solid #BBB;
	min-width: 14px;
	padding: 0 7px;
	margin: 0 5px 0 0;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	background: #efefef; /* Old browsers */
	background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#efefef)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #ffffff 0%,#efefef 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #ffffff 0%,#efefef 100%); /* Opera11.10+ */
	background: -ms-linear-gradient(top, #ffffff 0%,#efefef 100%); /* IE10+ */
	background: linear-gradient(top, #ffffff 0%,#efefef 100%); /* W3C */
}

.light-theme a:hover {
	text-decoration: none;
	background: #FCFCFC;
}

.light-theme .current {
	background: #666;
	color: #FFF;
	border-color: #444;
	box-shadow: 0 1px 0 rgba(255,255,255,1), 0 0 2px rgba(0, 0, 0, 0.3) inset;
	cursor: default;
}

.light-theme .ellipse {
	background: none;
	border: none;
	border-radius: 0;
	box-shadow: none;
	font-weight: bold;
	cursor: default;
}

/*------------------------------------*\
	Dark Theme Styles
\*------------------------------------*/

.dark-theme a, .dark-theme span {
	float: left;
	color: #CCC;
	font-size:14px;
	line-height:24px;
	font-weight: normal;
	text-align: center;
	border: 1px solid #222;
	min-width: 14px;
	padding: 0 7px;
	margin: 0 5px 0 0;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	background: #555; /* Old browsers */
	background: -moz-linear-gradient(top, #555 0%, #333 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#555), color-stop(100%,#333)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #555 0%,#333 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #555 0%,#333 100%); /* Opera11.10+ */
	background: -ms-linear-gradient(top, #555 0%,#333 100%); /* IE10+ */
	background: linear-gradient(top, #555 0%,#333 100%); /* W3C */
}

.dark-theme a:hover {
	text-decoration: none;
	background: #444;
}

.dark-theme .current {
	background: #222;
	color: #FFF;
	border-color: #000;
	box-shadow: 0 1px 0 rgba(255,255,255,0.2), 0 0 1px 1px rgba(0, 0, 0, 0.1) inset;
	cursor: default;
}

.dark-theme .ellipse {
	background: none;
	border: none;
	border-radius: 0;
	box-shadow: none;
	font-weight: bold;
	cursor: default;
}

.select2-container--default {
  max-width: 100%;
}
.select2-container--default .select2-selection--single {
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #666;
  line-height: 30px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}
ul.simple-pagination {
  display: inline-block;
}
ul.simple-pagination.light-theme a,
ul.simple-pagination.light-theme span {
  box-shadow: none;
  background: none;
}
ul.simple-pagination.light-theme .current {
  background: #666;
  border-color: #666;
  box-shadow: none;
}
body.extranet.read-only.profile section#basicinfo_content,
body.extranet.read-only.profile section#description_content,
body.extranet.read-only.profile section#contacts_content,
body.extranet.read-only.profile .hotel-status,
body.extranet.read-only.profile #inventory-rate-plan .rate-plan {
  pointer-events: none;
}
body.extranet.read-only.profile section#basicinfo_content .btn-section,
body.extranet.read-only.profile section#description_content .btn-section,
body.extranet.read-only.profile section#contacts_content .btn-section,
body.extranet.read-only.profile .hotel-status .btn-section,
body.extranet.read-only.profile #inventory-rate-plan .rate-plan .btn-section,
body.extranet.read-only.profile section#basicinfo_content button,
body.extranet.read-only.profile section#description_content button,
body.extranet.read-only.profile section#contacts_content button,
body.extranet.read-only.profile .hotel-status button,
body.extranet.read-only.profile #inventory-rate-plan .rate-plan button,
body.extranet.read-only.profile section#basicinfo_content .btn,
body.extranet.read-only.profile section#description_content .btn,
body.extranet.read-only.profile section#contacts_content .btn,
body.extranet.read-only.profile .hotel-status .btn,
body.extranet.read-only.profile #inventory-rate-plan .rate-plan .btn {
  display: none !important;
}
body.extranet.read-only.profile section#roominfo_content .room-type-modal {
  pointer-events: none;
}
body.extranet.read-only.profile section#roominfo_content #add-room,
body.extranet.read-only.profile section#roominfo_content .btn,
body.extranet.read-only.profile section#roominfo_content .btn-section,
body.extranet.read-only.profile section#roominfo_content button {
  display: none !important;
}
body.extranet.read-only.profile section#taxes_content table#hotel-taxes,
body.extranet.read-only.profile section#taxes_content .select-taxes-table {
  pointer-events: none;
}
body.extranet.read-only.profile section#taxes_content table#hotel-taxes input[type="checkbox"],
body.extranet.read-only.profile section#taxes_content .select-taxes-table input[type="checkbox"] {
  display: none !important;
}
body.extranet.read-only.profile section#taxes_content .btn-section,
body.extranet.read-only.profile section#taxes_content .icon-close {
  display: none !important;
}
body.extranet.read-only.inventory #week-view-inventory tr.room-row,
body.extranet.read-only.inventory #week-view-inventory td.rate-cell {
  pointer-events: none;
}
body.extranet.read-only.products .deal-manager .inventory-section-details,
body.extranet.read-only.products .owners-table,
body.extranet.read-only.products .create-rate-plan {
  pointer-events: none;
}
body.extranet.read-only.products .owners-table a.icon-close,
body.extranet.read-only.products .owners-table a.icon-plus {
  display: none;
}
body.extranet.read-only.administration #taxes_content #add-tax {
  display: none;
}
body.extranet.read-only.administration #tax-information .tax-details {
  pointer-events: none;
}
body.extranet.read-only.administration #tax-information .btn-section,
body.extranet.read-only.administration #tax-information button,
body.extranet.read-only.administration #tax-information .btn {
  display: none !important;
}
body.extranet.read-only .header-extranet span.readonly {
  color: #fff;
  font-weight: bold;
  padding-left: 15px;
  font-size: 13px;
  border-left: 0px;
}
.fc {
  direction: ltr;
  text-align: left;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
}
.fc .fc-header-space {
  padding-left: 10px;
}
.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  font-size: 300%;
  line-height: 50%;
}
.fc .fc-week-number {
  width: 22px;
  text-align: center;
}
.fc .fc-week-number div {
  padding: 0 2px;
}
html .fc,
.fc table {
  font-size: 1em;
}
.fc td,
.fc th {
  padding: 0;
  vertical-align: top;
}
.fc-header {
  margin-bottom: 0;
}
.fc-header td {
  white-space: nowrap;
  border: 0;
}
.fc-header .fc-button {
  margin-bottom: 0;
  vertical-align: top;
  margin-right: -1px;
}
.fc-header .fc-state-down {
  z-index: 3;
}
.fc-header tbody tr:last-of-type td {
  border: 0;
}
.fc-header-left {
  width: 25%;
  text-align: left;
}
.fc-header-center {
  text-align: center;
}
.fc-header-right {
  width: 25%;
  text-align: right;
}
.fc-header-title {
  display: block;
  vertical-align: top;
  text-align: center;
}
.fc-header-title h2 {
  margin-top: 15px;
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 18px;
  line-height: 18px;
}
.fc-header .fc-corner-right,
.fc-header .ui-corner-right {
  margin-right: 0;
}
.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
  z-index: 2;
}
.fc-header .fc-state-active,
.fc-header .ui-state-active {
  z-index: 4;
}
.fc-content {
  clear: both;
  zoom: 1;
}
.fc-view {
  width: 100%;
  overflow: hidden;
}
.fc-widget-header,
.fc-widget-content {
  border: 1px solid #ccc;
}
.fc-grid .inventory-info {
  font-size: 13px;
  color: #888;
  position: relative;
  display: block;
}
.fc-grid .inventory-info .price {
  color: #888;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 8px;
  min-height: 13px;
}
.fc-grid .inventory-info .inventory-count {
  color: #333;
  font-size: 13px;
  line-height: 13px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
.fc-grid .inventory-info .month {
  font-size: 15px;
  line-height: 15px;
  color: #71ac43;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: -30px;
}
.fc-grid .selected {
  background: #82b548;
  color: #fff;
}
.fc-grid .selected .fc-day-number,
.fc-grid .selected .price,
.fc-grid .selected .inventory-info .month {
  color: #fff;
}
.fc-grid .black-out,
.fc-grid .fc-other-month.black-out {
  background: #555;
  color: #fff;
}
.fc-grid .sold-out,
.fc-grid .fc-other-month.sold-out {
  background: #f9e8e3;
}
.fc-grid .sold-out .fc-day-number,
.fc-grid .fc-other-month.sold-out .fc-day-number,
.fc-grid .sold-out .price,
.fc-grid .fc-other-month.sold-out .price {
  color: #888;
}
.fc-grid .sold-out .inventory-count,
.fc-grid .fc-other-month.sold-out .inventory-count {
  color: #333;
}
.fc-grid .sold-out .inventory-count.count-zero,
.fc-grid .fc-other-month.sold-out .inventory-count.count-zero {
  color: #ce2127;
}
.fc-grid th {
  text-align: center;
}
.fc-grid .fc-day-number {
  text-align: right;
  padding: 0 2px;
  font-size: 15px;
  line-height: 15px;
  color: #ccc;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 15px;
}
.fc-grid .fc-other-month {
  background: #f2f2f2;
}
.fc-grid .fc-other-month .fc-day-number {
  color: #ccc;
}
.fc-grid .fc-other-month.last-day {
  border-right: #999 solid 3px;
}
.fc-grid .fc-other-month.first-day {
  border-left: #999 solid 3px;
}
.fc-grid .fc-other-month.selected {
  background: #82b548;
  color: #fff;
}
.fc-grid .fc-other-month.selected.black-out {
  background: #333;
}
.fc-grid .fc-other-month.selected .fc-day-number,
.fc-grid .fc-other-month.selected .price,
.fc-grid .fc-other-month.selected .inventory-info .month {
  color: #fff;
}
.fc-grid .selected {
  opacity: 0.5;
}
.fc-grid .selected.highlight {
  opacity: 1;
}
.fc-grid .fc-past {
  opacity: 0.5;
}
.fc-grid .fc-day {
  cursor: pointer;
}
.fc-grid .fc-day-content {
  clear: both;
  padding: 2px 2px 1px;
  display: none;
}
.fc-grid .fc-event-time {
  font-weight: bold;
}
.fc-cell-overlay {
  background: #92c359;
  opacity: 0.5;
}
.fc-button {
  position: relative;
  display: inline-block;
  padding: 0 0.6em;
  overflow: hidden;
  height: 16px;
  line-height: 16px;
  white-space: nowrap;
  cursor: pointer;
}
.fc-button .fc-icon-wrap {
  position: relative;
  float: left;
  top: 50%;
}
.fc-button .ui-icon {
  position: relative;
  float: left;
  margin-top: -50%;
  *margin-top: 0;
  *top: -50%;
}
.fc-state-default {
  border: 0;
}
.fc-state-default.fc-corner-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-state-default.fc-corner-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-text-arrow {
  margin: 0 0.1em;
  font-size: 18px;
  line-height: 18px;
  color: #999;
  vertical-align: baseline;
}
.fc-text-arrow:hover {
  color: #666;
}
.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow {
  font-weight: bold;
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333;
  background-color: none;
}
.fc-state-hover {
  color: #333;
  text-decoration: none;
}
.fc-state-down,
.fc-state-active {
  background-color: none;
  background-image: none;
}
.fc-state-disabled {
  cursor: default;
  background-image: none;
  box-shadow: none;
}
.fc-event-container > * {
  z-index: 8;
}
.fc-event-container > .ui-draggable-dragging,
.fc-event-container > .ui-resizable-resizing {
  z-index: 9;
}
.fc-event {
  border: 1px solid #3a87ad;
  background-color: #3a87ad;
  color: #fff;
  font-size: 0.85em;
  cursor: default;
}
a.fc-event {
  text-decoration: none;
}
a.fc-event,
.fc-event-draggable {
  cursor: pointer;
}
.fc-rtl .fc-event {
  text-align: right;
}
.fc-rtl .fc-grid .fc-day-number {
  float: left;
}
.fc-rtl .fc-grid .fc-event-time {
  float: right;
}
.fc-event-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fc-event-time,
.fc-event-title {
  padding: 0 1px;
}
.fc-event-hori {
  border-width: 1px 0;
  margin-bottom: 1px;
}
.fc-event-hori .ui-resizable-e {
  top: 0 !important;
  right: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: e-resize;
}
.fc-event-hori .ui-resizable-w {
  top: 0 !important;
  left: -3px !important;
  width: 7px !important;
  height: 100% !important;
  cursor: w-resize;
}
.fc-event-hori .ui-resizable-handle {
  _padding-bottom: 14px;
}
.fc-ltr .fc-event-hori.fc-event-start,
.fc-rtl .fc-event-hori.fc-event-end {
  border-left-width: 1px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-right-width: 1px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
table.fc-border-separate {
  border-collapse: separate;
  margin-top: 15px;
}
table.fc-border-separate thead {
  background: #fff;
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
}
table.fc-border-separate thead th {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.fc-border-separate th,
.fc-border-separate td {
  border-width: 1px 0 0 1px;
  padding: 8px 4px;
}
.fc-border-separate td {
  height: 81px;
}
.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
  border-right-width: 1px;
}
.fc-border-separate tr.fc-last th,
.fc-border-separate tr.fc-last td {
  border-bottom-width: 1px;
}
.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-first th {
  border-top-width: 0;
}
.fc-agenda table {
  border-collapse: separate;
}
.fc-agenda .fc-agenda-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: right;
  white-space: nowrap;
  font-weight: normal;
}
.fc-agenda .fc-week-number {
  font-weight: bold;
}
.fc-agenda .fc-day-content {
  padding: 2px 2px 1px;
}
.fc-agenda .ui-resizable-resizing {
  _overflow: hidden;
}
.fc-agenda-days th {
  text-align: center;
}
.fc-agenda-days .fc-agenda-axis {
  border-right-width: 1px;
}
.fc-agenda-days .fc-col0 {
  border-left-width: 0;
}
.fc-agenda-allday th {
  border-width: 0 1px;
}
.fc-agenda-allday .fc-day-content {
  min-height: 34px;
  _height: 34px;
}
.fc-agenda-divider-inner {
  height: 2px;
  overflow: hidden;
}
.fc-widget-header .fc-agenda-divider-inner {
  background: #eee;
}
.fc-agenda-slots th {
  border-width: 1px 1px 0;
}
.fc-agenda-slots td {
  border-width: 1px 0 0;
  background: none;
}
.fc-agenda-slots td div {
  height: 20px;
}
.fc-agenda-slots tr.fc-minor th.ui-widget-header {
  *border-top-style: solid;
}
.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
  border-top-width: 0;
}
.fc-agenda-slots tr.fc-minor th,
.fc-agenda-slots tr.fc-minor td {
  border-top-style: dotted;
}
.fc-event-vert {
  border-width: 0 1px;
}
.fc-event-vert.fc-event-start {
  border-top-width: 1px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.fc-event-vert.fc-event-end {
  border-bottom-width: 1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.fc-event-vert .fc-event-time {
  white-space: nowrap;
  font-size: 10px;
}
.fc-event-vert .fc-event-inner {
  position: relative;
  z-index: 2;
}
.fc-event-vert .fc-event-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.fc-event-vert .ui-resizable-s {
  bottom: 0 !important;
  width: 100% !important;
  height: 8px !important;
  overflow: hidden !important;
  line-height: 8px !important;
  font-size: 11px !important;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}
.fc .ui-draggable-dragging .fc-event-bg,
.fc-select-helper .fc-event-bg {
  display: none;
}
.fc-header-div {
  text-align: center;
  height: 16px;
}
/*
 *
 * jQuery TE 1.4.0 , http://jqueryte.com/
 * Copyright (C) 2013, Fatih Koca (fattih@fattih.com), (http://jqueryte.com/about)

 * jQuery TE is provided under the MIT LICENSE.
 *
*/
.htmledit {
  margin: 30px 0;
  border: #ccc 1px solid;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  overflow: hidden;
  transition: box-shadow 0.4s, border 0.4s;
  -webkit-transition: -webkit-box-shadow 0.4s, border 0.4s;
  -moz-transition: -moz-box-shadow 0.4s, border 0.4s;
  -o-transition: -o-box-shadow 0.4s, border 0.4s;
}
.htmledit * {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.htmledit_focused {
  border-color: #00aae7;
}
.htmledit_toolbar {
  overflow: auto;
  padding: 3px 4px;
  background: #eee;
  border-bottom: #bbb 1px solid;
}
.htmledit_tool {
  float: left;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.htmledit_tool.htmledit_tool_1 .htmledit_tool_label {
  position: relative;
  display: block;
  padding: 3px;
  width: 70px;
  height: 22px;
  line-height: 15px;
  overflow: hidden;
}
.htmledit_tool.htmledit_tool_1 .htmledit_tool_text {
  font: bold 13px Arial, sans-serif;
  color: #222;
}
.htmledit_tool.htmledit_tool_1 .htmledit_tool_icon {
  position: absolute;
  top: 10px;
  right: 2px;
  width: 6px;
  height: 4px;
  background-position: -19px -23px;
  border: none;
  border-radius: none;
  -webkit-border-radius: none;
  -moz-border-radius: none;
}
.htmledit_tool.htmledit_tool_2 .htmledit_tool_icon {
  background-position: 0 0;
}
.htmledit_tool.htmledit_tool_3 .htmledit_tool_icon {
  background-position: -22px 0;
}
.htmledit_tool.htmledit_tool_4 .htmledit_tool_icon {
  background-position: -44px 0;
}
.htmledit_tool.htmledit_tool_5 .htmledit_tool_icon {
  background-position: -66px 0;
}
.htmledit_tool.htmledit_tool_6 .htmledit_tool_icon {
  background-position: -88px 0;
}
.htmledit_tool.htmledit_tool_7 .htmledit_tool_icon {
  background-position: -110px 0;
}
.htmledit_tool.htmledit_tool_8 .htmledit_tool_icon {
  background-position: -132px 0;
}
.htmledit_tool.htmledit_tool_9 .htmledit_tool_icon {
  background-position: -154px 0;
}
.htmledit_tool.htmledit_tool_10 .htmledit_tool_icon {
  background-position: -176px 0;
}
.htmledit_tool.htmledit_tool_11 .htmledit_tool_icon {
  background-position: -198px 0;
}
.htmledit_tool.htmledit_tool_12 .htmledit_tool_icon {
  background-position: -220px 0;
}
.htmledit_tool.htmledit_tool_13 .htmledit_tool_icon {
  background-position: -242px 0;
}
.htmledit_tool.htmledit_tool_14 .htmledit_tool_icon {
  background-position: -264px 0;
}
.htmledit_tool.htmledit_tool_15 .htmledit_tool_icon {
  background-position: -286px 0;
}
.htmledit_tool.htmledit_tool_16 .htmledit_tool_icon {
  background-position: -308px 0;
}
.htmledit_tool.htmledit_tool_17 .htmledit_tool_icon {
  background-position: -330px 0;
}
.htmledit_tool.htmledit_tool_18 .htmledit_tool_icon {
  background-position: -352px 0;
}
.htmledit_tool.htmledit_tool_19 .htmledit_tool_icon {
  background-position: -374px 0;
}
.htmledit_tool.htmledit_tool_20 .htmledit_tool_icon {
  background-position: -396px 0;
}
.htmledit_tool.htmledit_tool_21 .htmledit_tool_icon {
  background-position: -418px 0;
}
.htmledit_tool:hover {
  background-color: #fff;
  border-color: #fff;
}
.htmledit_tool,
.htmledit_tool_icon,
.htmledit_tool_label {
  border: #eee 1px solid;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.htmledit_hiddenField {
  display: none;
}
.htmledit_tool_icon {
  display: block;
  width: 22px;
  height: 22px;
  background: url("../../public/getaways-xnet/assets/common-jquery-te.png") no-repeat;
}
.htmledit_tool:hover .htmledit_tool_icon,
.htmledit_tool:hover .htmledit_tool_label {
  border: #aaa 1px solid;
}
.htmledit_tool:active .htmledit_tool_icon,
.htmledit_tool:active .htmledit_tool_label {
  border: #777 1px solid;
}
.htmledit_tool.htmledit_tool_1:hover .htmledit_tool_icon,
.htmledit_tool.htmledit_tool_1:active .htmledit_tool_icon {
  border: none;
}
.htmledit_tool_depressed {
  background-color: #ddd;
  border-color: #ccc;
}
.htmledit_tool_depressed .htmledit_tool_icon {
  border-color: #aaa;
}
.htmledit_tool_depressed:hover {
  background-color: #eee;
}
.htmledit_linkform {
  padding: 5px 10px;
  background: #ddd;
  border-bottom: #bbb 1px solid;
}
.htmledit_linktypeselect {
  position: relative;
  float: left;
  width: 130px;
  background: #eee;
  cursor: pointer;
}
.htmledit_linktypeselect:Active {
  background: #fff;
}
.htmledit_linktypeview {
  padding: 3px 5px;
  border: #333 1px solid;
  color: #777;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  outline: none;
}
.htmledit_linktypetext {
  font-size: 12px;
}
.htmledit_linktypearrow {
  position: absolute;
  bottom: 8px;
  right: 6px;
  width: 7px;
  height: 6px;
  margin: 0 auto;
  background: url("../../public/getaways-xnet/assets/common-jquery-te.png") -7px -23px no-repeat;
}
.htmledit_linktypes {
  display: none;
  position: absolute;
  top: 22px;
  left: 1px;
  width: 125px;
  background: #fff;
  border: #333 1px solid;
  box-shadow: 0 1px 4px #aaa;
  -webkit-box-shadow: 0 1px 4px #aaa;
  -moz-box-shadow: 0 1px 4px #aaa;
}
.htmledit_linktypes a {
  display: block;
  padding: 4px 5px;
  font-size: 12px;
}
.htmledit_linktypes a:hover {
  background: #ddd;
}
.htmledit_linkinput {
  float: left;
  margin: 0 5px;
  padding: 3px 5px;
  width: 300px;
  background: #eee;
  border: #333 1px solid;
  color: #777;
  font-size: 12px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  outline: none;
}
.htmledit_linkinput:focus,
.htmledit_linkinput:hover {
  background: #fff;
  border-color: #000;
}
.htmledit_linkbutton {
  float: left;
  padding: 3px 12px;
  background: #aaa;
  border: #333 1px solid;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  box-shadow: inset 0 1px #eee;
  -webkit-box-shadow: inset 0 1px #eee;
  -moz-box-shadow: inset 0 1px #eee;
}
.htmledit_linkbutton:hover {
  background: #a1a1a1;
}
.htmledit_linkbutton:active {
  box-shadow: inset 0 1px #ccc;
  -webkit-box-shadow: inset 0 1px #ccc;
  -moz-box-shadow: inset 0 1px #ccc;
  background: #888;
}
.htmledit_formats {
  display: none;
  position: absolute;
  width: 180px;
  oveflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  border: #aaa 1px solid;
  box-shadow: 0 0 5px #aaa;
  -webkit-box-shadow: 0 0 5px #aaa;
  -moz-box-shadow: 0 0 5px #aaa;
  z-index: 100;
}
.htmledit_format {
  display: block;
  padding: 4px 7px;
  font-size: 13px;
}
.htmledit_format:hover {
  background: #ddd;
}
.htmledit_format_1,
.htmledit_format_2,
.htmledit_format_3,
.htmledit_format_4,
.htmledit_format_5,
.htmledit_format_6 {
  font-weight: bold;
}
.htmledit_format_1 {
  font-size: 22px;
}
.htmledit_format_2 {
  font-size: 20px;
}
.htmledit_format_3 {
  font-size: 18px;
}
.htmledit_format_4 {
  font-size: 16px;
}
.htmledit_format_5 {
  font-size: 14px;
}
.htmledit_format_6 {
  font-size: 12px;
}
.htmledit_format_7 {
  font-family: "Courier New", Courier, monospace;
}
.htmledit_fontsizes {
  display: none;
  position: absolute;
  width: 180px;
  height: 198px;
  oveflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  border: #aaa 1px solid;
  box-shadow: 0 0 5px #aaa;
  -webkit-box-shadow: 0 0 5px #aaa;
  -moz-box-shadow: 0 0 5px #aaa;
}
.htmledit_fontsize {
  display: block;
  padding: 3px 7px;
}
.htmledit_fontsize:hover {
  background: #ddd;
}
.htmledit_cpalette {
  display: none;
  position: absolute;
  padding: 6px;
  width: 144px;
  background: #fff;
  border: #aaa 1px solid;
  box-shadow: 0 0 5px #aaa;
  -webkit-box-shadow: 0 0 5px #aaa;
  -moz-box-shadow: 0 0 5px #aaa;
}
.htmledit_color {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  border: #fff 1px solid;
}
.htmledit_color:hover {
  border-color: #000;
}
.htmledit_colorSeperator {
  float: none;
  clear: both;
  height: 7px;
}
.htmledit_editor,
.htmledit_source {
  padding: 10px;
  background: #fff;
  min-height: 100px;
  max-height: 900px;
  overflow: auto;
  outline: none;
  word-wrap: break-word;
  -ms-word-wrap: break-word;
  resize: vertical;
}
.htmledit_editor div,
.htmledit_editor p {
  margin: 0 0 7px;
}
.htmledit_editor a:link,
.htmledit_editor a:link * {
  color: #06f !important;
  text-decoration: underline;
}
.htmledit_editor blockquote {
  margin-top: 0;
  margin-bottom: 7px;
}
.htmledit_editor img {
  float: left;
  margin: 0 10px 5px 0;
}
.htmledit_editor a[jqte-setlink],
.htmledit_editor a[jqte-setlink] * {
  background: #3297fd !important;
  color: #fff !important;
}
.htmledit_editor h1,
.htmledit_editor h2,
.htmledit_editor h3,
.htmledit_editor h4,
.htmledit_editor h5,
.htmledit_editor h6,
.htmledit_editor pre {
  display: block;
  margin: 0 0 3px;
}
.htmledit_editor h1,
.htmledit_editor h1 * {
  font-size: 26px;
}
.htmledit_editor h2,
.htmledit_editor h2 * {
  font-size: 24px;
}
.htmledit_editor h3,
.htmledit_editor h3 * {
  font-size: 22px;
}
.htmledit_editor h4,
.htmledit_editor h4 * {
  font-size: 20px;
}
.htmledit_editor h5,
.htmledit_editor h5 * {
  font-size: 18px;
}
.htmledit_editor h6,
.htmledit_editor h6 * {
  font-size: 15px;
}
.htmledit_editor pre,
.htmledit_editor pre * {
  font-family: "Courier New", Courier, monospace;
}
.htmledit_source,
.htmledit_source textarea {
  background: #fff1e8;
}
.htmledit_source textarea {
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
  width: 100% !important;
  min-height: 100px;
  font-family: Courier, Arial, sans-serif !important;
  font-weight: normal;
  font-size: 15px;
  overflow: hidden !important;
  outline: none;
  resize: none;
}
.htmledit_source textarea,
.htmledit_source textarea:focus {
  css_shadow: none !important;
  background: none !important;
  border: none !important;
}
.htmledit_title {
  display: none;
  position: absolute;
  z-index: 9999;
}
.htmledit_titleArrow {
  position: relative;
}
.htmledit_titleArrowIcon {
  width: 7px;
  height: 6px;
  margin: 0 auto;
  background: url("../../public/getaways-xnet/assets/common-jquery-te.png") 0 -23px no-repeat;
}
.htmledit_titleText {
  padding: 5px 7px;
  margin-top: 0;
  min-width: 5px;
  min-height: 1px;
  max-width: 400px;
  background: #000;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  word-wrap: break-word;
  -ms-word-wrap: break-word;
}
.htmledit_titleText,
.htmledit_titleText * {
  color: #fff;
  font-size: 11px;
}
.htmledit_placeholder {
  position: relative;
  display: none;
}
.htmledit_placeholder_text {
  position: absolute;
  top: 43px;
  left: 10px;
  font-size: 14px;
  color: #ccc;
}
.tabs-container2 {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  min-height: 36px;
}
.tabs-container2 .tabs2 {
  list-style: none;
  padding: 0;
  display: table;
  margin: 0 auto;
  position: relative;
  bottom: -1px;
}
.tabs-container2 .tabs2.left-align {
  margin-left: 10px;
}
.tabs-container2 .tabs2 li {
  float: left;
  height: 35px;
}
.tabs-container2 .tabs2 li:last-of-type a {
  border-right: 1px solid #ccc;
}
.tabs-container2 .tabs2 a {
  text-align: center;
  height: 30px;
  display: block;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: -moz-linear-gradient(to bottom, #f9f9f9, #ddd);
  background-image: linear-gradient(to bottom, #f9f9f9, #ddd);
  padding: 7px 15px;
  margin-top: 5px;
  white-space: nowrap;
  border: solid #ccc;
  border-width: 1px 1px 1px 1px;
  color: #666;
  font-weight: bold;
  text-shadow: 0 -1px 0 #fff;
  font-size: 12px;
  line-height: 16px;
}
.tabs-container2 .tabs2 li.active,
.tabs-container2 .tabs2 li.ui-state-active {
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
  position: relative;
  bottom: 0;
}
.tabs-container2 .tabs2 li.active a,
.tabs-container2 .tabs2 li.ui-state-active a {
  background-image: none;
  background-color: #fff;
  border: none;
  padding: 7px 14px 7px 15px;
  font-size: 14px;
}
.tabs-container2 .tabs2 li.active + li a,
.tabs-container2 .tabs2 li.ui-state-active + li a {
  border-left: none;
}
body.authentication {
  background-color: #f2f2f2;
  height: 100%;
  min-height: 600px;
}
body.authentication header,
body.authentication footer {
  display: none;
}
body.authentication .container {
  display: table;
  margin: 0 auto;
  height: 100vh;
}
body.authentication .center-form {
  width: 365px;
  display: table-cell;
  vertical-align: middle;
}
body.authentication .center-form .title {
  text-align: center;
  color: #666;
  display: table;
  margin: 0 auto;
}
body.authentication .center-form .title .icon-groupon {
  color: #82b548;
  font-size: 42px;
  display: table-cell;
}
body.authentication .center-form .title .title-text {
  padding-left: 10px;
  font-size: 22px;
  display: table-cell;
  vertical-align: middle;
}
body.authentication .center-form #gdpr-cb-wrapper {
  position: absolute;
}
body.authentication #login-form {
  color: #666;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px 1px #ddd;
  border-radius: 5px;
}
body.authentication #login-form .form-content {
  padding: 20px 30px;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
body.authentication #login-form .form-content label {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.authentication #login-form .form-content input,
body.authentication #login-form .form-content select {
  margin: 0;
}
body.authentication #login-form .action-buttons {
  margin-top: 0;
  padding: 20px 30px;
  border-top: 1px solid #ccc;
}
body.authentication #login-form .action-buttons button {
  font-weight: bold;
}
body.authentication .error-message {
  margin-bottom: 20px;
  color: #ed4e40;
}
body.authentication_2fa {
  background-color: #f2f2f2;
  height: 100%;
  min-height: 600px;
}
body.authentication_2fa header,
body.authentication_2fa footer {
  display: none;
}
body.authentication_2fa .container {
  display: table;
  margin: 0 auto;
  height: 100vh;
}
body.authentication_2fa .center-form {
  width: 365px;
  display: table-cell;
  vertical-align: middle;
}
body.authentication_2fa .center-form .title {
  text-align: center;
  color: #666;
  display: table;
  margin: 0 auto;
}
body.authentication_2fa .center-form .title .icon-groupon {
  color: #82b548;
  font-size: 42px;
  display: table-cell;
}
body.authentication_2fa .center-form .title .title-text {
  padding-left: 10px;
  font-size: 22px;
  display: table-cell;
  vertical-align: middle;
}
body.authentication_2fa .center-form #gdpr-cb-wrapper {
  position: absolute;
}
body.authentication_2fa #login-form-2fa {
  color: #666;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px 1px #ddd;
  border-radius: 5px;
}
body.authentication_2fa #login-form-2fa .form-content {
  padding: 20px 30px;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
body.authentication_2fa #login-form-2fa .form-content label {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.authentication_2fa #login-form-2fa .form-content input,
body.authentication_2fa #login-form-2fa .form-content select {
  margin: 0;
}
body.authentication_2fa #login-form-2fa .action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  border-top: 1px solid #ccc;
}
body.authentication_2fa #login-form-2fa .action-buttons button {
  font-weight: bold;
}
body.authentication_2fa .error-message {
  margin-bottom: 20px;
  color: #ed4e40;
}
body.extranet .bookings-page #booking-download-modal .modal-body {
  width: 400px;
}
body.extranet .bookings-page #booking-download-modal .modal-body .modal-title {
  font-size: 20px;
}
body.extranet .bookings-page #booking-download-modal .modal-body .modal-subtitle {
  font-size: 13px;
  margin-bottom: 10px;
}
body.extranet .bookings-page .icon-download {
  font-size: 11px;
}
body.extranet .bookings-page #bookings-filters {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin-bottom: 10px;
}
body.extranet .bookings-page #bookings-filters .columns {
  padding: 0 15px;
}
body.extranet .bookings-page input,
body.extranet .bookings-page select {
  margin-bottom: 0;
  height: 32px;
  font-size: 13px;
}
body.extranet .bookings-page .date-field {
  border: 1px solid #ccc;
  background-color: #fff;
}
body.extranet .bookings-page .date-field input {
  height: 30px;
  border: none;
  box-shadow: none;
  width: 80%;
}
body.extranet .bookings-page .date-field .icon-calendar {
  padding-left: 8px;
  display: inline-block;
}
body.extranet .bookings-page .date-field.error {
  border-color: #cc2127;
}
body.extranet .bookings-page .date-field.error span {
  color: #cc2127;
}
body.extranet .bookings-page .booking-modal .modal-body {
  margin-top: 50px;
  width: auto;
  max-width: 700px;
}
body.extranet .bookings-page .booking-modal table.table-striped th {
  border-bottom: none;
}
body.extranet .bookings-page .booking-modal table.table-striped tbody tr:last-of-type td {
  border-bottom: none;
}
body.extranet .bookings-page td.cancellation-date,
body.extranet .bookings-page td > div.cancellation-date {
  color: #cc2127;
}
body.extranet .bookings-page td.status.canceled {
  color: #cc2127;
}
body.extranet .bookings-page .bookings-printable-table {
  margin-top: 10px;
}
body.extranet .bookings-page .bookings-printable-table table {
  width: 100%;
  border-top: 1px solid #ccc;
  page-break-inside: avoid;
  margin-bottom: 0;
}
body.extranet .bookings-page .bookings-printable-table table:last-child {
  border-bottom: 1px solid #ccc;
}
body.extranet .bookings-page .bookings-printable-table table tr {
  page-break-inside: avoid;
}
body.extranet .bookings-page .bookings-printable-table table tr:last-of-type td,
body.extranet .bookings-page .bookings-printable-table table th {
  border-bottom: none;
}
body.extranet .bookings-page .bookings-printable-table table tr:first-child td,
body.extranet .bookings-page .bookings-printable-table table tr:first-child th {
  padding-top: 12px;
}
body.extranet .bookings-page .bookings-printable-table table tr:last-child td,
body.extranet .bookings-page .bookings-printable-table table tr:last-child th {
  padding-bottom: 12px;
}
body.extranet .bookings-page .bookings-printable-table table td,
body.extranet .bookings-page .bookings-printable-table table th {
  padding: 1px 10px;
  border-top: none;
  border-bottom: none;
}
body.extranet .bookings-page .bookings-printable-table table th {
  padding-left: 10px;
}
#profile-page .sidenav {
  padding-right: 30px;
}
#profile-page .sidenav h5 {
  padding-top: 20px;
  padding-bottom: 10px;
}
#profile-page .sidenav nav ul li a {
  padding: 10px;
  line-height: 1;
  font-size: 13px;
}
#profile-page .sidenav nav ul li a span {
  float: right;
  display: none;
  color: #aaa;
}
#profile-page .sidenav nav ul li.active a {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}
#profile-page .sidenav nav ul li.active a span {
  display: inline-block;
}
#profile-page .sidenav #hotel-status {
  margin-right: 10px;
}
#profile-page .sidenav #hotel-status .status-btn {
  position: relative;
  width: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#profile-page .sidenav #hotel-status .status-btn-checkbox {
  display: none;
}
#profile-page .sidenav #hotel-status .status-btn-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
}
#profile-page .sidenav #hotel-status .status-btn-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
#profile-page .sidenav #hotel-status .status-btn-inner:before,
#profile-page .sidenav #hotel-status .status-btn-inner:after {
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 25px;
  font-size: 13px;
  color: #fff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}
#profile-page .sidenav #hotel-status .status-btn-inner:before {
  content: "Active";
  padding-left: 20px;
  background-color: #ebf3db;
  color: #75a145;
}
#profile-page .sidenav #hotel-status .status-btn-inner:after {
  content: "Inactive";
  padding-right: 15px;
  background-color: #bdbec0;
  color: #fff;
  text-align: right;
}
#profile-page .sidenav #hotel-status .status-btn-switch {
  width: 27px;
  margin: 0px;
  background: #f3f3f4;
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 73px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
#profile-page .sidenav #hotel-status .status-btn-checkbox:checked + .status-btn-label .status-btn-inner {
  margin-left: 0;
}
#profile-page .sidenav #hotel-status .status-btn-checkbox:checked + .status-btn-label .status-btn-switch {
  right: 0px;
}
#profile-page label.required:before,
#profile-page small.required:before {
  content: "*";
  margin-right: 3px;
}
#profile-page .view.loading {
  min-height: 300px;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
#profile-page .view.loading .content {
  visibility: hidden;
}
#profile-page .section-title {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#profile-page .filter {
  background-color: #f5f5f5;
  padding: 10px;
}
#profile-page .modal-confirmation .modal-body {
  width: 400px;
  min-height: 200px;
}
#profile-page .modal-confirmation .icon-alert {
  font-size: 30px;
  margin-bottom: 20px;
  color: #666;
}
#profile-page #basic-info h5 {
  padding-top: 10px;
  padding-bottom: 10px;
}
#profile-page #description .htmledit {
  margin: 10px 0;
}
#profile-page #room-info #add-room-button {
  float: right;
}
#profile-page #room-info .modal h5 {
  padding-top: 20px;
  padding-bottom: 10px;
}
#profile-page #rate-plans #add-room-button {
  float: right;
}
#profile-page #contacts #add-contact-button {
  float: right;
}
#profile-page #contacts i.icon-circle {
  width: 13px;
  height: 13px;
  background: #fff;
  color: transparent;
  display: inline-flex;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
}
#profile-page #contacts i.icon-circle.disabled {
  background: #ccc;
}
#profile-page #contacts i.icon-close {
  color: #999;
  cursor: pointer;
}
#profile-page #taxes i.icon-close {
  color: #999;
  cursor: pointer;
}
#profile-page #taxes .disclaimer {
  margin-top: -15px;
  margin-bottom: 15px;
  background-color: #f2f2f2;
  padding: 20px;
  font-size: 12px;
  color: #666;
}
#profile-page #taxes h5 {
  padding-top: 10px;
  padding-bottom: 10px;
}
#profile-page #taxes #taxes-selection {
  background-color: #f5f5f5;
  padding: 0 10px 10px 10px;
}
#profile-page #taxes #taxes-selection table {
  margin-bottom: 10px;
}
#profile-page #taxes #taxes-selection thead,
#profile-page #taxes #taxes-selection tbody {
  background: #f5f5f5;
}
#profile-page #taxes #taxes-selection .action-buttons {
  margin-top: 10px;
}
#profile-page #channel-manager.disabled {
  pointer-events: none;
}
#profile-page #channel-manager label {
  color: #999;
}
#profile-page #channel-manager .connection-status-wrapper {
  margin: 20px 0;
}
#profile-page #channel-manager #connection-status-text {
  text-transform: uppercase;
}
#profile-page #channel-manager #connection-status {
  width: 15px;
  height: 15px;
  line-height: 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: -3px;
}
#profile-page #channel-manager #connection-status:after {
  color: #fff;
}
#profile-page #channel-manager #connection-status.disabled {
  cursor: not-allowed;
}
#profile-page #channel-manager #connection-status.active {
  background-color: #82b548;
  border-color: #82b548;
  vertical-align: 0px;
}
#profile-page #channel-manager #connection-status.active:after {
  content: "\2713";
}
#profile-page #channel-manager #connection-name-selector {
  text-transform: capitalize;
}
#profile-page #channel-manager #connection-name-selector:disabled {
  cursor: not-allowed;
}
#profile-page #channel-manager .modal-confirmation .modal-body {
  width: 450px;
}
#profile-page #channel-manager .modal-confirmation .modal-body .warning-text {
  color: #cc2127;
  margin: 10px 0;
}
#profile-page #channel-manager #channel-manager-mapping-container {
  margin-top: 30px;
}
#profile-page #channel-manager #channel-manager-mapping-container.loading {
  min-height: 200px;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
#profile-page #channel-manager #channel-manager-mapping-container.loading .content {
  visibility: hidden;
}
#profile-page #channel-manager #channel-manager-mapping-container .channel-manager-name {
  text-transform: capitalize;
  font-size: 12px;
}
#profile-page #channel-manager #channel-manager-mapping-container input {
  margin-bottom: 20px;
}
#profile-page #channel-manager #channel-manager-mapping-container .field-error-message {
  color: #cc2127;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  width: 600px;
}
#profile-page #channel-manager #channel-manager-mapping-container .field-error-message a {
  text-decoration: underline;
}
#profile-page #channel-manager #channel-manager-mapping-container section.room-types hr {
  border-color: #eee;
}
#profile-page #channel-manager #channel-manager-mapping-container section.rate-plans .section-title {
  margin-top: 10px;
  text-transform: uppercase;
}
#profile-page #channel-manager #channel-manager-mapping-container section.rate-plans .rate-plan {
  margin-bottom: 10px;
}
#profile-page #channel-manager #channel-manager-mapping-container section.rate-plans .rate-plan .rate-plan-link {
  font-size: 14px;
}
#profile-page #channel-manager #channel-manager-mapping-container section.rate-plans .rate-plan .bonus-add {
  margin-left: 10px;
  padding: 2px 8px;
  border: 1px solid #999;
  border-radius: 15px;
  cursor: pointer;
  color: #888;
  font-size: 12px;
}
#profile-page #channel-manager #channel-manager-mapping-container .no-active-promotions-message {
  margin: 20px 0;
}
#profile-page .modal .confirm-message {
  font-size: 20px;
  margin-bottom: 20px;
}
body.extranet .inventory-page .page-title,
body.extranet .inventory-download .page-title {
  margin-bottom: 10px;
}
body.extranet .inventory-page .tabs-btn ul.list-btn,
body.extranet .inventory-download .tabs-btn ul.list-btn {
  margin-top: 10px;
}
body.extranet .inventory-page .tabs-btn ul.list-btn li.divider,
body.extranet .inventory-download .tabs-btn ul.list-btn li.divider {
  border-left: #ccc solid 1px;
  padding-left: 10px;
}
body.extranet .inventory-page .filter-section .text-label,
body.extranet .inventory-download .filter-section .text-label {
  float: left;
  line-height: 30px;
  margin-right: 10px;
}
body.extranet .inventory-page .amount-field,
body.extranet .inventory-download .amount-field,
body.extranet .inventory-page .percentage-field,
body.extranet .inventory-download .percentage-field {
  padding: 0 0 0 5px;
  border: 1px solid #ccc;
  margin: 0;
  width: 95px;
  text-align: left;
  background: #fff;
  float: left;
}
body.extranet .inventory-page .amount-field.disabled,
body.extranet .inventory-download .amount-field.disabled,
body.extranet .inventory-page .percentage-field.disabled,
body.extranet .inventory-download .percentage-field.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
body.extranet .inventory-page .amount-field input,
body.extranet .inventory-download .amount-field input,
body.extranet .inventory-page .percentage-field input,
body.extranet .inventory-download .percentage-field input {
  border: 0;
  width: 55px;
  margin: 0;
  padding: 0px;
  text-align: right;
}
body.extranet .inventory-page .percentage-field input,
body.extranet .inventory-download .percentage-field input {
  text-align: right;
  margin-bottom: 1px;
}
body.extranet .inventory-page .percentage-field .word-or,
body.extranet .inventory-download .percentage-field .word-or {
  position: absolute;
  right: -7px;
  font-size: 13px;
  z-index: 1;
  display: inline;
}
body.extranet .inventory-page input[disabled],
body.extranet .inventory-download input[disabled] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
body.extranet .inventory-page .amount-field span.currency-symbol,
body.extranet .inventory-download .amount-field span.currency-symbol {
  width: 15px;
  display: inline-block;
}
body.extranet .inventory-page a[disabled],
body.extranet .inventory-download a[disabled] {
  pointer-events: none;
}
body.extranet .inventory-page .room-availability,
body.extranet .inventory-download .room-availability {
  float: right;
  width: 78px;
}
body.extranet .inventory-page .amount-field input,
body.extranet .inventory-download .amount-field input,
body.extranet .inventory-page .percentage-field input,
body.extranet .inventory-download .percentage-field input,
body.extranet .inventory-page .field input,
body.extranet .inventory-download .field input,
body.extranet .inventory-page .date-field input,
body.extranet .inventory-download .date-field input {
  outline: none;
  box-shadow: none;
}
body.extranet .inventory-page .amount-field input.focus,
body.extranet .inventory-download .amount-field input.focus,
body.extranet .inventory-page .percentage-field input.focus,
body.extranet .inventory-download .percentage-field input.focus,
body.extranet .inventory-page .field input.focus,
body.extranet .inventory-download .field input.focus,
body.extranet .inventory-page .date-field input.focus,
body.extranet .inventory-download .date-field input.focus {
  border: 1px solid #82b548 !important;
  box-shadow: #82b548 0 0 2px;
}
body.extranet .inventory-page .amount-field.focus,
body.extranet .inventory-download .amount-field.focus,
body.extranet .inventory-page .percentage-field.focus,
body.extranet .inventory-download .percentage-field.focus,
body.extranet .inventory-page .field.focus,
body.extranet .inventory-download .field.focus,
body.extranet .inventory-page .date-field.focus,
body.extranet .inventory-download .date-field.focus {
  border: 1px solid #82b548 !important;
  box-shadow: #82b548 0 0 2px;
}
body.extranet .inventory-page .amount-field.focus span,
body.extranet .inventory-download .amount-field.focus span,
body.extranet .inventory-page .percentage-field.focus span,
body.extranet .inventory-download .percentage-field.focus span,
body.extranet .inventory-page .field.focus span,
body.extranet .inventory-download .field.focus span,
body.extranet .inventory-page .date-field.focus span,
body.extranet .inventory-download .date-field.focus span {
  color: #82b548 !important;
}
body.extranet .inventory-page .amount-field.error,
body.extranet .inventory-download .amount-field.error,
body.extranet .inventory-page .percentage-field.error,
body.extranet .inventory-download .percentage-field.error,
body.extranet .inventory-page .field.error,
body.extranet .inventory-download .field.error,
body.extranet .inventory-page .date-field.error,
body.extranet .inventory-download .date-field.error {
  border: 1px solid #cc2127 !important;
  box-shadow: #ff5157 0 0 2px;
}
body.extranet .inventory-page .amount-field.error span,
body.extranet .inventory-download .amount-field.error span,
body.extranet .inventory-page .percentage-field.error span,
body.extranet .inventory-download .percentage-field.error span,
body.extranet .inventory-page .field.error span,
body.extranet .inventory-download .field.error span,
body.extranet .inventory-page .date-field.error span,
body.extranet .inventory-download .date-field.error span {
  color: #cc2127 !important;
}
body.extranet .inventory-page .blackout-text,
body.extranet .inventory-download .blackout-text,
body.extranet .inventory-page .no-check-in-text,
body.extranet .inventory-download .no-check-in-text,
body.extranet .inventory-page .sold-count-text,
body.extranet .inventory-download .sold-count-text {
  text-transform: uppercase;
}
body.extranet .inventory-page .blackout-status,
body.extranet .inventory-download .blackout-status,
body.extranet .inventory-page .no-check-in-status,
body.extranet .inventory-download .no-check-in-status {
  width: 15px;
  height: 15px;
  line-height: 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: -3px;
}
body.extranet .inventory-page .blackout-status:after,
body.extranet .inventory-download .blackout-status:after,
body.extranet .inventory-page .no-check-in-status:after,
body.extranet .inventory-download .no-check-in-status:after {
  color: #fff;
}
body.extranet .inventory-page .blackout-status.disabled,
body.extranet .inventory-download .blackout-status.disabled,
body.extranet .inventory-page .no-check-in-status.disabled,
body.extranet .inventory-download .no-check-in-status.disabled {
  cursor: not-allowed;
}
body.extranet .inventory-page .blackout-status.blackout,
body.extranet .inventory-download .blackout-status.blackout,
body.extranet .inventory-page .no-check-in-status.blackout,
body.extranet .inventory-download .no-check-in-status.blackout,
body.extranet .inventory-page .blackout-status.no-check-in,
body.extranet .inventory-download .blackout-status.no-check-in,
body.extranet .inventory-page .no-check-in-status.no-check-in,
body.extranet .inventory-download .no-check-in-status.no-check-in,
body.extranet .inventory-page .blackout-status.partial,
body.extranet .inventory-download .blackout-status.partial,
body.extranet .inventory-page .no-check-in-status.partial,
body.extranet .inventory-download .no-check-in-status.partial {
  background-color: #666;
  border-color: #666;
  vertical-align: 1px;
}
body.extranet .inventory-page .blackout-status.blackout:after,
body.extranet .inventory-download .blackout-status.blackout:after,
body.extranet .inventory-page .no-check-in-status.blackout:after,
body.extranet .inventory-download .no-check-in-status.blackout:after,
body.extranet .inventory-page .blackout-status.no-check-in:after,
body.extranet .inventory-download .blackout-status.no-check-in:after,
body.extranet .inventory-page .no-check-in-status.no-check-in:after,
body.extranet .inventory-download .no-check-in-status.no-check-in:after {
  content: "\2713";
}
body.extranet .inventory-page .blackout-status.partial:after,
body.extranet .inventory-download .blackout-status.partial:after,
body.extranet .inventory-page .no-check-in-status.partial:after,
body.extranet .inventory-download .no-check-in-status.partial:after {
  content: "\2212";
  display: block;
  line-height: 10px;
}
body.extranet .inventory-page .no-check-in-status.partial,
body.extranet .inventory-download .no-check-in-status.partial {
  vertical-align: 9px;
}
body.extranet .inventory-page .month-view .filter-section .date-range,
body.extranet .inventory-download .month-view .filter-section .date-range {
  margin-left: 0;
  float: left;
  border-right: #ccc solid 1px;
  padding-right: 10px;
}
body.extranet .inventory-page .month-view .filter-section .date-range .date-field,
body.extranet .inventory-download .month-view .filter-section .date-range .date-field {
  margin-right: 0;
  float: left;
  width: 130px;
}
body.extranet .inventory-page .month-view .filter-section .date-range .date-field input,
body.extranet .inventory-download .month-view .filter-section .date-range .date-field input {
  width: 95px;
}
body.extranet .inventory-page .month-view .filter-section .date-range span.dash,
body.extranet .inventory-download .month-view .filter-section .date-range span.dash {
  float: left;
  margin: 5px 7px 0;
}
body.extranet .inventory-page .month-view .filter-section .icon-button,
body.extranet .inventory-download .month-view .filter-section .icon-button {
  color: #999;
  margin-right: 0;
  float: left;
  height: 32px;
}
body.extranet .inventory-page .month-view .filter-section .date-range,
body.extranet .inventory-download .month-view .filter-section .date-range,
body.extranet .inventory-page .month-view .filter-section .show-all,
body.extranet .inventory-download .month-view .filter-section .show-all,
body.extranet .inventory-page .month-view .filter-section select,
body.extranet .inventory-download .month-view .filter-section select {
  margin-right: 10px;
}
body.extranet .inventory-page .month-view .month-view-calendar.loading,
body.extranet .inventory-download .month-view .month-view-calendar.loading {
  min-height: 750px;
  width: 100%;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
body.extranet .inventory-page .month-view .month-view-calendar.loading *,
body.extranet .inventory-download .month-view .month-view-calendar.loading * {
  visibility: hidden;
}
body.extranet .inventory-page .month-view .month-view-days,
body.extranet .inventory-download .month-view .month-view-days {
  margin-bottom: 0;
}
body.extranet .inventory-page .month-view .month-view-days li,
body.extranet .inventory-download .month-view .month-view-days li {
  margin: 0 1px;
}
body.extranet .inventory-page .month-view .month-view-days li a,
body.extranet .inventory-download .month-view .month-view-days li a {
  padding: 4px 10px;
}
body.extranet .inventory-page .month-view .room-info,
body.extranet .inventory-download .month-view .room-info {
  color: #666;
}
body.extranet .inventory-page .month-view .room-info h2,
body.extranet .inventory-download .month-view .room-info h2 {
  margin-bottom: 10px;
  border-bottom: 2px solid #888;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}
body.extranet .inventory-page .month-view .room-info .total-rooms-available,
body.extranet .inventory-download .month-view .room-info .total-rooms-available {
  float: right;
  margin-bottom: 10px;
}
body.extranet .inventory-page .month-view .room-info .total-rooms-available span,
body.extranet .inventory-download .month-view .room-info .total-rooms-available span {
  font-size: 12px;
}
body.extranet .inventory-page .month-view .room-info .total-rooms-available span.available-count,
body.extranet .inventory-download .month-view .room-info .total-rooms-available span.available-count {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  padding-left: 5px;
}
body.extranet .inventory-page .month-view .room-info .error-message,
body.extranet .inventory-download .month-view .room-info .error-message {
  color: #cc2127;
}
body.extranet .inventory-page .month-view #room_inv_section .buttons-section,
body.extranet .inventory-download .month-view #room_inv_section .buttons-section {
  border-top: 1px solid #aaa;
  text-align: right;
}
body.extranet .inventory-page .month-view #room_inv_section .status-change-buttons,
body.extranet .inventory-download .month-view #room_inv_section .status-change-buttons {
  margin-top: 60px;
}
body.extranet .inventory-page .month-view .room-inventory-section,
body.extranet .inventory-download .month-view .room-inventory-section {
  clear: both;
  margin-bottom: 30px;
}
body.extranet .inventory-page .month-view .room-inventory-section .error,
body.extranet .inventory-download .month-view .room-inventory-section .error {
  border: solid 1px #cc2127;
}
body.extranet .inventory-page .month-view .room-inventory-section ul.parsley-error-list,
body.extranet .inventory-download .month-view .room-inventory-section ul.parsley-error-list {
  display: none !important;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row {
  margin: 40px 0;
  line-height: 30px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .room-header,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .room-header {
  margin-bottom: 10px;
  position: relative;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .room-header .room-name,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .room-header .room-name {
  font-size: 16px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  line-height: 18px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .room-header .available,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .room-header .available {
  position: absolute;
  right: 0;
  bottom: 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .room-header .available .available-text,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .room-header .available .available-text {
  padding: 5px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .room-header .room-availability,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .room-header .room-availability {
  margin: 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary {
  border: 1px solid #ccc;
  margin: 0 0 30px 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header {
  padding: 5px 15px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .rateplan-name,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .rateplan-name {
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
  line-height: 18px;
  word-break: break-word;
  display: inline-block;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .rateplan-name:hover,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .rateplan-name:hover {
  color: #444;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .min-length-of-stay,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .min-length-of-stay {
  cursor: pointer;
  padding: 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .min-length-of-stay img,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .min-length-of-stay img {
  width: 12px;
  margin-bottom: -1px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .blackout-text,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .blackout-text {
  font-size: 12px;
  width: 65px;
  line-height: 14px;
  display: inline-block;
  text-align: center;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .blackout-status,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rateplan-header .blackout-status {
  vertical-align: top;
  margin-top: 8px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section {
  border-top: 1px solid #e0e0e0;
  padding: 5px 15px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns {
  padding-bottom: 20px;
  background: #f5f5f5;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper {
  position: absolute;
  font-size: 12px;
  bottom: -13px;
  font-weight: bold;
  text-align: center;
  margin-left: -10px;
  width: 100%;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container {
  background: #333;
  line-height: 15px;
  color: #fff;
  padding: 2px 10px;
  border-radius: 10px;
  display: inline-block;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container.valid,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container.valid {
  background: #82b548;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container.invalid,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container.invalid {
  background: #cc2127;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container.hide,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section .highlight.columns .discount-percentage-wrapper .discount-percentage-container.hide {
  display: none;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section #groupon-rate-update-prevention-modal .modal-body,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .rates-section #groupon-rate-update-prevention-modal .modal-body {
  width: 600px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link {
  cursor: pointer;
  color: inherit;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link:hover,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link:hover {
  color: #444;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link.active i.icon-arrow-up,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link.active i.icon-arrow-up {
  display: inline-block;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link.active i.icon-arrow-down,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link.active i.icon-arrow-down {
  display: none;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link i,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-link i {
  position: absolute;
  top: 2px;
  font-size: 18px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section {
  display: none;
  border-top: 1px dashed #ccc;
  padding: 5px 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section .no-check-in-restriction,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section .no-check-in-restriction {
  padding: 10px 10px 0 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section .no-check-in-restriction .no-check-in-text,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section .no-check-in-restriction .no-check-in-text {
  display: inline-block;
  width: 80px;
  line-height: 15px;
  font-size: 12px;
  text-align: left;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section .no-check-in-restriction .no-checkin-status,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rateplan-summary .restrictions-section .no-check-in-restriction .no-checkin-status {
  display: inline-block;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row {
  padding: 10px;
  background: #f6f6f6;
  margin-bottom: 3px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .percentage-field.plus-field,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .percentage-field.plus-field {
  width: 70px;
  padding: 0 3px 0 0;
  text-align: right;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings {
  border-top: 2px solid #e3e4e4;
  display: none;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings input,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings input {
  width: 80px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings input.small,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings input.small {
  width: 55px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings input.tiny,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings input.tiny {
  width: 40px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings ul.list-btn,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings ul.list-btn {
  position: relative;
  bottom: -25px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings ul.list-btn li a,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row .margin-settings ul.list-btn li a {
  padding: 4px 10px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row.current-selection,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row.current-selection {
  border: 1px solid #82b548;
  border-left-width: 5px;
  background: #fff;
  margin: 5px 0;
  padding: 0 10px 10px 10px;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row.current-selection table,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row.current-selection table {
  margin-top: 0;
}
body.extranet .inventory-page .month-view .room-inventory-section .room-inventory-row .rate-plan-row.current-selection .margin-settings,
body.extranet .inventory-download .month-view .room-inventory-section .room-inventory-row .rate-plan-row.current-selection .margin-settings {
  display: block;
}
body.extranet .inventory-page .week-view .week-view-calendar table,
body.extranet .inventory-download .week-view .week-view-calendar table {
  border-spacing: 5px 0;
  border-collapse: separate;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row th,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row th,
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row td,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row td {
  border-top: 1px solid #ddd;
  vertical-align: top;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .label-section,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .label-section {
  min-width: 165px;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .restrictions-link span,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .restrictions-link span {
  cursor: pointer;
  color: #888;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .restrictions-link span:hover,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .restrictions-link span:hover {
  color: #666;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .icon-arrow-down,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .icon-arrow-down,
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .icon-arrow-up,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .icon-arrow-up {
  position: relative;
  top: 4px;
  font-size: 18px;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .title,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .title {
  line-height: 30px;
  margin: 7px 0;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-plan-name,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-plan-name {
  color: #666;
  text-decoration: underline;
  cursor: pointer;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .restrictions,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .restrictions {
  display: inline-block;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .restrictions .restrictions-section,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .restrictions .restrictions-section {
  padding-top: 18px;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell {
  padding-left: 0;
  padding-right: 0;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .rates-section,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .rates-section {
  padding: 6px;
  border: 1px solid #eee;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .restrictions-section,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .restrictions-section {
  border-top: 1px dashed #ccc;
  padding-top: 10px;
  margin-top: 20px;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell input,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell input {
  font-size: 12px;
  box-shadow: none;
  outline: none;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field {
  line-height: 30px;
  margin: 3px 0;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.sold-field,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.sold-field {
  margin: 7px 0;
  padding: 0 20px;
  position: relative;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.sold-field a,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.sold-field a {
  font-weight: bold;
  color: #333;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.blackout-field,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.blackout-field {
  font-size: 12px;
  padding-left: 6px;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.no-check-in-field,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.no-check-in-field {
  font-size: 12px;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.groupon-rate,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.groupon-rate,
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.net-rate,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.net-rate {
  border-color: #888;
}
body.extranet .inventory-page .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.min-allotment input,
body.extranet .inventory-download .week-view .week-view-calendar table .rate-plan-row .rate-cell .field.min-allotment input {
  text-align: right;
  width: 95px;
  margin-bottom: 0;
}
body.extranet .inventory-page .week-view .filter-section .show-week,
body.extranet .inventory-download .week-view .filter-section .show-week,
body.extranet .inventory-page .week-view .filter-section .this-week,
body.extranet .inventory-download .week-view .filter-section .this-week {
  float: left;
  margin-right: 20px;
  line-height: 30px;
}
body.extranet .inventory-page .week-view .filter-section .this-week,
body.extranet .inventory-download .week-view .filter-section .this-week {
  border-right: 1px solid #ccc;
  padding-right: 20px;
}
body.extranet .inventory-page .week-view .filter-section select,
body.extranet .inventory-download .week-view .filter-section select {
  width: 210px;
}
body.extranet .inventory-page .week-view .filter-section .date-range,
body.extranet .inventory-download .week-view .filter-section .date-range {
  float: left;
  margin-right: 20px;
  margin-left: 0;
}
body.extranet .inventory-page .week-view .filter-section .date-range .date-field,
body.extranet .inventory-download .week-view .filter-section .date-range .date-field {
  margin-right: 0;
  float: left;
}
body.extranet .inventory-page .week-view .filter-section .date-range .date-field input,
body.extranet .inventory-download .week-view .filter-section .date-range .date-field input {
  width: 200px;
}
body.extranet .inventory-page .week-view-calendar table,
body.extranet .inventory-download .week-view-calendar table {
  border-bottom: 2px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
}
body.extranet .inventory-page .week-view-calendar table th,
body.extranet .inventory-download .week-view-calendar table th,
body.extranet .inventory-page .week-view-calendar table td,
body.extranet .inventory-download .week-view-calendar table td {
  vertical-align: middle;
  padding: 0 5px 5px 5px;
  border: 0;
  color: #333;
  font-size: 13px;
}
body.extranet .inventory-page .week-view-calendar table th,
body.extranet .inventory-download .week-view-calendar table th {
  color: #666;
}
body.extranet .inventory-page .week-view-calendar table td span,
body.extranet .inventory-download .week-view-calendar table td span {
  color: #666;
}
body.extranet .inventory-page .week-view-calendar table td span em,
body.extranet .inventory-download .week-view-calendar table td span em {
  color: #999;
  font-style: normal;
}
body.extranet .inventory-page .week-view-calendar table .day-cell,
body.extranet .inventory-download .week-view-calendar table .day-cell {
  width: 110px;
  vertical-align: top;
  text-transform: uppercase;
}
body.extranet .inventory-page .week-view-calendar table tr.sold-row td,
body.extranet .inventory-download .week-view-calendar table tr.sold-row td {
  padding-top: 20px;
}
body.extranet .inventory-page .week-view-calendar table tr.btn-row,
body.extranet .inventory-download .week-view-calendar table tr.btn-row {
  border-bottom: 1px solid #eee;
}
body.extranet .inventory-page .week-view-calendar table tr.btn-row td,
body.extranet .inventory-download .week-view-calendar table tr.btn-row td {
  padding-bottom: 20px;
}
body.extranet .inventory-page .week-view-calendar table thead,
body.extranet .inventory-download .week-view-calendar table thead {
  background: none;
  border: 0;
}
body.extranet .inventory-page .week-view-calendar table thead th,
body.extranet .inventory-download .week-view-calendar table thead th {
  font-weight: 300;
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .inventory-page .week-view-calendar table thead th .head,
body.extranet .inventory-download .week-view-calendar table thead th .head {
  border-bottom: 3px solid #82b548;
  padding-bottom: 12px;
  margin: 5px;
}
body.extranet .inventory-page .week-view-calendar table thead th .day,
body.extranet .inventory-download .week-view-calendar table thead th .day {
  font-size: 13px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 15px;
}
body.extranet .inventory-page .week-view-calendar table thead th .month,
body.extranet .inventory-download .week-view-calendar table thead th .month {
  font-size: 13px;
  color: #666;
  margin-bottom: 5px;
}
body.extranet .inventory-page .week-view-calendar table thead th .date,
body.extranet .inventory-download .week-view-calendar table thead th .date {
  font-size: 30px;
  color: #666;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .inventory-page .week-view-calendar table tr:last-child td,
body.extranet .inventory-download .week-view-calendar table tr:last-child td {
  padding-bottom: 15px;
}
body.extranet .inventory-page .week-view-calendar table .text-pad,
body.extranet .inventory-download .week-view-calendar table .text-pad {
  padding-right: 20px;
}
body.extranet .inventory-page .week-view-calendar table .amount-field input,
body.extranet .inventory-download .week-view-calendar table .amount-field input,
body.extranet .inventory-page .week-view-calendar table .percentage-field input,
body.extranet .inventory-download .week-view-calendar table .percentage-field input {
  border: 0;
  width: 58px;
  margin: 0;
  padding: 0px;
  text-align: right;
}
body.extranet .inventory-page .week-view-calendar .week-header,
body.extranet .inventory-download .week-view-calendar .week-header,
body.extranet .inventory-page .week-view-calendar .week-view-inventory,
body.extranet .inventory-download .week-view-calendar .week-view-inventory {
  position: relative;
}
body.extranet .inventory-page .week-view-calendar .week-header .arrow-nav,
body.extranet .inventory-download .week-view-calendar .week-header .arrow-nav,
body.extranet .inventory-page .week-view-calendar .week-view-inventory .arrow-nav,
body.extranet .inventory-download .week-view-calendar .week-view-inventory .arrow-nav {
  position: absolute;
  top: 60px;
  color: #999;
}
body.extranet .inventory-page .week-view-calendar .week-header .arrow-nav:hover,
body.extranet .inventory-download .week-view-calendar .week-header .arrow-nav:hover,
body.extranet .inventory-page .week-view-calendar .week-view-inventory .arrow-nav:hover,
body.extranet .inventory-download .week-view-calendar .week-view-inventory .arrow-nav:hover {
  color: #666;
}
body.extranet .inventory-page .week-view-calendar .week-header .arrow-nav.previous,
body.extranet .inventory-download .week-view-calendar .week-header .arrow-nav.previous,
body.extranet .inventory-page .week-view-calendar .week-view-inventory .arrow-nav.previous,
body.extranet .inventory-download .week-view-calendar .week-view-inventory .arrow-nav.previous {
  left: 190px;
}
body.extranet .inventory-page .week-view-calendar .week-header .arrow-nav.next,
body.extranet .inventory-download .week-view-calendar .week-header .arrow-nav.next,
body.extranet .inventory-page .week-view-calendar .week-view-inventory .arrow-nav.next,
body.extranet .inventory-download .week-view-calendar .week-view-inventory .arrow-nav.next {
  right: -10px;
}
body.extranet .inventory-page .week-view-calendar .week-header .text-pad,
body.extranet .inventory-download .week-view-calendar .week-header .text-pad,
body.extranet .inventory-page .week-view-calendar .week-view-inventory .text-pad,
body.extranet .inventory-download .week-view-calendar .week-view-inventory .text-pad {
  font-size: 15px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .inventory-page .week-view-calendar .week-header .text-pad a,
body.extranet .inventory-download .week-view-calendar .week-header .text-pad a,
body.extranet .inventory-page .week-view-calendar .week-view-inventory .text-pad a,
body.extranet .inventory-download .week-view-calendar .week-view-inventory .text-pad a {
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .inventory-page .week-view-calendar .week-header table,
body.extranet .inventory-download .week-view-calendar .week-header table {
  border: 0;
}
body.extranet .inventory-page .week-view-calendar #inventory.loading,
body.extranet .inventory-download .week-view-calendar #inventory.loading {
  min-height: 150px;
  width: 100%;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center center;
}
body.extranet .inventory-page .week-view-calendar #inventory.loading *,
body.extranet .inventory-download .week-view-calendar #inventory.loading * {
  display: none;
}
body.extranet .inventory-page .room-inventory-table,
body.extranet .inventory-download .room-inventory-table {
  margin: 20px 0;
}
body.extranet .inventory-page .room-inventory-table table thead td h4,
body.extranet .inventory-download .room-inventory-table table thead td h4 {
  font-size: 16px;
}
body.extranet .inventory-page .room-inventory-table table tr td input,
body.extranet .inventory-download .room-inventory-table table tr td input {
  width: 100px;
}
body.extranet .inventory-page .room-inventory-table table tr td input.room-available,
body.extranet .inventory-download .room-inventory-table table tr td input.room-available {
  text-align: right;
  color: #333;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  outline: none;
  box-shadow: none;
}
body.extranet .inventory-page .room-inventory-table table tr td input.room-available:focus,
body.extranet .inventory-download .room-inventory-table table tr td input.room-available:focus {
  border: 1px solid #82b548;
  box-shadow: #82b548 0 0 2px;
}
body.extranet .inventory-page .room-inventory-table table tr.room-row td,
body.extranet .inventory-download .room-inventory-table table tr.room-row td {
  padding-bottom: 20px;
}
body.extranet .inventory-page .room-inventory-table table tr.btn-row td,
body.extranet .inventory-download .room-inventory-table table tr.btn-row td {
  text-align: center;
  height: 50px;
}
body.extranet .inventory-page .room-inventory-table table tr.btn-row td .icon-close,
body.extranet .inventory-download .room-inventory-table table tr.btn-row td .icon-close {
  color: #999;
  font-size: 12px;
}
body.extranet .inventory-page .room-inventory-table table tr .overlay-container,
body.extranet .inventory-download .room-inventory-table table tr .overlay-container {
  position: relative;
}
body.extranet .inventory-page .room-inventory-table table tr .blockout,
body.extranet .inventory-download .room-inventory-table table tr .blockout,
body.extranet .inventory-page .room-inventory-table table tr .rate-cloused-out,
body.extranet .inventory-download .room-inventory-table table tr .rate-cloused-out {
  background: #555;
  padding: 15px;
  color: #fff;
  font-size: 13px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .inventory-page .room-inventory-table table tr .rate-cloused-out,
body.extranet .inventory-download .room-inventory-table table tr .rate-cloused-out {
  background: #f2f2f2;
  color: #333;
}
body.extranet .inventory-page .room-inventory-table table tr .rate-cloused-out a,
body.extranet .inventory-download .room-inventory-table table tr .rate-cloused-out a {
  font-size: 12px;
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .rate-plan .date-field {
  border: 1px solid #ccc;
  display: inline-block;
  margin-right: 6px;
  height: 32px;
  padding: 0 8px;
}
body.extranet .rate-plan .date-field span.icon-calendar {
  font-size: 18px;
  position: relative;
  top: 3px;
  cursor: pointer;
}
body.extranet .rate-plan .date-field input {
  border: 0;
  box-shadow: none;
  width: 110px;
}
body.extranet .rate-plan .rateplan-left-column {
  border-right: 0;
}
body.extranet .rate-plan .rateplan-right-column {
  padding-left: 30px;
  border-left: 1px solid #ccc;
}
body.extranet .rate-plan .column-border {
  border-left: 1px solid #ccc;
}
body.extranet .rate-plan .group,
body.extranet .rate-plan .radio-buttons {
  margin-bottom: 30px;
}
body.extranet .rate-plan .radio-buttons li a {
  padding: 4px 14px;
}
body.extranet .rate-plan .blackout-dates {
  float: right;
  width: 300px;
}
body.extranet .rate-plan .blackout-date {
  display: inline-block;
  background: #666;
  height: 32px;
  line-height: 26px;
  color: #fff;
  padding: 3px 8px;
  margin: 0 5px 10px 0;
}
body.extranet .rate-plan .blackout-date span.date {
  margin-right: 30px;
  width: 120px;
  display: inline-block;
}
body.extranet .rate-plan .blackout-date a.icon-close {
  color: #aaa;
}
body.extranet #inventory-download {
  text-align: left;
}
body.extranet #inventory-download .modal-body {
  width: 500px;
  min-height: 250px;
}
body.extranet #inventory-download .modal-body .btn-section {
  margin-bottom: 0;
}
body.extranet .rateplan-preview .modal-body {
  width: 760px;
  overflow: auto;
}
body.extranet .rateplan-preview .preview-left {
  width: 175px;
  float: left;
  margin-right: 30px;
}
body.extranet .rateplan-preview .preview-left.sidenav {
  padding-right: 0;
}
body.extranet .rateplan-preview .preview-left.sidenav nav {
  margin-top: 30px;
  border-top: 1px solid #ccc;
}
body.extranet .rateplan-preview .preview-left.sidenav nav ul li a {
  font-size: 14px;
}
body.extranet .rateplan-preview .preview-left.sidenav nav ul li a span {
  font-size: 12px;
}
body.extranet .rateplan-preview .preview-left p {
  font-size: 14px;
  line-height: 16px;
  color: #999;
  margin: 0;
  padding: 0;
}
body.extranet .rateplan-preview .preview-left .modal-title {
  margin-bottom: 10px;
}
body.extranet .rateplan-preview .preview-right {
  float: left;
  width: 470px;
}
body.extranet .rateplan-preview .preview-right .box-content {
  width: 315px;
  padding: 15px;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: 18px;
  float: left;
}
body.extranet .rateplan-preview .preview-right .box-content h3 {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 300;
}
body.extranet .rateplan-preview .preview-right .box-content span {
  color: #76797d;
}
body.extranet .rateplan-preview .preview-right .preview-info {
  float: left;
  width: 130px;
  font-size: 13px;
  margin-left: 25px;
}
body.extranet .rateplan-preview .preview-right .preview-info .heading,
body.extranet .rateplan-preview .preview-right .preview-info .description {
  position: relative;
  min-height: 42px;
}
body.extranet .rateplan-preview .preview-right .preview-info .heading span.line,
body.extranet .rateplan-preview .preview-right .preview-info .description span.line {
  position: absolute;
  border-bottom: 1px solid #ccc;
  width: 15px;
  left: -25px;
  top: 20px;
}
body.extranet .rateplan-preview .preview-right .preview-info .heading {
  line-height: 40px;
}
body.extranet .rateplan-preview .preview-right .preview-info .description {
  line-height: 16px;
  padding-top: 12px;
}
body.extranet .rateplan-preview .preview-right .preview-info .description span {
  color: #76797d;
}
body.extranet .rateplan-preview .preview-right #fine-print .description {
  margin-top: 42px;
}
body.extranet .ratePlanPopup .modal-body {
  width: 1000px;
}
body.extranet .ratePlanPopup .modal-body .rate-plan .radio-buttons li a {
  padding: 4px 12px;
  font-weight: normal;
}
body.extranet .ratePlanPopup .modal-body .rate-plan .blackout-dates {
  width: 260px;
}
body.extranet .ratePlanPopup .modal-content.loading {
  min-height: 300px;
  width: 100%;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
body.extranet .ratePlanPopup .modal-content.loading * {
  display: none;
}
body.extranet #ql2-file-upload .modal-body {
  width: 500px;
  min-height: 150px;
}
body.extranet #ql2-file-upload .file-field #uploadFile {
  float: left;
  padding: 4px;
  width: 250px;
}
body.extranet #ql2-file-upload .file-field .file-upload {
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  cursor: pointer;
}
body.extranet #ql2-file-upload .file-field .file-upload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}
body.extranet .rates-filters {
  background: #f2f2f2;
  padding: 0;
  margin-bottom: 45px;
}
body.extranet .rates-filters .filter-section {
  float: left;
  width: 95%;
  margin: 0;
}
body.extranet .rates-filters .filter-section .text-label {
  margin-right: 8px;
}
body.extranet .rates-filters .filter-section .hasDatepicker {
  padding: 5px 0px 5px 8px;
}
body.extranet .rates-filters .ps-rules-section {
  float: left;
  height: 32px;
  border-left: 1px solid #ccc;
  padding: 10px 7px;
  width: 48px;
  line-height: 12px;
  position: relative;
  margin-top: 7px;
}
body.extranet .rates-filters .ps-rules-section .rules-popup {
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 10px 5px rgba(205,205,205,0.75);
  position: absolute;
  bottom: 40px;
  right: 0px;
}
body.extranet .rates-filters .ps-rules-section .rules-popup table {
  margin: 0 10px 0 0;
}
body.extranet .rates-filters .ps-rules-section .rules-popup table th {
  color: #999;
  font-weight: normal;
  vertical-align: bottom;
  font-size: 13px;
  padding: 0 10px;
  min-width: 80px;
  line-height: 14px;
  text-align: center;
}
body.extranet .rates-filters .ps-rules-section .rules-popup table td {
  color: #333;
  font-weight: bold;
  font-size: 18px;
  border: 0;
  text-align: center;
}
body.extranet .rates-filters .ps-rules-section .rules-popup table td span {
  font-size: 14px;
  font-weight: normal;
}
body.extranet .rates-filters .ps-rules-section .rules-popup #rules-close-btn {
  float: right;
  color: #999;
  font-size: 18px;
  font-weight: bold;
}
body.extranet .rates-filters .ps-rules-section .rules-popup .indicator {
  display: block;
  position: absolute;
  width: 20px;
  height: 10px;
  bottom: 1px;
  right: 10px;
}
body.extranet .rates-filters .ps-rules-section .rules-popup .indicator:after {
  top: 100%;
  right: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255,255,255,0);
  border-top-color: #fff;
  border-width: 15px 10px 10px;
  margin-left: -15px;
}
body.extranet .rates-filters .rateplan-filters {
  border-top: 1px solid #ddd;
  padding: 7px 0px;
  margin: 0px 10px;
  font-size: 14px;
}
body.extranet .rates-filters .rateplan-filters span.text-label {
  margin-right: 8px;
}
body.extranet .rates-filters .rateplan-filters select {
  margin-bottom: 0px;
}
body.extranet .alert.rates-error {
  position: relative;
  top: -20px;
}
body.extranet .alert.rates-error a.text-close {
  color: #0185c6;
  outline: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
body.extranet .partial-blackout {
  position: relative;
  opacity: 0;
}
body.extranet .partial-blackout:before {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #333 transparent transparent transparent;
  left: -4px;
  top: -8px;
}
body.extranet td.show-partial-blackout .partial-blackout {
  opacity: 1;
}
body.extranet .validation-msgs {
  max-height: 140px;
  overflow: auto;
}
body.extranet .validation-msgs ul {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
}
body.extranet .validation-msgs ul li {
  list-style: none;
  padding: 0;
  margin: 0 0 1px 0;
}
body.extranet .validation-msgs ul li ul {
  padding-left: 10px;
}
body.extranet #validation-messages {
  border: 1px solid #ed4e40;
  border-right: none;
  border-left: none;
  border-radius: 0px;
  padding: 10px 0;
  color: #ed4e40;
  margin-bottom: 30px;
}
body.extranet #validation-messages .message {
  font-size: 13px;
  font-weight: normal;
}
body.extranet #validation-messages .message:before {
  padding-right: 5px;
}
body.extranet #validation-messages .review-violations-link {
  float: right;
  cursor: pointer;
}
body.extranet #validation-messages .review-violations-link i {
  vertical-align: middle;
}
body.extranet #validation-messages .violation-details {
  width: 100%;
}
body.extranet #validation-messages .violation-details .action-buttons {
  border-top: 1px solid #ffbaba;
  padding: 10px 0 0 0;
  text-align: right;
}
body.extranet #validation-messages .message-list {
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
  font-size: 12px;
}
body.extranet #validation-messages .message-list ul {
  list-style: none;
  margin-bottom: 10px;
}
body.extranet #validation-messages .message-list .violation-header {
  cursor: pointer;
}
body.extranet #validation-messages .modal .modal-body {
  width: 400px;
  min-height: 200px;
}
body.extranet #validation-messages .modal .modal-body img {
  width: 30px;
}
body.extranet #validation-messages .modal .modal-body .icon-alert {
  color: #666;
  font-size: 30px;
  margin-bottom: 20px;
}
body.extranet #validation-messages .modal .modal-body .confirm-message {
  font-size: 13px;
  color: #666;
}
body.extranet #validation-messages .modal .modal-body .action-info {
  margin-top: 20px;
  font-size: 12px;
  color: #aaa;
}
body.extranet #validation-messages .modal .modal-body button {
  margin-bottom: 0px;
}
body.extranet .action-buttons {
  margin-top: 20px;
}
body.extranet .action-buttons button {
  padding: 6px 30px;
  font-size: 13px;
  background-image: none;
  background-color: #fff;
  margin: 5px 20px 5px 0;
  outline: none;
  box-shadow: none;
}
body.extranet .action-buttons button:hover {
  background-color: #eee;
}
body.extranet .action-buttons button.btn-grey {
  background-color: #eaeaea;
  border-color: #aaa;
}
body.extranet .action-buttons button.btn-grey:hover {
  background-color: #e0e0e0;
}
body.extranet .action-buttons button:last-child {
  margin-right: 0px;
}
body.extranet .advanced_search {
  margin: 0;
}
body.extranet .advanced_search #upload-rates {
  margin: 30px 0;
}
body.extranet .advanced_search #upload-rates .buttons-container button.btn-primary {
  padding: 6px 10px;
  outline: none;
  font-weight: bold;
  margin-right: 5px;
}
body.extranet .advanced_search .create-hotel-link {
  margin-bottom: 30px;
}
body.extranet .advanced_search .create-hotel-link .link {
  font-size: 16px;
}
body.extranet .advanced_search .create-hotel-link .link:before {
  vertical-align: middle;
  font-size: 25px;
  line-height: 16px;
}
body.extranet .advanced_search .create-hotel-link .description {
  color: #888;
  padding-left: 25px;
}
body.extranet .advanced_search #no_search_result {
  border: 1px solid #ddd;
  border-right: none;
  border-left: none;
  padding: 25px 0;
  text-align: center;
}
body.extranet .advanced_search .tabs-container2 {
  margin-bottom: 0;
  border-bottom: 1px solid #e5e5e5;
}
body.extranet .advanced_search .tabs-container2 ul.tabs2 li {
  margin-right: 5px;
  border: 1px solid #e5e5e5;
  background: none;
  background-image: none;
}
body.extranet .advanced_search .tabs-container2 ul.tabs2 li a {
  background: none;
  border: none;
  background-image: none;
  color: #1897d4;
  padding-top: 4px;
  font-size: 14px;
}
body.extranet .advanced_search .tabs-container2 ul.tabs2 li.active {
  background-color: #f2f2f2;
  border-bottom: none;
}
body.extranet .advanced_search .tabs-container2 ul.tabs2 li.active a {
  color: #666;
}
body.extranet .advanced_search #hotel_search_form,
body.extranet .advanced_search #promotions_search_form {
  background-color: #f2f2f2;
  padding: 10px;
}
body.extranet .advanced_search #hotel_search_form .checkbox,
body.extranet .advanced_search #promotions_search_form .checkbox {
  margin: 35px 0 0 0;
  padding-left: 10px;
}
body.extranet .advanced_search #hotel_search_form .date-range,
body.extranet .advanced_search #promotions_search_form .date-range {
  margin: 0;
}
body.extranet .advanced_search #hotel_search_form .date-range .date-field,
body.extranet .advanced_search #promotions_search_form .date-range .date-field {
  margin-right: 0;
}
body.extranet .advanced_search #hotel_search_form .date-range .date-field input,
body.extranet .advanced_search #promotions_search_form .date-range .date-field input {
  width: 100px;
}
body.extranet .advanced_search #hotel_search_form .date-range .dash,
body.extranet .advanced_search #promotions_search_form .date-range .dash {
  margin: 0 5px;
}
body.extranet .advanced_search #hotel_search_form .hotel-search-btn,
body.extranet .advanced_search #promotions_search_form .hotel-search-btn {
  margin-top: 0;
  margin-left: 15px;
  padding: 6px 20px;
  text-transform: uppercase;
}
body.extranet .advanced_search ul.ui-tabs-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
body.extranet .advanced_search ul.ui-tabs-nav li {
  float: left;
  height: 34px;
  border: #e3e4e4 solid 1px;
  border-bottom: 0;
  margin-right: 5px;
}
body.extranet .advanced_search ul.ui-tabs-nav li a {
  padding: 10px 20px;
  line-height: 34px;
}
body.extranet .advanced_search ul.ui-tabs-nav li.ui-tabs-active {
  background-color: #f2f2f2;
}
body.extranet .advanced_search ul.ui-tabs-nav li.ui-tabs-active a {
  color: #333;
}
body.extranet .promotions_list_view span.role-text {
  color: #b3b3b3;
}
body.extranet #connection-status-icon-wrapper img {
  height: 22px;
  margin-bottom: -1px;
}
body.extranet #connection-status-icon-wrapper.active #disconnected-status-icon {
  display: none;
}
body.extranet #connection-status-icon-wrapper:not(.active) #connected-status-icon {
  display: none;
}
body.extranet.new-hotel .page-header {
  margin-bottom: 25px;
  border-bottom: 1px solid #ccc;
}
body.extranet.new-hotel .create-hotel-form {
  margin-left: 75px;
}
body.extranet.new-hotel input,
body.extranet.new-hotel textarea,
body.extranet.new-hotel select {
  outline: none;
}
body.extranet.new-hotel input:focus,
body.extranet.new-hotel textarea:focus,
body.extranet.new-hotel select:focus {
  border: 1px solid #82b548;
  box-shadow: #82b548 0 0 2px;
}
body.extranet.new-hotel input.error:focus,
body.extranet.new-hotel textarea.error:focus,
body.extranet.new-hotel select.error:focus {
  border: 1px solid #cc2127;
  box-shadow: #ff5157 0 0 2px;
}
body.extranet.new-hotel textarea#hotel-description {
  resize: both;
}
body.extranet.new-hotel .state-not-applicable {
  padding: 5px 0px;
  color: #aaa;
}
body.extranet.new-hotel .btn-section {
  margin: 50px 0;
}
body.extranet.new-hotel .btn-section button {
  font-size: 15px;
}
body.extranet.new-hotel section {
  margin-top: 30px;
}
body.extranet.new-hotel section h4,
body.extranet.new-hotel section h5 {
  line-height: 45px;
}
body.extranet.new-hotel section.basic-information h4 small {
  margin-left: 5px;
}
body.extranet.new-hotel section label.required:before,
body.extranet.new-hotel section small.required:before {
  content: "*";
  margin-right: 3px;
}
body.extranet.new-hotel section label.checkbox {
  margin: 15px 0;
}
body.extranet.new-hotel .success-message-popup {
  position: fixed;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  background-color: rgba(0,0,0,0.4);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
body.extranet.new-hotel .success-message-popup.active {
  visibility: visible;
  opacity: 1;
}
body.extranet.new-hotel .success-message-popup .content {
  padding: 30px;
  width: 350px;
  min-height: 150px;
  border: 1px solid #888;
  background-color: #fff;
  position: relative;
  margin: 200px auto 20px auto;
  border-radius: 0;
  box-shadow: none;
}
body.extranet.new-hotel .success-message-popup .content .message {
  font-size: 14px;
}
body.extranet.new-hotel .success-message-popup .content .message .hotel-name {
  font-weight: bold;
  font-size: 15px;
}
body.extranet.new-hotel .success-message-popup .content .message .request-id {
  color: #bbb;
  font-size: 10px;
}
body.extranet.new-hotel .success-message-popup .content .icon-check-circle {
  color: #82b548;
  vertical-align: middle;
  font-size: 20px;
}
body.extranet.new-hotel .success-message-popup .content .btn-section {
  margin-bottom: 0;
  text-align: center;
}
body.extranet.new-hotel .success-message-popup .content .btn-section .btn {
  margin-bottom: 0;
  font-size: 13px;
  padding: 5px 10px;
}
body.extranet.home-page .hotel-row {
  margin-bottom: 20px;
  background: #f2f2f2;
  width: 100%;
  float: left;
}
body.extranet.home-page .hotel-row img.photo {
  width: 180px;
  float: left;
}
body.extranet.home-page .hotel-row img.photo[src=''] {
  visibility: hidden;
}
body.extranet.home-page .hotel-row .hotel-details {
  float: left;
  padding: 10px;
  width: 800px;
}
body.extranet.home-page .hotel-row .hotel-details .address-info {
  width: 390px;
  margin-right: 10px;
  float: left;
}
body.extranet.home-page .hotel-row .hotel-details .address-info h3 {
  font-size: 18px;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 10px;
}
body.extranet.home-page .hotel-row .hotel-details .address-info p {
  margin-bottom: 0;
  line-height: 18px;
  padding-left: 20px;
  position: relative;
}
body.extranet.home-page .hotel-row .hotel-details .address-info p.icon-marker:before {
  color: #666;
  position: absolute;
  left: 0;
  top: 3px;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info {
  border-left: solid 1px #ddd;
  float: left;
  width: 300px;
  min-height: 90px;
  padding-left: 15px;
  margin-left: 10px;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info h4 {
  font-size: 14px;
  margin-bottom: 15px;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .inventory {
  width: 80px;
  float: left;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .inventory h5 {
  font-size: 13px;
  color: #999;
  margin-bottom: 10px;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .inventory a {
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .bookings {
  width: 80px;
  float: left;
  margin: 0 20px;
  text-align: center;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .bookings a {
  font-size: 30px;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .available {
  width: 80px;
  float: left;
  text-align: center;
}
body.extranet.home-page .hotel-row .hotel-details .inventory-info .available a {
  font-size: 30px;
}
body.extranet.home-page .hotel-row .hotel-details .profile {
  float: left;
  width: 65px;
  text-align: center;
}
body.extranet .advanced_search #upload-rates .modal {
  background: rgba(100,100,100,0.3);
}
body.extranet .advanced_search #upload-rates .modal .modal-body {
  min-height: 210px;
  max-height: none;
  min-width: 410px;
  max-width: 550px;
  padding: 20px;
  box-shadow: 1px 1px 20px #666;
  border: none;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .close-modal {
  display: none;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .notification {
  font-size: 18px;
  color: #656565;
  margin-bottom: 20px;
  line-height: 25px;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .notification.icon-check-circle:before {
  color: #88b556;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .notification.icon-close:before {
  color: #ff2400;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .notification:before {
  font-size: 28px;
  top: 7px;
  position: relative;
  padding-right: 5px;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .selected-file {
  color: #88b556;
  font-size: 18px;
  margin-bottom: 20px;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .message {
  background: #f1f1f1;
  color: #787878;
  margin-bottom: 20px;
  padding: 10px 30px;
  min-height: 41px;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .buttons .btn {
  margin-right: 5px;
  outline: none;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .file-upload {
  position: relative;
}
body.extranet .advanced_search #upload-rates .modal .modal-body .file-upload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}
body.extranet #rates-inventory-upload #upload-rates-inventory-modal .modal-body {
  width: 600px;
}
body.extranet #rates-inventory-upload #upload-rates-inventory-modal .confirm-message {
  font-size: 20px;
  margin-bottom: 20px;
}
body.extranet.administration .tax-list {
  margin-top: 30px;
}
body.extranet.administration .tax-list .select-taxes-table {
  padding: 15px;
  min-height: 150px;
}
body.extranet.administration .tax-list .select-taxes-table.loading {
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center center;
}
body.extranet.administration .tax-list .select-taxes-table table {
  background: none;
}
body.extranet.administration .tax-list .select-taxes-table .btn-section {
  margin-bottom: 0;
}
body.extranet.administration .tax-list h4 {
  font-size: 13px;
  margin-bottom: 10px;
}
body.extranet.administration .tax-list .tax-container .location-filters {
  margin-bottom: 10px;
  padding: 5px 15px;
  background-color: #f4f4f5;
}
body.extranet.administration .tax-list .tax-container .location-filters .btn-secondary {
  padding: 5px 15px;
}
body.extranet.administration .tax-list .tax-container .location-filters .icon-magnifying-glass:before {
  font-size: 13px;
  margin-right: 5px;
}
body.extranet.administration .roles-tooltip {
  position: relative;
  display: inline-block;
}
body.extranet.administration .roles-tooltip a.icon-question {
  color: #999;
  font-size: 14px;
}
body.extranet.administration .roles-tooltip .roles-description {
  position: absolute;
  bottom: 25px;
  left: -10px;
  background: #fff;
  border: #ccc solid 2px;
  padding: 10px;
  width: 230px;
  font-size: 14px;
  border-radius: 3px;
}
body.extranet.administration .roles-tooltip .roles-description ul li {
  width: 100%;
}
body.extranet.administration .roles-tooltip .roles-description ul li span {
  width: 40px;
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}
body.extranet.administration #ps_results.loading {
  min-height: 750px;
  width: 100%;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
body.extranet.administration #ps_results.loading * {
  visibility: hidden;
}
body.extranet.administration .btn-section button {
  text-transform: uppercase;
}
body.extranet .field-readonly {
  min-height: 30px;
  line-height: 20px;
  padding: 5px 8px;
  background: #f5f5f5;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
body.extranet .home-link {
  margin-bottom: 15px;
}
body.extranet .home-link .icon-home {
  border: 2px solid #aaa;
  padding: 5px 7px 2px 8px;
  border-radius: 50%;
  font-size: 20px;
  color: #aaa;
}
body.extranet .home-link .icon-home:hover {
  color: #82b548;
  border-color: #82b548;
}
body.extranet select.loading,
body.extranet input.loading,
body.extranet span.loading {
  background: url("../../public/getaways-xnet/assets/common-dropdown-spinner.gif") no-repeat right 5px center;
  min-height: 20px;
}
body.extranet #hotel-selector {
  font-size: 20px;
}
body.extranet .alert .request-id {
  color: #aaa;
  font-size: 10px;
}
body.extranet .capitalize {
  text-transform: capitalize;
}
body.extranet .clear {
  clear: both;
}
body.extranet .input-field {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  margin-bottom: 10px;
}
body.extranet .input-field.disabled {
  background-color: #eaeaea;
}
body.extranet .input-field span {
  margin-left: -5px;
}
body.extranet .input-field span.degree-symbol {
  font-size: 18px;
}
body.extranet .input-field span.currency-symbol {
  margin-left: 5px;
}
body.extranet .input-field.focus {
  border: 1px solid #82b548;
  box-shadow: #82b548 0 0 2px;
}
body.extranet .input-field.focus span {
  color: #82b548;
}
body.extranet .input-field.error {
  border: 1px solid #cc2127;
}
body.extranet .input-field.error.focus {
  box-shadow: #ff5157 0 0 2px;
}
body.extranet .input-field.error span {
  color: #cc2127;
}
body.extranet .input-field input {
  border: none;
  box-shadow: none;
  margin-bottom: 0px;
  height: 28px;
}
body.extranet .input-field input:focus,
body.extranet .input-field input .error:focus {
  border: none;
  box-shadow: none;
}
body.extranet .modal.confirmation-popup .modal-body {
  width: 400px;
  min-height: 200px;
}
body.extranet .modal.confirmation-popup .modal-body .confirm-message {
  font-size: 13px;
  color: #666;
}
body.extranet .modal.confirmation-popup .modal-body .action-info {
  margin-top: 20px;
  font-size: 12px;
  color: #aaa;
}
body.extranet .modal.confirmation-popup .modal-body .close-modal.hide {
  display: none;
}
body.extranet .action-buttons {
  margin-top: 20px;
}
body.extranet .action-buttons button {
  padding: 6px 20px;
  min-width: 100px;
  font-size: 13px;
  background-image: none;
  background-color: #fff;
  margin: 5px 20px 5px 0;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  border: 1px solid #ccc;
}
body.extranet .action-buttons button:hover {
  background-color: #f8f8f8;
}
body.extranet .action-buttons button.btn-grey {
  background-color: #eaeaea;
  border-color: #ccc;
}
body.extranet .action-buttons button.btn-green {
  background-color: #82b548;
  color: #fff;
  border-color: #82b548;
}
body.extranet .action-buttons button.btn-green:hover {
  background-color: #72a538;
}
body.extranet .action-buttons button.btn-blue {
  background-color: #43b2ce;
  color: #fff;
  border-color: #43b2ce;
}
body.extranet .action-buttons button.btn-blue:hover {
  background-color: #30a4c1;
}
body.extranet .action-buttons button.btn-green-inverse {
  color: #82b548;
  border-color: #82b548;
}
body.extranet .action-buttons button.btn-green-inverse:hover {
  color: #fff;
  background-color: #72a538;
}
body.extranet .action-buttons button:last-child {
  margin-right: 0px;
}
body.extranet input,
body.extranet textarea,
body.extranet select {
  outline: none;
}
body.extranet input:focus,
body.extranet textarea:focus,
body.extranet select:focus {
  border: 1px solid #82b548;
  box-shadow: #82b548 0 0 2px;
}
body.extranet input.error:focus,
body.extranet textarea.error:focus,
body.extranet select.error:focus {
  border: 1px solid #cc2127;
  box-shadow: #ff5157 0 0 2px;
}
body.extranet .btn-section button {
  text-transform: uppercase;
  outline: none;
}
body.extranet .btn-section button:last-child {
  margin-right: 0px;
}
body.extranet #page-nav .unassigned-rate-description-count {
  color: #c3f;
  font-weight: bold;
  padding: 2px 3px;
  line-height: 13px;
  display: inline-block;
  border-radius: 50%;
  font-size: 10px;
  border: 1px solid #c3f;
  min-width: 20px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 6px;
  margin-right: 3px;
}
body.extranet #page-nav .unassigned-rate-description-count.hide {
  display: none;
}
body.extranet .state-not-applicable {
  padding: 5px 0px;
  color: #aaa;
}
body.extranet .alert .message {
  padding-left: 18px;
}
body.extranet .alert .message:before {
  margin-left: -18px;
}
body.extranet table {
  width: 100%;
}
body.extranet table th {
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet table.table-striped thead {
  background: none;
}
body.extranet table.table-striped td,
body.extranet table.table-striped th {
  padding: 10px;
}
body.extranet table.table-striped td {
  border-top: none;
}
body.extranet table.table-striped td div.secondary {
  opacity: 0.6;
}
body.extranet table.table-striped td .icon-check {
  color: #82b548;
}
body.extranet table.table-striped td .icon-x {
  color: #cc2127;
}
body.extranet .visual-print {
  display: none !important;
}
@media print {
  body.extranet .hidden-print {
    display: none !important;
  }
  body.extranet .visual-print {
    display: block !important;
  }
}
.stacktrace {
  list-style: none inside none;
  padding: 20px;
}
.cat-error {
  overflow: hidden;
}
.cat-error .error-body {
  margin: 80px auto;
}
.cat-error .error-body h1 {
  font-family: OpenSansLight, 'Helvetica Neue', Arial, Helvetica, FreeSans, sans-serif;
  font-size: 36px;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 300;
}
.cat-error .error-body h1 .exclamation {
  color: #82b548;
}
.cat-error .error-body h5 {
  font-weight: 300;
  font-size: 16px;
  font-family: OpenSansRegular, 'Helvetica Neue', Helvetica, Arial, FreeSans, sans-serif;
}
body.extranet.error .alert.error {
  margin-top: 50px;
  border-radius: 0;
  border-width: 2px;
}
body.extranet.error .alert.error .request-id {
  padding-left: 0;
}
body.extranet.error .alert.error .message-header {
  padding: 0 0 20px 0;
}
body.extranet.error .alert.error .message-footer {
  padding: 20px 0 0 0;
}
body.extranet .owners-dropdown {
  text-align: right;
  position: relative;
}
body.extranet .owners-dropdown a.owners-trigger,
body.extranet .owners-dropdown a.owners-hidden {
  color: #0185c6;
  font-family: OpenSansLight, 'Helvetica Neue', Arial, Helvetica, FreeSans, sans-serif;
  font-size: 13px;
  color: #4bc1de;
}
body.extranet .owners-dropdown a.owners-trigger .icon-arrow-up:before,
body.extranet .owners-dropdown a.owners-hidden .icon-arrow-up:before,
body.extranet .owners-dropdown a.owners-trigger .icon-arrow-down:before,
body.extranet .owners-dropdown a.owners-hidden .icon-arrow-down:before {
  font-size: 14px;
  color: #4bc1de;
}
body.extranet .owners-dropdown a.owners-hidden {
  color: #888;
}
body.extranet .owners-dropdown a.owners-hidden .icon-arrow-down:before {
  color: #888;
}
body.extranet .owners-dropdown .owners-table {
  margin: 5px 0 10px 0;
  border: 1px solid #29abe2;
  padding: 15px 20px 15px 10px;
  position: absolute;
  right: 10px;
  top: 20px;
  background: #fff;
  z-index: 1;
}
body.extranet .owners-dropdown .owners-table table {
  border: 0;
  width: 100%;
  margin: 0;
}
body.extranet .owners-dropdown .owners-table table td {
  color: #999;
  padding: 5px 10px;
  text-align: left;
  border: 0;
  font-family: OpenSansLight, 'Helvetica Neue', Arial, Helvetica, FreeSans, sans-serif;
  font-size: 13px;
  line-height: 13px;
}
body.extranet .owners-dropdown .owners-table table td.owner-name {
  color: #4d4d4d;
}
body.extranet .owners-dropdown .owners-table table td.roles {
  text-align: right;
  width: 135px;
  white-space: nowrap;
}
body.extranet .owners-dropdown .owners-table table td.action-btns {
  padding: 0;
}
body.extranet .owners-dropdown .owners-table table td a.icon-close,
body.extranet .owners-dropdown .owners-table table td a.icon-plus {
  color: #e6e6e6;
  font-size: 18px;
}
body.extranet .owners-dropdown .owners-table table td a.icon-close {
  float: right;
}
body.extranet .owners-dropdown .owners-table table td input {
  height: 20px;
  padding: 3px 5px;
  min-width: 150px;
  width: 100%;
  line-height: 0px;
  font-size: 12px;
  margin-bottom: 0;
}
body.extranet .owners-dropdown .owners-table table td.remove-owner-icon {
  text-align: right;
}
body.extranet #admin-remove-modal .alert-warning {
  max-width: 470px;
  min-height: 190px;
  padding: 20px;
  font-family: OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet #admin-remove-modal .alert-warning .alert.info {
  text-align: left;
  background-color: #fffae3;
}
body.extranet #admin-remove-modal .alert-warning .alert.info:before {
  font-size: 20px;
  color: #ed1c24;
}
body.extranet #admin-remove-modal .alert-warning .delete-info {
  text-align: left;
  color: #b3b3b3;
}
body.extranet #admin-remove-modal .alert-warning .delete-info strong {
  color: #333;
}
body.extranet #admin-remove-modal .alert-warning .btn-section {
  margin-bottom: 0;
}
body.extranet .booking-deals .page-title {
  margin-bottom: 30px;
}
body.extranet .booking-deals .table-style {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
body.extranet .booking-deals .table-style table {
  border-bottom: 0;
}
body.extranet .booking-deals .table-style tr th {
  padding: 10px;
}
body.extranet .booking-deals .table-style tr td {
  padding: 10px;
}
body.extranet .booking-deals .table-style tr td a.notes-link {
  font-size: 13px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 2px 15px;
  border-radius: 12px;
}
body.extranet .booking-deals .table-style tr.hightlight td {
  background: #f2f2f2;
}
body.extranet .deal-name h2 {
  margin-bottom: 10px;
}
body.extranet .deal-name h2.deal-title a {
  color: #888;
}
body.extranet .deal-name input {
  padding: 10px;
  height: 42px;
  font-size: 20px;
  line-height: 30px;
  font-family: OpenSansLight, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  color: #888;
}
body.extranet .deal-name input .modal-body {
  width: 360px;
  min-height: 190px;
  padding: 20px;
  text-align: left;
}
body.extranet .deal-name input .modal-body .alert.info {
  width: 325px;
  padding: 7px 7px 7px 35px;
  line-height: 16px;
  margin-bottom: 20px;
}
body.extranet .deal-name input .modal-body .delete-info {
  line-height: 16px;
}
body.extranet .deal-name input .modal-body .btn-section {
  margin-bottom: 0;
}
body.extranet .deal-name input .modal-body .btn-section button {
  margin-bottom: 0;
}
body.extranet .new-booking-deals {
  margin-bottom: 10px;
}
body.extranet .new-booking-deals h2.deal-title a {
  color: #888;
}
body.extranet .new-booking-deals input.medium {
  width: 145px;
}
body.extranet .new-booking-deals input.small {
  width: 120px;
}
body.extranet .new-booking-deals input.tiny {
  width: 90px;
}
body.extranet .new-booking-deals .percentage-field {
  margin: 0;
  width: 120px;
  height: 30px;
}
body.extranet .new-booking-deals .percentage-field input {
  height: 28px;
}
body.extranet .new-booking-deals .deal-information {
  background: #f2f2f2;
  height: 60px;
  padding: 20px 10px 10px 10px;
  margin-top: 10px;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text {
  padding: 10px;
  height: auto;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text .deal-info .title {
  width: 30%;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text .deal-info .info {
  width: 68%;
  display: inline-block;
  vertical-align: top;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text .action-buttons i {
  color: #888;
  cursor: pointer;
  font-size: 15px;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text .action-buttons i:hover {
  color: #333;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text #unlink-deal-modal .modal-body {
  width: 350px;
  padding: 15px;
  min-height: 160px;
}
body.extranet .new-booking-deals .deal-information.deal-uuid-text #unlink-deal-modal .modal-body .btn-section,
body.extranet .new-booking-deals .deal-information.deal-uuid-text #unlink-deal-modal .modal-body button {
  margin-bottom: 0;
}
body.extranet .modal .modal-body {
  width: 400px;
  min-height: 200px;
}
body.extranet .modal .modal-body .icon-alert {
  color: #666;
  font-size: 30px;
  margin-bottom: 20px;
}
body.extranet .new-deal-inventory {
  margin-bottom: 30px;
  overflow: hidden;
}
body.extranet .new-deal-inventory h2.page-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: normal;
}
body.extranet .new-deal-inventory .left-column {
  width: 325px;
  padding-left: 0;
  padding-top: 10px;
  padding-right: 40px;
  border-right: 1px solid #ddd;
}
body.extranet .new-deal-inventory .left-column div.row {
  margin-bottom: 10px;
}
body.extranet .new-deal-inventory .left-column label {
  width: 100%;
  text-align: right;
  height: 30px;
  line-height: 16px;
  display: table;
  margin: 0;
}
body.extranet .new-deal-inventory .left-column label span {
  display: table-cell;
  vertical-align: middle;
  height: 30px;
}
body.extranet .new-deal-inventory .right-column .row:first-child {
  margin-bottom: 15px;
}
body.extranet .new-deal-inventory .label-bottom {
  padding-top: 32px;
  padding-left: 30px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
}
body.extranet .new-deal-inventory .percentage-field {
  width: 130px;
  margin: 0;
}
body.extranet .new-deal-inventory .percentage-field input {
  width: 100px;
}
body.extranet .new-deal-inventory .blackout-days {
  padding-right: 0;
}
body.extranet .new-deal-inventory .blackout-days ul {
  margin: 0;
  padding: 0;
}
body.extranet .new-deal-inventory .blackout-days ul li {
  width: 40px;
  list-style: none;
  float: left;
  margin: 0 5px 0 0;
}
body.extranet .new-deal-inventory .blackout-days ul li span {
  display: block;
  text-align: center;
}
body.extranet .new-deal-inventory .blackout-days ul li input {
  width: 40px;
  margin-bottom: 0;
}
body.extranet .new-deal-inventory .last-column {
  border-left: 1px solid #ddd;
  margin-left: 20px;
  padding-left: 20px;
}
body.extranet .create-rate-plan {
  margin-bottom: 30px;
  overflow: hidden;
}
body.extranet .create-rate-plan .left-column {
  padding-right: 30px;
  width: 450px;
}
body.extranet .create-rate-plan .right-column {
  border-left: 1px solid #ddd;
  padding-left: 30px;
  width: 550px;
  min-height: 420px;
}
body.extranet .create-rate-plan .percentage-field {
  width: 120px;
  margin: 0;
}
body.extranet .create-rate-plan .percentage-field input {
  width: 90px;
}
body.extranet .create-rate-plan .length-stay {
  padding-left: 0;
}
body.extranet .create-rate-plan .length-stay select {
  width: 120px;
}
body.extranet .create-rate-plan .blackout-days {
  padding-right: 0;
}
body.extranet .create-rate-plan .blackout-days ul {
  margin: 0;
  padding: 0;
}
body.extranet .create-rate-plan .blackout-days ul li {
  width: 40px;
  list-style: none;
  float: left;
  margin: 0 5px 0 0;
}
body.extranet .create-rate-plan .blackout-days ul li span {
  display: block;
  text-align: center;
}
body.extranet .create-rate-plan .blackout-days ul li input {
  width: 40px;
  margin-bottom: 0;
}
body.extranet .create-rate-plan .rate-plan .blackout-dates {
  width: 340px;
}
body.extranet .rate-plan-table {
  background: #f2f2f2;
  padding: 20px;
}
body.extranet .rate-plan-table table {
  background: none;
}
body.extranet .rate-plan-table table tr {
  display: table-row;
}
body.extranet .tabs-container2 .tabs2.left-align {
  width: auto;
}
body.extranet .tabs-container2 .tabs2.left-align.bd-tabs {
  width: 99%;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container {
  float: right;
  color: #666;
  font-size: 15px;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title {
  font-weight: bold;
  padding-right: 5px;
  text-transform: uppercase;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title .icon-previous {
  display: none;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title .icon-next {
  display: none;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title.expandable {
  cursor: pointer;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title.expandable:hover {
  color: #333;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title.expandable .icon-previous {
  display: inline-block;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title.expandable.expanded .icon-previous {
  display: none;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .title.expandable.expanded .icon-next {
  display: inline-block;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container > span {
  display: table-cell;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container #status-rollback-confirmation .icon-alert {
  color: #666;
  font-size: 30px;
  margin-bottom: 20px;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container #status-rollback-confirmation .product-set-status {
  font-weight: bold;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses {
  overflow: hidden;
  height: 20px;
  word-break: break-all;
  color: #888;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses.hide {
  display: none;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name {
  cursor: pointer;
  text-transform: capitalize;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name.disabled {
  cursor: not-allowed;
  color: #aaa;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.created {
  color: #306099;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.shop-rates {
  color: #d2bd84;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.ready {
  color: #e350e0;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.review-violations {
  color: #f50;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.approved {
  color: #f3a117;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.live {
  color: #88b556;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.status-name:not(.disabled):hover.finished {
  color: #656565;
}
body.extranet .tabs-container2 .tabs2.left-align li.product-set-status-rollback-container .prev-statuses span.right-arrow:after {
  content: "\2794";
  padding: 0 5px;
}
body.extranet .product-set-status-buttons-container {
  text-align: right;
}
body.extranet .vrdivider {
  display: inline;
  border-left: 1px solid #ddd;
  width: 1px;
  height: 34px;
  margin-right: 10px;
}
body.extranet .btn-divider {
  position: relative;
  margin-left: 10px;
}
body.extranet .btn-divider:before {
  content: "";
  display: inline;
  border-left: 1px solid #ddd;
  width: 1px;
  height: 34px;
  position: absolute;
  left: -10px;
  top: 0;
}
body.extranet .products-notes {
  width: 100%;
}
body.extranet hr.section-divider {
  border-bottom: 2px solid #f0f1f1;
  margin: 30px 0;
  height: 1px;
  background: none;
}
body.extranet .promotion-owners {
  margin-bottom: 50px;
}
body.extranet .promotion-owners .page-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: normal;
}
body.extranet .promotion-owners .left-column {
  width: 178px;
  text-align: right;
  padding-top: 30px;
}
body.extranet .promotion-owners .left-column label {
  font-size: 13px;
  font-family: OpenSansSemiBold, OpenSansRegular, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}
body.extranet .promotion-owners .right-column {
  font-size: 12px;
  float: left;
}
body.extranet .promotion-owners .right-column label {
  font-weight: 300;
}
body.extranet .promotion-owners .right-column input[type=email] {
  outline: none;
  width: 280px;
  height: 30px;
}
body.extranet .promotion-owners .right-column .parsley-success,
body.extranet .promotion-owners .right-column .error {
  border: none !important;
  box-shadow: none !important;
}
body.extranet .promotion-owners .right-column .parsley-success input {
  border: 1px solid #82b548 !important;
  box-shadow: #82b548 0 0 2px !important;
}
body.extranet .promotion-owners .right-column .parsley-success .icon-check {
  display: inline-block;
}
body.extranet .promotion-owners .right-column .error input {
  border: 1px solid #cc2127 !important;
  box-shadow: #ff5157 0 0 2px !important;
}
body.extranet .promotion-owners .right-column .error .icon-x {
  display: inline-block;
}
body.extranet .promotion-owners .right-column .icon-check,
body.extranet .promotion-owners .right-column .icon-x {
  font-size: 17px;
  margin-left: 5px;
  margin-top: 5px;
  display: none;
}
body.extranet .promotion-owners .right-column .icon-check {
  color: #82b548;
}
body.extranet .promotion-owners .right-column .icon-x {
  color: #cc2127;
}
body.extranet .apw-description {
  font-size: 12px;
  color: #888;
}
body.extranet #products-list.loading {
  min-height: 300px;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
body.extranet #products-list.loading .booking-deals {
  visibility: hidden;
}
body.extranet .promotions_list_view span.product-set-status,
body.extranet #products-list span.product-set-status,
body.extranet #channel-manager-mapping-container span.product-set-status {
  font-weight: bold;
  color: #fff;
  padding: 3px;
  cursor: pointer;
  width: 30px;
  display: block;
  text-align: center;
}
body.extranet .promotions_list_view span.product-set-status.created,
body.extranet #products-list span.product-set-status.created,
body.extranet #channel-manager-mapping-container span.product-set-status.created {
  background-color: #306099;
}
body.extranet .promotions_list_view span.product-set-status.shop-rates,
body.extranet #products-list span.product-set-status.shop-rates,
body.extranet #channel-manager-mapping-container span.product-set-status.shop-rates {
  background-color: #d2bd84;
}
body.extranet .promotions_list_view span.product-set-status.ready,
body.extranet #products-list span.product-set-status.ready,
body.extranet #channel-manager-mapping-container span.product-set-status.ready {
  background-color: #e350e0;
}
body.extranet .promotions_list_view span.product-set-status.review-violations,
body.extranet #products-list span.product-set-status.review-violations,
body.extranet #channel-manager-mapping-container span.product-set-status.review-violations {
  background-color: #f50;
}
body.extranet .promotions_list_view span.product-set-status.approved,
body.extranet #products-list span.product-set-status.approved,
body.extranet #channel-manager-mapping-container span.product-set-status.approved {
  background-color: #f3a117;
}
body.extranet .promotions_list_view span.product-set-status.live,
body.extranet #products-list span.product-set-status.live,
body.extranet #channel-manager-mapping-container span.product-set-status.live {
  background-color: #88b556;
}
body.extranet .promotions_list_view span.product-set-status.finished,
body.extranet #products-list span.product-set-status.finished,
body.extranet #channel-manager-mapping-container span.product-set-status.finished {
  background-color: #656565;
}
body.extranet .promotions_list_view span.role-text,
body.extranet #products-list span.role-text,
body.extranet #channel-manager-mapping-container span.role-text {
  color: #b3b3b3;
}
body.extranet .promotions_list_view span.product-set-status {
  margin-top: 5px;
}
body.extranet .deal-manager span.product-set-status,
body.extranet .alert span.product-set-status {
  text-transform: capitalize;
}
body.extranet .deal-manager span.product-set-status.created,
body.extranet .alert span.product-set-status.created {
  color: #306099;
}
body.extranet .deal-manager span.product-set-status.shop-rates,
body.extranet .alert span.product-set-status.shop-rates {
  color: #d2bd84;
}
body.extranet .deal-manager span.product-set-status.ready,
body.extranet .alert span.product-set-status.ready {
  color: #e350e0;
}
body.extranet .deal-manager span.product-set-status.review-violations,
body.extranet .alert span.product-set-status.review-violations {
  color: #f50;
}
body.extranet .deal-manager span.product-set-status.approved,
body.extranet .alert span.product-set-status.approved {
  color: #f3a117;
}
body.extranet .deal-manager span.product-set-status.live,
body.extranet .alert span.product-set-status.live {
  color: #88b556;
}
body.extranet .deal-manager span.product-set-status.finished,
body.extranet .alert span.product-set-status.finished {
  color: #656565;
}
body.extranet .bd-rate-plan-details section {
  margin: 40px 0;
}
body.extranet .bd-rate-plan-details section .section-title {
  margin: 10px 0;
  text-transform: uppercase;
}
body.extranet .bd-rate-plan-details section .percentage-field {
  width: 100%;
  margin: 0;
}
body.extranet .bd-rate-plan-details section .percentage-field input {
  width: 90px;
}
body.extranet .bd-rate-plan-details section #clear-bonus-add {
  padding-top: 35px;
  color: #08c;
  cursor: pointer;
  display: inline-block;
}
body.extranet .bd-rate-plan-details section #clear-bonus-add.hide {
  display: none;
}
body.extranet .bd-rate-plan-details section #clear-bonus-add:hover {
  text-decoration: underline;
}
body.extranet .bd-rate-plan-details section .row {
  margin: 5px -10px;
}
body.extranet .bd-rate-plan-details section .blackout-days {
  padding-right: 0;
}
body.extranet .bd-rate-plan-details section .blackout-days ul {
  margin: 0;
  padding: 0;
}
body.extranet .bd-rate-plan-details section .blackout-days ul li {
  width: 10%;
  list-style: none;
  float: left;
  margin: 0 4% 0 0;
}
body.extranet .bd-rate-plan-details section .blackout-days ul li:last-child {
  margin: 0;
}
body.extranet .bd-rate-plan-details section .blackout-days ul li span {
  display: block;
  text-align: center;
}
body.extranet .bd-rate-plan-details section .blackout-days ul li input {
  width: 100%;
  margin-bottom: 0;
}
body.extranet .ql2-upload-container input[disabled] {
  cursor: not-allowed;
}
body.extranet .ql2-upload-container button {
  outline: none;
  padding: 5px 10px;
}
body.extranet .ql2-upload-container button:before {
  padding-right: 3px;
}
body.extranet.room-mapping .help-message {
  color: #888;
  margin-top: 30px;
}
body.extranet.room-mapping .room-mapping-section {
  margin: 30px 0;
  color: #666;
}
body.extranet.room-mapping .room-mapping-section.loading {
  min-height: 300px;
  background: url("../../public/getaways-xnet/assets/common-spin-fff.gif") no-repeat center 100px;
}
body.extranet.room-mapping .room-mapping-section.loading .content {
  display: none;
}
body.extranet.room-mapping .room-mapping-section .section-title {
  text-transform: uppercase;
  font-weight: bold;
  color: #666;
  border-bottom: 2px solid #ccc;
  padding: 5px 0;
  text-align: center;
}
body.extranet.room-mapping .room-mapping-section .group-title {
  text-align: center;
  font-size: 16px;
  color: #82b548;
}
body.extranet.room-mapping .room-mapping-section .invisible-placeholder {
  visibility: hidden;
}
body.extranet.room-mapping .room-mapping-section .rate-description {
  margin: 3px 3px 10px 3px;
  padding: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background: #fff;
  line-height: 18px;
}
body.extranet.room-mapping .room-mapping-section .rate-description:not(.read-only) {
  cursor: move;
}
body.extranet.room-mapping .room-mapping-section .rate-description:not(.read-only).selected {
  border-color: #82b548;
  box-shadow: #82b548 0 0 5px;
  color: #528518;
}
body.extranet.room-mapping .room-mapping-section .rate-description:not(.read-only).selected .icon-list:before {
  content: "\e050";
}
body.extranet.room-mapping .room-mapping-section .rate-description .rate-description-name {
  max-width: 85%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
body.extranet.room-mapping .room-mapping-section .rate-description .rate-description-name:before {
  padding-right: 5px;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group {
  border: 1px dashed #aaa;
  margin: 20px 0 50px 0;
  padding: 20px;
  position: relative;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.active,
body.extranet.room-mapping .room-mapping-section .rate-description-group.highlight {
  background-color: rgba(135,236,19,0.07);
  border-color: #82b548;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.highlight {
  box-shadow: #82b548 0 0 10px;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.opted-out .group-title {
  color: #ff9305;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.opted-out.active,
body.extranet.room-mapping .room-mapping-section .rate-description-group.opted-out.highlight {
  background-color: rgba(255,147,5,0.07);
  border-color: #ff9305;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.opted-out.highlight {
  box-shadow: #ff9305 0 0 10px;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.unassigned.active,
body.extranet.room-mapping .room-mapping-section .rate-description-group.unassigned.highlight {
  background-color: #f8f8f8;
  border-color: #ccc;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.unassigned.highlight {
  box-shadow: #ccc 0 0 10px;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group:after {
  clear: both;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.hotel-lar .context-menu-option.hotel-lar,
body.extranet.room-mapping .room-mapping-section .rate-description-group.opted-out .context-menu-option.opt-out,
body.extranet.room-mapping .room-mapping-section .rate-description-group.unassigned .context-menu-option.unassign {
  display: none;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.multi-selection .rate-description:not(.selected) .toggle-context-menu {
  display: none;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group .select-all {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  font-size: 16px;
  color: #aaa;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group .select-all.active {
  color: #82b548;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.empty .select-all {
  display: none;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.empty .toggle-link {
  cursor: auto;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.empty .toggle-link .icon-arrow-down-large {
  visibility: hidden;
}
body.extranet.room-mapping .room-mapping-section .rate-description-group.empty .group-title {
  color: #666;
}
body.extranet.room-mapping .room-mapping-section .draggable-window.multiple {
  width: 400px;
  background-color: #fff;
  box-shadow: #ccc 0 0 10px;
  border: 1px solid #ccc;
  height: auto !important;
  padding: 15px;
}
body.extranet.room-mapping .room-mapping-section .draggable-window.multiple .rate-description.selected {
  box-shadow: none;
}
body.extranet.room-mapping .room-mapping-section .draggable-window.multiple .more-descriptions {
  font-size: 12px;
  text-align: center;
}
body.extranet.room-mapping .room-mapping-section .draggable-window .toggle-context-menu {
  display: none;
}
body.extranet.room-mapping .room-mapping-section .rate-description-count {
  font-weight: bold;
}
body.extranet.room-mapping .room-mapping-section .rate-description-list {
  max-height: 420px;
  overflow: auto;
  margin-top: 10px;
}
body.extranet.room-mapping .room-mapping-section .rate-description-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
body.extranet.room-mapping .room-mapping-section .rate-description-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,0.3);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}
body.extranet.room-mapping .room-mapping-section .toggle-link {
  text-align: center;
  cursor: pointer;
}
body.extranet.room-mapping .room-mapping-section .toggle-link .icon-arrow-down-large:before {
  display: inline-block;
  vertical-align: middle;
}
body.extranet.room-mapping .room-mapping-section .toggle-link.expanded .icon-arrow-down-large:before {
  content: "\e074";
}
body.extranet.room-mapping .room-mapping-section .action-buttons {
  padding-top: 20px;
  border-top: 1px solid #ccc;
}
body.extranet.room-mapping .room-mapping-section .toggle-context-menu {
  cursor: pointer;
  z-index: 5;
}
body.extranet.room-mapping .room-mapping-section .toggle-context-menu:before {
  vertical-align: middle;
  font-size: 18px;
}
body.extranet.room-mapping .room-mapping-section .icon-list:before {
  vertical-align: middle;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup {
  cursor: auto;
  background: #fff;
  width: 200px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.4);
  position: absolute;
  border: 1px solid #ccc;
  right: 0;
  z-index: 10;
  color: #666;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup .context-menu-option-title {
  padding: 10px;
  font-weight: bold;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup .divider {
  margin: 3px 0;
  border-top: 1px solid #ccc;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup .context-menu-option {
  cursor: pointer;
  padding: 5px 10px;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup .context-menu-option:hover {
  background-color: #82b548;
  color: #fff;
  font-weight: bold;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup .context-menu-option.opt-out:hover {
  background-color: #ff9305;
}
body.extranet.room-mapping .room-mapping-section .context-menu-popup .context-menu-option.unassign:hover {
  background-color: #aaa;
}
body.extranet.room-mapping #room-mapping-save-confirmation .icon-alert {
  color: #666;
  font-size: 30px;
  margin-button: 20px;
  padding-bottom: 5px;
}
body.cc-enable-manage-consent h2.cc-notification {
  display: none;
}
body.cc-enable-manage-consent #cc-form .gig-selectable #cc-functional-label:before,
body.cc-enable-manage-consent #cc-form .gig-selectable #cc-marketing-label:before,
body.cc-enable-manage-consent #cc-form .gig-selectable #cc-functional-label:after,
body.cc-enable-manage-consent #cc-form .gig-selectable #cc-marketing-label:after {
  opacity: 1;
  pointer-events: auto;
}
body.cc-enable-manage-consent #cc-form .gig-selectable #cc-functional-input,
body.cc-enable-manage-consent #cc-form .gig-selectable #cc-marketing-input {
  pointer-events: auto;
}
body.cc-enable-manage-consent #cc-form #cc-submit-btn {
  pointer-events: auto;
  opacity: 1;
}
body.cc-enable-manage-consent #cc-form #cc-submit-btn span.cc-enabled-button-text {
  display: block;
}
body.cc-enable-manage-consent #cc-form #cc-submit-btn span.cc-disabled-button-text {
  display: none;
}
div#cc-manage-section {
  display: block !important;
}
#ls-cc-page h2.cc-notification {
  color: var(--color-err);
}
#ls-cc-page h3 {
  font-weight: bold;
  margin: 8px 0;
}
#ls-cc-page h4 {
  font-weight: normal;
  font-size: 16px;
}
#cc-form {
  margin: 0 0 30px 20px;
}
#cc-form label {
  margin: 0;
  transition: opacity 0.25s ease-in-out;
}
#cc-form label:before {
  width: 16px;
  height: 16px;
}
#cc-form input[type='checkbox'] + label:after {
  padding: 0 1px;
  margin-top: -1px;
  width: 1.4rem;
  height: 1.4rem;
}
#cc-form .flex-row {
  margin-top: 20px;
}
#cc-form .cc-checkbox-label-column {
  margin-left: 4px;
}
#cc-form .gig-selectable #cc-essential-label:before,
#cc-form .gig-selectable #cc-functional-label:before,
#cc-form .gig-selectable #cc-marketing-label:before,
#cc-form .gig-selectable #cc-essential-label:after,
#cc-form .gig-selectable #cc-functional-label:after,
#cc-form .gig-selectable #cc-marketing-label:after {
  opacity: 0.5;
  pointer-events: none;
}
#cc-form .gig-selectable #cc-essential-input,
#cc-form .gig-selectable #cc-functional-input,
#cc-form .gig-selectable #cc-marketing-input {
  pointer-events: none;
}
#cc-form .cc-label-txt {
  color: var(--color-gray-dk);
  font-weight: bold;
  font-size: 14px;
  margin: 1px 0 6px 0;
}
#cc-form .cc-label-desc {
  display: block;
  color: var(--color-gray-dk);
  font-size: 14px;
  line-height: 22px;
}
#cc-form #cc-submit-btn {
  cursor: pointer;
  margin: 25px 0 0 0;
  min-width: 150px;
  padding: 8px 0 10px;
  font-weight: bold;
  pointer-events: none;
  opacity: 0.5;
}
#cc-form #cc-submit-btn span {
  padding: 0 20px;
  transition: opacity 0.25s ease-in-out;
}
#cc-form #cc-submit-btn span.cc-enabled-button-text {
  display: none;
}
#cc-form #cc-submit-btn span.cc-disabled-button-text {
  display: block;
}
#cc-form #cc-submit-btn.cc-no-click {
  pointer-events: none;
  cursor: default;
}
#cc-form #cc-submit-btn.ls-loading-white span {
  opacity: 0;
}
#cc-form #cc-submit-btn.ls-loading-white:before {
  top: 10px;
  left: 65px;
  transition: opacity 0.25s ease-in-out;
}
#cc-form #cc-error-msg {
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  margin: 20px 0 0 0;
  color: var(--color-err);
  line-height: 22px;
}
#cc-form #cc-error-msg.icon-circle-alert:before {
  position: relative;
  top: 5px;
  left: 0;
  font-size: 20px;
  padding-right: 8px;
}
#cc-form .flex-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
/*# sourceMappingURL=core/frontend/styles.css.map */